import { useEffect, useState } from "react";

export default function useGetIndicatorQuestionInfo(data){
    const [info, setInfo] = useState([]);

    useEffect(() => {
        const resulReport = data?.QuestionInstructionSections?.map(it=> ({
            title: it.name,
            rows: it.QuestionInstructionSubsections.map(itRow => ({
                title: itRow.description,
                adorment: itRow.adorment,
                children: itRow.QuestionInstructionSubsectionLists.map(itChild=> ({
                    title: itChild.description,
                    type: itChild.adorment,
                    sxExtra:{
                        view: {
                            marginLeft: 10
                        }
                    }
                }))

            }))
        }))
        setInfo(resulReport)
    }, [])

    return info;
}