import { StyleSheet, View } from "@react-pdf/renderer";
import ReportTableHeader from "./ReportTableHeader";
import ReportTableRow from "./ReportTableRow";

export default function ReportTable({table, sx={}}){
    const styles = StyleSheet.create({
        tableContainer: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: 24,
            borderWidth: 1,
            borderColor: '#000'
        },
    });
    return (
        <View style={[styles.tableContainer, sx]}>
            <ReportTableHeader title={table?.header} headerType={table?.headerType} itemHeader={table?.itemHeader}/>
            <ReportTableRow rows={table?.rows}/>
        </View>
    )
}