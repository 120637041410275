import { useState } from "react";

export default function useGetCaseInfo(document) {
    const [info, setInfo] = useState({
        header: 'CASE INFORMATION',
        rows: [
            {
                style: {
                    height: 54,
                    borderBottomColor: '#000',
                    borderBottomWidth: 1,
                },
                columns: [
                    {
                        style: {
                            width: '30%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                        },
                        title: 'Date of Case Acceptance:',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: document?.caseInfo?.dateAcceptance,
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '30%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                        },
                        title: 'Date of Release:',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: document?.caseInfo?.dateRelease,
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '40%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: 'Post-Release Agency, Case Manager & Contact Information:',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text:  `${document?.caseInfo?.postReleaseAgency} ${document?.caseInfo?.caseManager} ${document?.caseInfo?.contactInformation}`,
                        typeInput: 'label'
                    }
                ]
            },
            {
                style: {
                    height: 24,
                    borderBottomColor: '#000',
                    borderBottomWidth: 1,
                },
                columns: [
                    {
                        style: {
                            width: '30%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                        },
                        title: 'Date(s) and Type(s) of Contacts: ',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: '',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '30%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                        },
                        title: 'Date Report Completed:',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: document?.caseInfo?.dateComplete,
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '40%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: 'Date of Case Closure:',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: document?.caseInfo?.dateClosure,
                        typeInput: 'label'
                    }
                ]
            }
        ]

    })

    return info;
}