import { get, post, put } from "./api";
import * as url from "./url";

//get user logued
const getStageList = query => get(`${url.STAGES}${query}`)

//get case
const getStage = id => get(`${url.STAGES}/${id}`)

//save stage
const postStage = data => post(url.STAGES, data)

//update stage
const putStage = (id, data) => put(`${url.STAGES}/${id}`, data)

const getStagesActives = () => get(`${url.STAGES}/active`)

export {
    getStageList,
    getStage,
    postStage,
    putStage,
    getStagesActives
}