import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Card, CardBody, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ERROR_SERVER, requiredField, SAVE_SUCCESS, UPDATE_SUCCESS } from "../../constants/messages";
import FullScreenLoader from "../Loader/FullScreenLoader";
import { postUser, putUser } from "../../helpers/user";
import Select from "react-select";
import useGetRoles from "../../hooks/useGetRoles";

export default function UserForm({id, item}){
    const [isSubmiting, setIsSubmiting] = useState(false)
    const rolesOpt = useGetRoles();
    const history = useHistory();
    const initialValues = item ? item :{
        id: null,
        name: '',
        email: '',
        username: '',
        password:'',
        active: true,
        role_id: null
    };
    const form = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: Yup.object({
            name: Yup.string().required(requiredField),
            email: Yup.string().required(requiredField),
            username: Yup.string().required(requiredField),
            password: Yup.string().when('id', {
                is: value => value===null,
                then: Yup.string().required(requiredField),
                otherwise: Yup.string().notRequired()
            }),
            role_id: Yup.string().nullable().required(requiredField)           
        }),
        onSubmit: async (values) => { 
            setIsSubmiting(true)
            try {
                if(values.id){
                    const valuesToUpdate = {
                        id: values.id,
                        name: values.name,
                        email: values.email,
                        username: values.username,
                        role_id: values.role_id     
                    }
                    const response = await putUser(id, valuesToUpdate);  
                    if(response.success){
                        toast.success(UPDATE_SUCCESS)
                    }else{
                        toast.error(ERROR_SERVER)
                    }         
                    setIsSubmiting(false)            
                }else{
                    const response = await postUser(values)
                    if(response.success){
                        toast.success(SAVE_SUCCESS)
                    }else{
                        toast.error(ERROR_SERVER)
                    }  
                    setIsSubmiting(false)                   
                }
                setTimeout(() => {
                    history.goBack();
                }, 2000)
                
                
            } catch (error) {
                toast.error(ERROR_SERVER)
                setIsSubmiting(false) 
            }         
        }
    })
    return (
        <Card>
            {isSubmiting && <FullScreenLoader />}
            <CardBody>
                <Form
                    className="needs-validation"
                    id="tooltipForm"
                    onSubmit={(e) => {
                    e.preventDefault();
                    form.handleSubmit();
                    return false;
                }}
                >
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="stagesname"
                        className="col-form-label col-lg-2"
                      >
                        Name
                      </Label>
                      <Col lg="10">
                        <Input
                            type="text"
                            id="name"
                            name="name"
                            className={`form-control ${form.errors.name ? 'is-invalid' : ''}`}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={form.values.name || ""}  
                        />
                        {
                            form.errors.name && 
                            <FormFeedback type="invalid">{form.errors?.name}</FormFeedback>
                        }
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="email"
                        className="col-form-label col-lg-2"
                      >
                        Email
                      </Label>
                      <Col lg="10">
                        <Input
                            type="text"
                            id="email"
                            name="email"
                            className={`form-control ${form.errors.email ? 'is-invalid' : ''}`}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={form.values.email || ""}  
                        />
                        {
                            form.errors.email && 
                            <FormFeedback type="invalid">{form.errors?.email}</FormFeedback>
                        }
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="username"
                        className="col-form-label col-lg-2"
                      >
                        Username
                      </Label>
                      <Col lg="10">
                        <Input
                            type="text"
                            id="username"
                            name="username"
                            className={`form-control ${form.errors.username ? 'is-invalid' : ''}`}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={form.values.username || ""}  
                        />
                        {
                            form.errors.username && 
                            <FormFeedback type="invalid">{form.errors?.username}</FormFeedback>
                        }
                      </Col>
                    </FormGroup>
                    {!id && <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="password"
                        className="col-form-label col-lg-2"
                      >
                        Password
                      </Label>
                      <Col lg="10">
                        <Input
                            type="password"
                            id="password"
                            name="password"
                            className={`form-control ${form.errors.password ? 'is-invalid' : ''}`}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={form.values.password || ""}  
                        />
                        {
                            form.errors.password && 
                            <FormFeedback type="invalid">{form.errors?.password}</FormFeedback>
                        }
                      </Col>
                    </FormGroup>}
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="role"
                        className="col-form-label col-lg-2"
                      >
                        Role
                      </Label>
                      <Col lg="10">
                        <Select
                            value={{
                                value: form.values.role_id, 
                                label: rolesOpt.find(it=>it.value===form.values.role_id)?.label ?? ''
                            }}
                            onChange={(selected) => {
                                if(selected){
                                    form.setFieldValue(`role_id`, selected.value)
                                }else{
                                    form.setFieldValue(`role_id`, '')
                                }
                            }}
                            options={rolesOpt}
                            classNamePrefix="select2-selection"
                            closeMenuOnSelect={true}
                            className={`${form.errors?.role_id ? 'is-invalid' : ''}`}
                        />
                        {
                            form.errors.role_id && 
                            <FormFeedback type="invalid">{form.errors?.role_id}</FormFeedback>
                        }
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="stagesactive"
                        className="col-form-label col-lg-2"
                      >
                        
                      </Label>
                      <Col lg="10">
                        <div className="form-check form-check-end">
                            <Input
                                type="checkbox"
                                id="stagesactive"
                                name="active"
                                className={`form-check-input ${form.errors.active ? 'is-invalid' : ''}`}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                                checked={form.values.active || ""}  
                            />
                            <label
                                className="form-check-label"
                                htmlFor="stagesactive"
                            >
                            Active
                          </label>
                        </div>
                      </Col>
                    </FormGroup>
                    <Row className="justify-content-end">
                        <Col lg="10">
                        <Button type="submit" color="success" className="me-2">
                            Save
                        </Button>
                        <Link to="/user-list" className="btn btn-light waves-effect">Cancel</Link>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
        </Card>
    )
}