import { get, post, put } from "./api";
import * as url from "./url";

//get user logued
const getReportTopConfigurationList = query => get(`${url.HEADER_REPORT}${query}`)

//get case
const getReportTopConfiguration = id => get(`${url.HEADER_REPORT}/${id}`)

//save stage
const postReportTopConfiguration = data => post(url.HEADER_REPORT, data)

//update stage
const putReportTopConfiguration = (id, data) => put(`${url.HEADER_REPORT}/${id}`, data)

const getReportTopConfigurationActives = () => get(`${url.HEADER_REPORT}/active`)

export {
    getReportTopConfigurationList,
    getReportTopConfiguration,
    postReportTopConfiguration,
    putReportTopConfiguration,
    getReportTopConfigurationActives
}