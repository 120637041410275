import { withRouter } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumbs"
import StagesForm from "../../components/Stages/StagesForm"

function StagesAdd(){
    
    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Stage" breadcrumbItem="New stage" />
                <Row>
                    <Col xs="12" lg="12">
                        <StagesForm document={null}/>
                    </Col>
                </Row>
            </Container>
        </div>
    )

}

export default withRouter(StagesAdd)