import { withRouter } from "react-router-dom"
import { Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumbs";
import CasesTotalStatus from "../../components/Dashboard/CasesTotalStatus";
import StaticsCase from "../../components/Dashboard/StaticsCase";
import WelcomeCard from "../../components/Dashboard/WelcomeCard";
import UploadDocument from "../../components/Document/UploadDocument";
import CasesReminders from "../../components/Dashboard/CasesReminders";

function Dashboard(){  
  
  return (
      <>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={'Dashboards'}
              breadcrumbItem={"Dashboard"}
            />

            <Row>
              <Col xl="4">                
                <WelcomeCard />
                <UploadDocument />
              </Col>
              <Col xl="8">
                <Row>
                  <CasesTotalStatus />
                </Row>
                <Row>
                  <Col md="6">
                    <StaticsCase 
                      title="Active cases"
                      type="active"
                    />
                  </Col>
                  <Col md="6">
                    <StaticsCase 
                      title="Close cases"
                      type="close"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xl="12">
                <CasesReminders />
              </Col>
            </Row>

          </Container>
        </div>
      </>
    );
}

export default withRouter(Dashboard)