import { withRouter } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumbs";
import QuestionInstructionsForm from "../../components/QuestionInstructions/QuestionInstructionsForm";

function QuestionInstructionsAdd(){

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Questions & Instructions" breadcrumbItem="New Questions & Instructions" />
                <Row>
                    <Col xs="12" lg="12">
                        <QuestionInstructionsForm document={null}/>
                    </Col>
                </Row>
            </Container>
        </div>
    )

}

export default withRouter(QuestionInstructionsAdd)