import { useState, useEffect } from "react";
import { Field, FieldArray, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { Button, Card, CardBody, Col, Form, Label, Row } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { DELETE_SUCCESS, ERROR_SERVER, FIELD_REQUIRED, SAVE_SUCCESS } from "../../constants/messages";
import FullScreenLoader from "../Loader/FullScreenLoader";
import moment from "moment";
import { deleteReminder, postReminder } from "../../helpers/reminders";
import { toast } from "react-toastify";
import ModalInformativo from "../Common/ModalInformativo";
import DateInputMask from "../Controllers/DateInputMask";
import { getUserListAll } from "../../helpers/user";
import Select from "react-select";

export default function ReminderForm({id, reminders, setReload}){
    const [isSubmiting, setIsSubmiting] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [idDelete, setIdDelete] = useState(null)
    const history = useHistory();
    const initialValues = reminders.length > 0 ? {
        case_id: id,
        reminders: reminders.map(rem=>({...rem, User: {id: rem.User?.id ?? ''}}))
    } : {
        case_id: id,
        reminders: [{
            id:null,
            date: '',
            note: '',
            User: {
                id: '',
                name: ''
            }
        }]
    };
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: Yup.object({
            reminders: Yup.array().of(
                Yup.object().shape({
                    date: Yup.string().required(FIELD_REQUIRED),
                    note: Yup.string().required(FIELD_REQUIRED),
                    User: Yup.object().shape({
                        id: Yup.string().required(FIELD_REQUIRED)
                    }),
                })
            )       
        }),
        onSubmit: async (values) => { 
            //setIsSubmiting(true)
            console.log(values)  
            try {
                const data = {
                    case_id: values.case_id,
                    reminders: values.reminders.map(rem=>({
                        ...rem,
                        date: moment(rem.date).format("YYYY-MM-DD"),
                    }))
                }
                const response = await postReminder(data)
                if(response.success){
                    toast.success(SAVE_SUCCESS)
                }else{
                    toast.error(ERROR_SERVER)
                }  
                setIsSubmiting(false)
                setTimeout(() => {
                    history.goBack();
                }, 2000)                
            } catch (error) {
                toast.error(ERROR_SERVER)
                setIsSubmiting(false) 
            }      
        }
    })

    const onClickDelete = async () => {
        try {
            const response = await deleteReminder(idDelete)
            if(response.success){
                toast.success(DELETE_SUCCESS)
                setIdDelete(null)
                setOpenModal(false)
                setReload(true)
            }else{
                toast.error(ERROR_SERVER)
            }
        } catch (error) {
            toast.error(ERROR_SERVER)
        }
    }

    const [userOpt, setUserOpt] = useState([])
    const fecthUserApi = async () => {
        try {
            const response = await getUserListAll();
            setUserOpt(response.content.map((u) => ({label: u.name, value: u.id})))        
        } catch (error) {
            //toast.error(ERROR_SERVER)
        }
    }

    useEffect(() => {
        fecthUserApi();
    }, [])

    console.log(formik.errors)

    return (
        <Card>
            {isSubmiting && <FullScreenLoader />}
            <CardBody>
                <Form
                    className="needs-validation"
                    id="tooltipForm"
                    onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                    return false;
                }}
                >
                    <Row>
                        <Col>
                            <FormikProvider value={formik}>
                                <FieldArray
                                    name="reminders"
                                    render={arrayHelper=>(
                                        <div className="border p-2 mt-1">
                                            {
                                                (formik.values.reminders && formik.values.reminders.length > 0) &&
                                                formik.values.reminders.map((item, index) => (
                                                    <div key={index} className="mb-2">
                                                        <Row>
                                                            <Col xs="12" md="2">
                                                                {index === 0 && <Label className="mb-0">Date(MM/DD/YYYY)</Label>}
                                                                <DateInputMask
                                                                    date={formik.values.reminders[index].date}
                                                                    onChange={event=>{
                                                                        formik.setFieldValue(`reminders.${index}.date`, event.target.value)
                                                                    }}
                                                                    className="form-control"
                                                                />
                                                                {
                                                                    formik.errors?.reminders?.length > 0 && formik.errors.reminders[index]?.date &&
                                                                    <div className="invalid-tooltip d-block">{formik.errors.reminders[index]?.date}</div>
                                                                } 
                                                            </Col>
                                                            <Col xs="12" md="6">
                                                                {index === 0 && <Label className="mb-0">Note</Label>}
                                                                <Field
                                                                    className={`form-control`}
                                                                    name={`reminders.${index}.note`} 
                                                                />
                                                                {
                                                                    formik.errors?.reminders?.length > 0 && formik.errors.reminders[index]?.note &&
                                                                    <div className="invalid-tooltip d-block">{formik.errors.reminders[index]?.note}</div>
                                                                } 
                                                            </Col>
                                                            <Col xs="12" md="3">
                                                                {index === 0 && <Label className="mb-0">Asigando</Label>}
                                                                <Select
                                                                    value={{value: formik.values.reminders[index].User?.id, label:formik.values.reminders[index].User?.name}}
                                                                    onChange={(value) => {
                                                                        formik.setFieldValue(`reminders.${index}.User.id`, value.value)
                                                                        formik.setFieldValue(`reminders.${index}.User.name`, value.label)
                                                                    }}
                                                                    options={userOpt}
                                                                    classNamePrefix="select2-selection"
                                                                    closeMenuOnSelect={true}
                                                                />
                                                                {
                                                                    formik.errors?.reminders?.length > 0 && formik.errors.reminders[index]?.User?.id &&
                                                                    <div className="invalid-tooltip d-block">{formik.errors.reminders[index]?.User?.id}</div>
                                                                } 
                                                            </Col>
                                                            {index > 0 && 
                                                            <Col xs="12" md="1" className="d-flex align-items-center">
                                                                {index===0 && <Label className="mb-0 opacity-0 d-block">O</Label>}
                                                                <Button color="danger" size="sm" onClick={() => {
                                                                    if(item.id){
                                                                        setIdDelete(item.id)
                                                                        setOpenModal(true)
                                                                    }else{
                                                                        arrayHelper.remove(index)
                                                                    }                                                                    
                                                                }}>Delete</Button>
                                                            </Col>}                                             
                                                        </Row>
                                                    </div>
                                                ))
                                            }
                                            <Button type="button" color="link" className="btn btn-link" onClick={() => arrayHelper.push({
                                                    id:null,
                                                    date: '',
                                                    note: '',
                                                    User: {
                                                        id: '',
                                                        name: ''
                                                    }
                                                },
                                            )}>
                                                <i className="mdi mdi-notebook-plus-outline me-1"></i>
                                                Agregar
                                            </Button>
                                        </div>
                                    )}
                                />
                            </FormikProvider>
                        </Col>
                    </Row>
                    
                    <Row className="justify-content-end mt-2">
                        <Col lg="12">
                        <Button type="submit" color="success" className="me-2">
                            Save
                        </Button>
                        <Link to="/case-list" className="btn btn-light waves-effect">Cancel</Link>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
            <ModalInformativo
                open={openModal}
                setOpen={setOpenModal}
                title={"Delete reminder"}
                text={"Are you sure to delete the reminder."}
                onClickOk={onClickDelete}
                existCase={false}
                onClickViewCase={() => {}}
            />
        </Card>
    )
}