import { StyleSheet, Text } from "@react-pdf/renderer";

export default function ReportTitle(){
    const styles = StyleSheet.create({
        title: {
            fontSize: 14,
            textAlign: 'center',
            fontFamily: 'Times New Roman Bold',
            marginTop: 24
          },
      });
    return (
        <Text style={styles.title}>ORR/UAC PROGRAM POST-RELEASE SERVICES REPORT</Text>
    )
}