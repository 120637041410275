import { withRouter } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumbs"
import HeaderReportForm from "../../components/HeaderReport/HeaderReportForm"

function HeaderReportAdd(){
    
    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Header Report" breadcrumbItem="New header report" />
                <Row>
                    <Col xs="12" lg="12">
                        <HeaderReportForm document={null}/>
                    </Col>
                </Row>
            </Container>
        </div>
    )

}

export default withRouter(HeaderReportAdd)