import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { postStage, putStage } from "../../helpers/stages";
import { toast } from "react-toastify";
import { ERROR_SERVER, SAVE_SUCCESS, UPDATE_SUCCESS } from "../../constants/messages";
import FullScreenLoader from "../Loader/FullScreenLoader";

export default function StagesForm({id, stage}){
    const [isSubmiting, setIsSubmiting] = useState(false)
    const history = useHistory();
    const [initialValues, setInitialValues] = useState( stage ? stage :{
        name: '',
        active: true
    });
    const form = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: Yup.object({
            name: Yup.string().required()           
        }),
        onSubmit: async (values) => { 
            setIsSubmiting(true)
            try {
                if(values.id){
                    const response = await putStage(id, values);  
                    if(response.success){
                        toast.success(UPDATE_SUCCESS)
                    }else{
                        toast.error(ERROR_SERVER)
                    }         
                    setIsSubmiting(false)            
                }else{
                    const response = await postStage(values)
                    if(response.success){
                        toast.success(SAVE_SUCCESS)
                    }else{
                        toast.error(ERROR_SERVER)
                    }  
                    setIsSubmiting(false)                   
                }
                setTimeout(() => {
                    history.goBack();
                }, 2000)
                
                
            } catch (error) {
                toast.error(ERROR_SERVER)
                setIsSubmiting(false) 
            }         
        }
    })
    return (
        <Card>
            {isSubmiting && <FullScreenLoader />}
            <CardBody>
                <Form
                    className="needs-validation"
                    id="tooltipForm"
                    onSubmit={(e) => {
                    e.preventDefault();
                    form.handleSubmit();
                    return false;
                }}
                >
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="stagesname"
                        className="col-form-label col-lg-2"
                      >
                        Name
                      </Label>
                      <Col lg="10">
                        <Input
                            type="text"
                            id="name"
                            name="name"
                            className={`form-control ${form.errors.name ? 'is-invalid' : ''}`}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={form.values.name || ""}  
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="stagesactive"
                        className="col-form-label col-lg-2"
                      >
                        
                      </Label>
                      <Col lg="10">
                        <div className="form-check form-check-end">
                            <Input
                                type="checkbox"
                                id="stagesactive"
                                name="active"
                                className={`form-check-input ${form.errors.active ? 'is-invalid' : ''}`}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                                checked={form.values.active || ""}  
                            />
                            <label
                                className="form-check-label"
                                htmlFor="stagesactive"
                            >
                            Active
                          </label>
                        </div>
                      </Col>
                    </FormGroup>
                    <Row className="justify-content-end">
                        <Col lg="10">
                        <Button type="submit" color="success" className="me-2">
                            Save
                        </Button>
                        <Link to="/stage-list" className="btn btn-light waves-effect">Cancel</Link>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
        </Card>
    )
}