import { Field, FieldArray, FormikProvider } from "formik";
import { Button, Col, FormFeedback, Input, Label, Row, Table } from "reactstrap";
import Select from "react-select";
import { countries } from "../../constants/countries";
import useGetRelationShip from "../../hooks/useGetRelationShip";
import { deleteHouseHolderMember } from "../../helpers/householdermember";
import { toast } from "react-toastify";
import ReasonReferral from "./ReasonReferral";
import { useEffect, useState } from "react";
import ControlledFieldSurvey from "../Controllers/ControlledFieldSurvey";

export default function Section2({form}){
    const { initialValues } = form;
    const relationshipOpt = useGetRelationShip()
    const [safetyStatus, setSafetySattus] = useState(initialValues.SafetyStatus)
    const [reSet, setReSet] = useState(false)

    const handleOnChange = (value, idQuestion, label) => {
        const copyData = {...safetyStatus.data}
        const copyQuestions = [...copyData?.questions ?? []];
        const idx = copyQuestions.findIndex(it=>it?.id === idQuestion)
        if(idx>=0){
            const copyQuestionObj = copyQuestions[idx]
            const copyLabels = copyQuestionObj.labels.map((it=>({...it, checked: false})))
            const idxL = copyLabels.findIndex(it=>it.value===label.value)
            if(idxL>=0){
                copyLabels[idxL].checked = true;
                copyQuestionObj.labels = copyLabels
                copyQuestions[idx] = copyQuestionObj;
            }
        }
        copyData.questions = copyQuestions;
        setSafetySattus(prev=>({
            ...prev,
            data: copyData
        }))
        setReSet(true)
    }

    useEffect(() => {
        if(reSet){
            form.setFieldValue('SafetyStatus', safetyStatus)
            setReSet(false)
        }
    }, [reSet])

    return(
        <>
            <Row>
                <Col xs="12" md="12">
                    <Table bordered size="sm" style={{width: '100%'}}>
                        <thead>
                            <tr className="bg-dark bg-soft">
                                <th colSpan={7}>HOUSEHOLD MEMBERS</th>
                            </tr>
                            <tr className="text-center">
                                <th style={{width: '25%'}}>Name</th>
                                <th style={{width: '15%'}}>Nationality</th>
                                <th style={{width: '10%'}}>Age</th>
                                <th style={{width: '10%'}}>Gender</th>
                                <th style={{width: '15%'}}>Relationship to the sponsor</th>
                                <th style={{width: '12%'}}>Relationship to the minor</th>
                                <th style={{width: '3%'}}></th>
                            </tr>
                        </thead>
                        <tbody className="fs-7">
                            <FormikProvider value={form}>
                            <FieldArray
                                name="houseHoldMembers" 
                                render={arrayHelper=>(
                                    <>
                                    {
                                        (form.values.houseHoldMembers && form.values.houseHoldMembers.length > 0) &&
                                        form.values.houseHoldMembers.map((item, index) => (
                                            <tr key={index}>
                                                <td style={{width: '25%'}}>
                                                    <Field
                                                        className={`fs-7 input-1 p-0 form-control ${form.errors?.houseHoldMembers?.length > 0 && form.errors.houseHoldMembers[index]?.name ? 'is-invalid' : ''}`}
                                                        name={`houseHoldMembers.${index}.name`} 
                                                    />  
                                                    {
                                                        (form.errors?.houseHoldMembers?.length > 0 && form.errors?.houseHoldMembers[index]?.name) && 
                                                        <FormFeedback type="invalid">{form.errors?.houseHoldMembers[index]?.name}</FormFeedback>
                                                    } 
                                                </td>
                                                <td style={{width: '15%'}}>
                                                    <Select
                                                            value={{
                                                            value: form.values.houseHoldMembers[index].nacionality, 
                                                            label: form.values.houseHoldMembers[index].nacionality
                                                        }}
                                                        onChange={(selected) => {
                                                            if(selected){
                                                                form.setFieldValue(`houseHoldMembers.${index}.nacionality`, selected.value)
                                                            }else{
                                                                form.setFieldValue(`houseHoldMembers.${index}.nacionality`, '')
                                                            }
                                                        }}
                                                        options={countries}
                                                        classNamePrefix="select2-selection"
                                                        closeMenuOnSelect={true}
                                                        className={`select2-1 ${form.errors?.houseHoldMembers?.length > 0 && form.errors?.houseHoldMembers[index]?.nacionality ? 'is-invalid' : ''}`}
                                                    />      
                                                    {
                                                        (form.errors?.houseHoldMembers?.length > 0 && form.errors?.houseHoldMembers[index]?.nacionality) && 
                                                        <FormFeedback type="invalid">{form.errors?.houseHoldMembers[index]?.nacionality}</FormFeedback>
                                                    } 
                                                </td>
                                                <td style={{width: '10%'}}>
                                                    <Field
                                                        type="number"
                                                        className={`fs-7 input-1 p-0 form-control ${form.errors?.houseHoldMembers?.length > 0 && form.errors.houseHoldMembers[index]?.age ? 'is-invalid' : ''}`}
                                                        name={`houseHoldMembers.${index}.age`} 
                                                    />     
                                                    {
                                                        (form.errors?.houseHoldMembers?.length > 0 && form.errors?.houseHoldMembers[index]?.age) && 
                                                        <FormFeedback type="invalid">{form.errors?.houseHoldMembers[index]?.age}</FormFeedback>
                                                    }
                                                </td>
                                                <td style={{width: '10%'}}>
                                                    <div className="form-check form-check-inline">
                                                        <Input
                                                            type="radio"
                                                            id={`genderHHMSection21_${index}`}
                                                            name={`genderHHM_${index}`}
                                                            className="form-check-input"
                                                            checked={form.values.houseHoldMembers[index].gender === 'Male'}
                                                            onChange={e=>{
                                                                form.setFieldValue(`houseHoldMembers.${index}.gender`, e.target.checked ? 'Male' : 'Female')
                                                            }}
                                                        />
                                                        <Label
                                                            className="form-check-label fw-light"
                                                            htmlFor={`genderHHMSection21_${index}`}
                                                        >
                                                            M
                                                        </Label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <Input
                                                            type="radio"
                                                            id={`genderHHMSection22_${index}`}
                                                            name={`genderHHM_${index}`}
                                                            className="form-check-input"
                                                            checked={form.values.houseHoldMembers[index].gender ==='Female'}
                                                            onChange={e=>{
                                                                form.setFieldValue(`houseHoldMembers.${index}.gender`, e.target.checked ? 'Female' : 'Male')
                                                            }}
                                                        />
                                                        <Label
                                                            className="form-check-label fw-light"
                                                            htmlFor={`genderHHMSection22_${index}`}
                                                        >
                                                            F
                                                        </Label>
                                                    </div>
                                                    {
                                                        (form.errors?.houseHoldMembers?.length > 0 && form.errors?.houseHoldMembers[index]?.gender) && 
                                                        <FormFeedback type="invalid">{form.errors?.houseHoldMembers[index]?.gender}</FormFeedback>
                                                    }
                                                </td>
                                                <td style={{width: '15%'}}>
                                                    <Field
                                                        className={`fs-7 input-1 p-0 form-control ${form.errors?.houseHoldMembers?.length > 0 && form.errors.houseHoldMembers[index]?.relationshipSponsor ? 'is-invalid' : ''}`}
                                                        name={`houseHoldMembers.${index}.relationshipSponsor`} 
                                                    />    
                                                    {
                                                        (form.errors?.houseHoldMembers?.length > 0 && form.errors?.houseHoldMembers[index]?.relationshipSponsor) && 
                                                        <FormFeedback type="invalid">{form.errors?.houseHoldMembers[index]?.relationshipSponsor}</FormFeedback>
                                                    }
                                                </td>
                                                <td style={{width: '12%'}}>
                                                    <Select
                                                    value={{
                                                        value: form.values.houseHoldMembers[index].relationship_id, 
                                                        label: relationshipOpt.find(item => item.value === form.values.houseHoldMembers[index].relationship_id)?.label
                                                    }}
                                                    onChange={(value) => {
                                                        form.setFieldValue(`houseHoldMembers.${index}.relationship_id`, value.value, true)
                                                    }}
                                                    options={relationshipOpt}
                                                    classNamePrefix="select2-selection"
                                                    closeMenuOnSelect={true}
                                                    className={`select2-1 ${form.errors?.houseHoldMembers?.length > 0 && form.errors?.houseHoldMembers[index]?.relationship_id ? 'is-invalid' : ''}`}
                                                />
                                                {
                                                    (form.errors?.houseHoldMembers?.length > 0 && form.errors?.houseHoldMembers[index]?.relationship_id) && 
                                                    <FormFeedback type="invalid">{form.errors?.houseHoldMembers[index]?.relationship_id}</FormFeedback>
                                                }
                                                </td>
                                                <td style={{width: '3%'}}>
                                                    <div className="text-center">
                                                        <i 
                                                            className="mdi mdi-close-circle-outline text-danger cursor-pointer fs-9"
                                                            onClick={async () => {
                                                                if(item.id){
                                                                    try {
                                                                        const response = await deleteHouseHolderMember(item.id)
                                                                        console.log(response)
                                                                        if(response.success){
                                                                            arrayHelper.remove(index)
                                                                            toast.success('Deleted success!')
                                                                        }else{
                                                                            toast.error('Error server!')
                                                                        }                                                                   
                                                                    } catch (error) {
                                                                        toast.error('Error server!')
                                                                    }
                                                                }else{
                                                                    arrayHelper.remove(index)
                                                                }                                                       
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    <tr>
                                        <td colSpan={7}>
                                            <Button type="button" color="primary" size="sm" className="btn btn-primary" onClick={() => arrayHelper.push({
                                                name: '',
                                                nacionality: '',
                                                age: '',
                                                gender: 'Male',
                                                relationshipSponsor: '',
                                                relationshipMinor: '',
                                            })}>
                                                <i className="mdi mdi-plus me-1"></i>
                                                Create new house holder member
                                            </Button>
                                        </td>
                                    </tr>
                                    
                                    </>
                                )}
                            />
                            </FormikProvider>                    
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <Row>
                <Col xs="12" md="12">
                    <Table bordered size="sm" style={{width: '100%'}}>
                        <thead>
                            <tr className="bg-dark bg-soft">
                                <th>REASON FOR REFERRAL</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <ReasonReferral form={form}/>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>

            {safetyStatus && <Row>
                <Col xs="12" md="12">
                    <Table bordered size="sm" style={{width: '100%'}}>
                        <thead>
                            <tr className="bg-dark bg-soft">
                                <th>SAFETY STATUS</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                {
                                    safetyStatus?.data?.questions.map(item=>(
                                        <div className="mb-2" key={item.id}>
                                            <h6>{item.title}</h6>
                                            <ControlledFieldSurvey 
                                                question={item}
                                                onChange={handleOnChange}
                                                respuesta={[]}
                                            />
                                        </div>
                                    ))
                                }
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>}
        </>
    )
}