import { StyleSheet, Text, View } from "@react-pdf/renderer";

export default function BulletTitle({text, sx={}}){
    const styles = StyleSheet.create({
        item: {
          flexDirection: 'row',
          marginBottom: 2,
          ...sx?.view
        },
        bulletPoint: {
            width: 10,
            fontSize: 10,
        },
        itemContent: {
            flexDirection: 'row',
            flexGrow: 1,
            fontSize: 9,
            fontFamily: 'Times New Roman',
        },
    });

    return (
        <View style={styles.item}>
            <Text style={styles.bulletPoint}>•</Text>
            <Text style={[styles.itemContent, sx]}>{text}</Text>
        </View>
    )
}