export default function useGetReasonForReferral(document) {
    const info = {
        header: 'REASON FOR REFERRAL',
        rows: [
            {
                style: {
                    height: 24,
                },
                columns: [
                    {
                        style: {
                            width: '100%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: 'PRS ONLY:',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Criminal history',
                        typeInput: 'checkbox',
                        checked: document?.reasonReferral?.prsOnly?.criminalHistory,
                    }
                ]
            },
            {
                style: {
                    height: 13,
                },
                columns: [
                    {
                        style: {
                            width: '100%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Gang involvement',
                        typeInput: 'checkbox',
                        checked: document?.reasonReferral?.prsOnly?.gangInvolvement,
                    }
                ]
            },
            {
                style: {
                    height: 13,
                },
                columns: [
                    {
                        style: {
                            width: '100%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Behavioral problems',
                        typeInput: 'checkbox',
                        checked: document?.reasonReferral?.prsOnly?.behavioralProblems,
                    }
                ]
            },
            {
                style: {
                    height: 13,
                },
                columns: [
                    {
                        style: {
                            width: '100%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Abuse history',
                        typeInput: 'checkbox',
                        checked: document?.reasonReferral?.prsOnly?.abuseHistory,
                    }
                ]
            },
            {
                style: {
                    height: 13,
                },
                columns: [
                    {
                        style: {
                            width: '100%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Trauma history',
                        typeInput: 'checkbox',
                        checked: document?.reasonReferral?.prsOnly?.traumaHistory,
                    }
                ]
            },
            {
                style: {
                    height: 13,
                },
                columns: [
                    {
                        style: {
                            width: '100%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Mental health',
                        typeInput: 'checkbox',
                        checked: document?.reasonReferral?.prsOnly?.mentalHealth,
                    }
                ]
            },
            {
                style: {
                    height: 13,
                },
                columns: [
                    {
                        style: {
                            width: '100%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Suicidal/self-harming behaviors',
                        typeInput: 'checkbox',
                        checked: document?.reasonReferral?.prsOnly?.suicidalBehaviors,
                    }
                ]
            },
            {
                style: {
                    height: 13,
                },
                columns: [
                    {
                        style: {
                            width: '100%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Substance abuse',
                        typeInput: 'checkbox',
                        checked: document?.reasonReferral?.prsOnly?.substanceAbuse,
                    }
                ]
            },
            {
                style: {
                    height: 13,
                },
                columns: [
                    {
                        style: {
                            width: '100%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Cognitive developmental delays',
                        typeInput: 'checkbox',
                        checked: document?.reasonReferral?.prsOnly?.cognitiveDevelopmentalDelays,
                    }
                ]
            },
            {
                style: {
                    height: 13,
                },
                columns: [
                    {
                        style: {
                            width: '100%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Lack of formal education',
                        typeInput: 'checkbox',
                        checked: document?.reasonReferral?.prsOnly?.lackFormalEducation,
                    }
                ]
            },
            {
                style: {
                    height: 13,
                },
                columns: [
                    {
                        style: {
                            width: '100%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Medical condition',
                        typeInput: 'checkbox',
                        checked: document?.reasonReferral?.prsOnly?.medicalCondition,
                    }
                ]
            },
            {
                style: {
                    height: 13,
                },
                columns: [
                    {
                        style: {
                            width: '100%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Disability',
                        typeInput: 'checkbox',
                        checked: document?.reasonReferral?.prsOnly?.disability,
                    }
                ]
            },
            {
                style: {
                    height: 13,
                },
                columns: [
                    {
                        style: {
                            width: '100%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Pregnant or parenting teen',
                        typeInput: 'checkbox',
                        checked: document?.reasonReferral?.prsOnly?.pregnantParentingTeen,
                    }
                ]
            },
            {
                style: {
                    height: 13,
                },
                columns: [
                    {
                        style: {
                            width: '100%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Lack of prior relationship with sponsor',
                        typeInput: 'checkbox',
                        checked: document?.reasonReferral?.prsOnly?.lackPriorRelationshipSponsor,
                    }
                ]
            },
            {
                style: {
                    height: 13,
                },
                columns: [
                    {
                        style: {
                            width: '100%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: `Sponsor's lack of knowledge/accessibility to community resources`,
                        typeInput: 'checkbox',
                        checked: document?.reasonReferral?.prsOnly?.sponsorLackKnowledge,
                    }
                ]
            },
            {
                style: {
                    height: 13,
                },
                columns: [
                    {
                        style: {
                            width: '100%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Trafficking concern',
                        typeInput: 'checkbox',
                        checked: document?.reasonReferral?.prsOnly?.traffickingConcern,
                    }
                ]
            },
            {
                style: {
                    height: 13,
                },
                columns: [
                    {
                        style: {
                            width: '100%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Sponsor Concerns',
                        typeInput: 'checkbox',
                        checked: document?.reasonReferral?.prsOnly?.sponsorConcerns,
                    }
                ]
            },
            {
                style: {
                    height: 13,
                },
                columns: [
                    {
                        style: {
                            width: '100%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'PRS after a Discretionary Home Study',
                        typeInput: 'checkbox',
                        checked: document?.reasonReferral?.prsOnly?.prsDiscretionary
                    }
                ]
            },
            {
                style: {
                    height: 13,
                },
                columns: [
                    {
                        style: {
                            width: '100%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Category 3 Sponsorship',
                        typeInput: 'checkbox',
                        checked: document?.reasonReferral?.prsOnly?.categorySponsorship
                    }
                ]
            },
            {
                style: {
                    height: 13,
                },
                columns: [
                    {
                        style: {
                            width: '100%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Other (List Reason)',
                        typeInput: 'checkbox',
                        checked: document?.reasonReferral?.prsOnly?.other
                    }
                ]
            },
            {
                style: {
                    height: 15,
                },
                columns: [
                    {
                        style: {
                            width: '100%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: '',
                        typeInput: 'label'
                    }
                ]
            },
            {
                style: {
                    height: 13,
                },
                columns: [
                    {
                        style: {
                            width: '100%',
                            textAlign: 'left',
                            justifyContent: 'end',
                            paddingLeft: 1, 
                        },
                        title: 'PRS AFTER TVPRA HOME STUDY:',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: '',
                        typeInput: 'label'
                    }
                ]
            },
            {
                style: {
                    height: 13,
                },
                columns: [
                    {
                        style: {
                            width: '100%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'TVPRA Category Required Post Release Services After Home Study Trafficking Concerns',
                        typeInput: 'checkbox',
                        checked: document?.reasonReferral?.prsAfter?.tvpraTraffickingConcerns
                    }
                ]
            },
            {
                style: {
                    height: 13,
                },
                columns: [
                    {
                        style: {
                            width: '100%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'TVPRA Category Required Post Release Services After Home Study Sponsor Concerns',
                        typeInput: 'checkbox',
                        checked: document?.reasonReferral?.prsAfter?.tvpraSponsorConcerns
                    }
                ]
            },
            {
                style: {
                    height: 13,
                },
                columns: [
                    {
                        style: {
                            width: '100%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'TVPRA Category Required Post Release Services After Home Study Disability',
                        typeInput: 'checkbox',
                        checked: document?.reasonReferral?.prsAfter?.tvpraDisability
                    }
                ]
            },
            {
                style: {
                    height: 13,
                },
                columns: [
                    {
                        style: {
                            width: '100%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'TVPRA Category Required Post Release Services After Home Study Abuse History',
                        typeInput: 'checkbox',
                        checked: document?.reasonReferral?.prsAfter?.tvpraAbuseHistory
                    }
                ]
            }
        ]
    }

    return info;
}