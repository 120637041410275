import useGetRelationShip from "../useGetRelationShip";

export default function useGetHouseHoldMembers(document) {
    const relationshipOpt = useGetRelationShip()
    //console.log(relationshipOpt)
    const extraRows  = document?.houseHoldMembers.map(item => ({
        style: {
            height: 18,
            borderBottomColor: '#000',
            borderBottomWidth: 1,
        },
        columns: [
            {
                style: {
                    width: '30%',
                    textAlign: 'left',
                    justifyContent: 'center',
                    borderRightColor: '#000',
                    borderRightWidth: 1,
                    paddingLeft: 1,
                },
                title: '',
                titleStyle: {
                    fontFamily: 'Times New Roman Bold',
                },
                text: item.name,
                typeInput: 'label'
            },
            {
                style: {
                    width: '15%',
                    textAlign: 'center',
                    justifyContent: 'center',
                    borderRightColor: '#000',
                    borderRightWidth: 1,
                    paddingLeft: 1, 
                },
                title: '',
                titleStyle: {
                    fontFamily: 'Times New Roman Bold',
                },
                text: item.nacionality,
                typeInput: 'label'
            },
            {
                style: {
                    width: '10%',
                    textAlign: 'center',
                    justifyContent: 'center',
                    borderRightColor: '#000',
                    borderRightWidth: 1,
                    paddingLeft: 1, 
                },
                title: '',
                titleStyle: {
                    fontFamily: 'Times New Roman Bold',
                },
                text: item.age,
                typeInput: 'label'
            },
            {
                style: {
                    width: '10%',
                    textAlign: 'center',
                    justifyContent: 'center',
                    borderRightColor: '#000',
                    borderRightWidth: 1,
                    paddingLeft: 1, 
                },
                title: '',
                titleStyle: {
                    fontFamily: 'Times New Roman Bold',
                },
                text: item.gender,
                typeInput: 'label'
            },
            {
                style: {
                    width: '20%',
                    textAlign: 'center',
                    justifyContent: 'center',
                    borderRightColor: '#000',
                    borderRightWidth: 1,
                    paddingLeft: 1, 
                },
                title: '',
                titleStyle: {
                    fontFamily: 'Times New Roman Bold',
                },
                text: item.relationshipSponsor,
                typeInput: 'label'
            },
            {
                style: {
                    width: '15%',
                    justifyContent: 'center',
                    textAlign: 'center',
                    paddingLeft: 1, 
                },
                title: '',
                titleStyle: {
                    fontFamily: 'Times New Roman Bold',
                },
                text: relationshipOpt.find(it=>it.value===item.relationship_id)?.label ?? '',
                typeInput: 'label'
            }
        ]
    }))

    //console.log(extraRows)

    const info= {
        header: 'HOUSEHOLD MEMBERS',
        rows: [
            {
                style: {
                    height: 30,
                    borderBottomColor: '#000',
                    borderBottomWidth: 1,
                },
                columns: [
                    {
                        style: {
                            width: '30%',
                            textAlign: 'center',
                            justifyContent: 'center',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                        },
                        title: 'Name',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: '',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '15%',
                            textAlign: 'center',
                            justifyContent: 'center',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                        },
                        title: 'Nationality',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: '',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '10%',
                            textAlign: 'center',
                            justifyContent: 'center',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                        },
                        title: 'Age',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: '',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '10%',
                            textAlign: 'center',
                            justifyContent: 'center',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                        },
                        title: 'Gender',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: '',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '20%',
                            textAlign: 'center',
                            justifyContent: 'center',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                        },
                        title: 'Relationship to the sponsor',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: '',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '15%',
                            textAlign: 'center',
                            justifyContent: 'center',
                            paddingLeft: 1, 
                        },
                        title: 'Relationship to the minor',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: '',
                        typeInput: 'label'
                    }
                ]
            },
            ...extraRows
        ]

    };

    return info;
}