import { useState } from "react";

export default function useGetServicesArea(){
    const [info, setInfo] = useState({
        header: 'SERVICE AREAS Discuss service goals/objectives, assistance provided, and outcomes, addressing the child’s and sponsor’s needs, strengths, coping skills and resiliency factors. See further instructions below regarding the Service Areas grid.',
        rows: [
            {
                style: {
                    height: 24,
                    borderBottomColor: '#000',
                    borderBottomWidth: 1,
                },
                columns: [
                    {
                        style: {
                            width: '20%',
                            textAlign: 'center',
                            justifyContent: 'center',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                        },
                        title: 'Domain:',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: '',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '30%',
                            textAlign: 'center',
                            justifyContent: 'center',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                        },
                        title: 'Goals/Objectives:',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: '',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '20%',
                            textAlign: 'center',
                            justifyContent: 'center',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                        },
                        title: 'Assistance provided',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: '',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '15%',
                            textAlign: 'center',
                            justifyContent: 'center',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                        },
                        title: 'Narrative',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: '',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '15%',
                            textAlign: 'center',
                            justifyContent: 'center',
                            paddingLeft: 1, 
                        },
                        title: 'Goals Achieved ',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: '',
                        typeInput: 'label'
                    }
                ]
            },
            {
                style: {
                    height: 120,
                    borderBottomColor: '#000',
                    borderBottomWidth: 1,
                },
                columns: [
                    {
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Placement Stability and Safety ',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '30%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Ensure placement shows signs of well adjustment (i.e., routines established, school in place, medical in place and counseling if necessary), and ensure placement is safe and appropriate (i.e., sleeping quarters appropriate for participant, food, clothing and home conditions appropriate for the care of a child)',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        itemsText: [
                            {
                                text: 'Discussed',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Educated',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Referred',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'Monitored',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'Provided directly',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Other',
                                typeInput: 'checkbox',
                                checked: false
                            }
                        ],
                        text: 'Discussed',
                        typeInput: 'list',
                    },
                    {
                        style: {
                            width: '15%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: '',
                        typeInput: 'list',
                        itemsText: [
                            {
                                text: '14-day report',
                                typeInput: 'label',
                                sxExtra: {
                                    fontFamily: 'Times New Roman Bold'
                                }
                            },
                            {
                                text: '90-day report',
                                typeInput: 'label',
                                sxExtra: {
                                    fontFamily: 'Times New Roman Bold'
                                }
                            },
                        ],
                    },
                    {
                        style: {
                            width: '15%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Yes',
                        typeInput: 'list',
                        checked: true,
                        itemsText: [
                            {
                                text: 'Yes',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'No',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'In Progress',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'N/A',
                                typeInput: 'checkbox',
                                checked: false
                            }
                        ],
                    }
                ]
            },
            {
                style: {
                    height: 80,
                    borderBottomColor: '#000',
                    borderBottomWidth: 1,
                },
                columns: [
                    {
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1,
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Guardianship',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '30%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'If the sponsor is not a parent or legal guardian, describe efforts made by the sponsor to obtain legal guardianship of the child. If guardianship will not be pursued, explain why',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        itemsText: [
                            {
                                text: 'Discussed',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Educated',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Referred',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'Monitored',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'Provided directly',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Other',
                                typeInput: 'checkbox',
                                checked: false
                            }
                        ],
                        text: 'Discussed',
                        typeInput: 'list',
                    },
                    {
                        style: {
                            width: '15%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1,
                            paddingTop: 1 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: '',
                        typeInput: 'list',
                        itemsText: [
                            {
                                text: '14-day report',
                                typeInput: 'label',
                                sxExtra: {
                                    fontFamily: 'Times New Roman Bold'
                                }
                            },
                            {
                                text: '90-day report',
                                typeInput: 'label',
                                sxExtra: {
                                    fontFamily: 'Times New Roman Bold'
                                }
                            },
                        ],
                    },
                    {
                        style: {
                            width: '15%',
                            textAlign: 'left',
                            paddingLeft: 1,
                            paddingTop: 1 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Yes',
                        typeInput: 'list',
                        checked: true,
                        itemsText: [
                            {
                                text: 'Yes',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'No',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'In Progress',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'N/A',
                                typeInput: 'checkbox',
                                checked: false
                            }
                        ],
                    }
                ]
            },
            {
                style: {
                    height: 80,
                    borderBottomColor: '#000',
                    borderBottomWidth: 1,
                },
                columns: [
                    {
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1,
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Immigration Proceedings',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '30%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'The PRS provider must monitor and help to facilitate the sponsor’s plan to ensure the UC’s attendance at all immigration court proceedings and compliance with DHS requirements',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        itemsText: [
                            {
                                text: 'Discussed',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Educated',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Referred',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'Monitored',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'Provided directly',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Other',
                                typeInput: 'checkbox',
                                checked: false
                            }
                        ],
                        text: 'Discussed',
                        typeInput: 'list',
                    },
                    {
                        style: {
                            width: '15%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1,
                            paddingTop: 1 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: '',
                        typeInput: 'list',
                        itemsText: [
                            {
                                text: '14-day report',
                                typeInput: 'label',
                                sxExtra: {
                                    fontFamily: 'Times New Roman Bold'
                                }
                            },
                            {
                                text: '90-day report',
                                typeInput: 'label',
                                sxExtra: {
                                    fontFamily: 'Times New Roman Bold'
                                }
                            },
                        ],
                    },
                    {
                        style: {
                            width: '15%',
                            textAlign: 'left',
                            paddingLeft: 1,
                            paddingTop: 1 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Yes',
                        typeInput: 'list',
                        checked: true,
                        itemsText: [
                            {
                                text: 'Yes',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'No',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'In Progress',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'N/A',
                                typeInput: 'checkbox',
                                checked: false
                            }
                        ],
                    }
                ]
            },
            {
                style: {
                    height: 70,
                    borderBottomColor: '#000',
                    borderBottomWidth: 1,
                },
                columns: [
                    {
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1,
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Legal Services',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '30%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Assist family in identifying a low cost or free legal service provider in their community',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        itemsText: [
                            {
                                text: 'Discussed',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Educated',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Referred',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'Monitored',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'Provided directly',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Other',
                                typeInput: 'checkbox',
                                checked: false
                            }
                        ],
                        text: 'Discussed',
                        typeInput: 'list',
                    },
                    {
                        style: {
                            width: '15%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1,
                            paddingTop: 1 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: '',
                        typeInput: 'list',
                        itemsText: [
                            {
                                text: '14-day report',
                                typeInput: 'label',
                                sxExtra: {
                                    fontFamily: 'Times New Roman Bold'
                                }
                            },
                            {
                                text: '90-day report',
                                typeInput: 'label',
                                sxExtra: {
                                    fontFamily: 'Times New Roman Bold'
                                }
                            },
                        ],
                    },
                    {
                        style: {
                            width: '15%',
                            textAlign: 'left',
                            paddingLeft: 1,
                            paddingTop: 1 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Yes',
                        typeInput: 'list',
                        checked: true,
                        itemsText: [
                            {
                                text: 'Yes',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'No',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'In Progress',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'N/A',
                                typeInput: 'checkbox',
                                checked: false
                            }
                        ],
                    }
                ]
            },
            {
                style: {
                    height: 70,
                    borderBottomColor: '#000',
                    borderBottomWidth: 1,
                },
                columns: [
                    {
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1,
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Education',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '30%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Assist the sponsor in identifying a local school and enrolling the minor',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        itemsText: [
                            {
                                text: 'Discussed',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Educated',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Referred',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'Monitored',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'Provided directly',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Other',
                                typeInput: 'checkbox',
                                checked: false
                            }
                        ],
                        text: 'Discussed',
                        typeInput: 'list',
                    },
                    {
                        style: {
                            width: '15%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1,
                            paddingTop: 1 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: '',
                        typeInput: 'list',
                        itemsText: [
                            {
                                text: '14-day report',
                                typeInput: 'label',
                                sxExtra: {
                                    fontFamily: 'Times New Roman Bold'
                                }
                            },
                            {
                                text: '90-day report',
                                typeInput: 'label',
                                sxExtra: {
                                    fontFamily: 'Times New Roman Bold'
                                }
                            },
                        ],
                    },
                    {
                        style: {
                            width: '15%',
                            textAlign: 'left',
                            paddingLeft: 1,
                            paddingTop: 1 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Yes',
                        typeInput: 'list',
                        checked: true,
                        itemsText: [
                            {
                                text: 'Yes',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'No',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'In Progress',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'N/A',
                                typeInput: 'checkbox',
                                checked: false
                            }
                        ],
                    }
                ]
            },
            {
                style: {
                    height: 70,
                    borderBottomColor: '#000',
                    borderBottomWidth: 1,
                },
                columns: [
                    {
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1,
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Medical Services',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '30%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Identify and refer minor to a medical provider in their community, assist with health insurance enrollment, if applicable',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        itemsText: [
                            {
                                text: 'Discussed',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Educated',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Referred',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'Monitored',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'Provided directly',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Other',
                                typeInput: 'checkbox',
                                checked: false
                            }
                        ],
                        text: 'Discussed',
                        typeInput: 'list',
                    },
                    {
                        style: {
                            width: '15%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1,
                            paddingTop: 1 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: '',
                        typeInput: 'list',
                        itemsText: [
                            {
                                text: '14-day report',
                                typeInput: 'label',
                                sxExtra: {
                                    fontFamily: 'Times New Roman Bold'
                                }
                            },
                            {
                                text: '90-day report',
                                typeInput: 'label',
                                sxExtra: {
                                    fontFamily: 'Times New Roman Bold'
                                }
                            },
                        ],
                    },
                    {
                        style: {
                            width: '15%',
                            textAlign: 'left',
                            paddingLeft: 1,
                            paddingTop: 1 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Yes',
                        typeInput: 'list',
                        checked: true,
                        itemsText: [
                            {
                                text: 'Yes',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'No',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'In Progress',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'N/A',
                                typeInput: 'checkbox',
                                checked: false
                            }
                        ],
                    }
                ]
            },
            {
                style: {
                    height: 110,
                    borderBottomColor: '#000',
                    borderBottomWidth: 1,
                },
                columns: [
                    {
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1,
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Individual Mental Health Services',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '30%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Identify and refer minor to a mental health provider, as necessary.  Describe the status of the minor’s mental health needs, including any services (e.g. counseling, psychotropic medication) the child receives to address mental health concerns',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        itemsText: [
                            {
                                text: 'Discussed',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Educated',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Referred',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'Monitored',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'Provided directly',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Other',
                                typeInput: 'checkbox',
                                checked: false
                            }
                        ],
                        text: 'Discussed',
                        typeInput: 'list',
                    },
                    {
                        style: {
                            width: '15%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1,
                            paddingTop: 1 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: '',
                        typeInput: 'list',
                        itemsText: [
                            {
                                text: '14-day report',
                                typeInput: 'label',
                                sxExtra: {
                                    fontFamily: 'Times New Roman Bold'
                                }
                            },
                            {
                                text: '90-day report',
                                typeInput: 'label',
                                sxExtra: {
                                    fontFamily: 'Times New Roman Bold'
                                }
                            },
                        ],
                    },
                    {
                        style: {
                            width: '15%',
                            textAlign: 'left',
                            paddingLeft: 1,
                            paddingTop: 1 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Yes',
                        typeInput: 'list',
                        checked: true,
                        itemsText: [
                            {
                                text: 'Yes',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'No',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'In Progress',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'N/A',
                                typeInput: 'checkbox',
                                checked: false
                            }
                        ],
                    }
                ]
            },
            {
                style: {
                    height: 70,
                    borderBottomColor: '#000',
                    borderBottomWidth: 1,
                },
                columns: [
                    {
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1,
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Family Stabilization/Counseling',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '30%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Identify and refer participant and family to family counseling, as necessary',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        itemsText: [
                            {
                                text: 'Discussed',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Educated',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Referred',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'Monitored',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'Provided directly',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Other',
                                typeInput: 'checkbox',
                                checked: false
                            }
                        ],
                        text: 'Discussed',
                        typeInput: 'list',
                    },
                    {
                        style: {
                            width: '15%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1,
                            paddingTop: 1 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: '',
                        typeInput: 'list',
                        itemsText: [
                            {
                                text: '14-day report',
                                typeInput: 'label',
                                sxExtra: {
                                    fontFamily: 'Times New Roman Bold'
                                }
                            },
                            {
                                text: '90-day report',
                                typeInput: 'label',
                                sxExtra: {
                                    fontFamily: 'Times New Roman Bold'
                                }
                            },
                        ],
                    },
                    {
                        style: {
                            width: '15%',
                            textAlign: 'left',
                            paddingLeft: 1,
                            paddingTop: 1 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Yes',
                        typeInput: 'list',
                        checked: true,
                        itemsText: [
                            {
                                text: 'Yes',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'No',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'In Progress',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'N/A',
                                typeInput: 'checkbox',
                                checked: false
                            }
                        ],
                    }
                ]
            },
            {
                style: {
                    height: 70,
                    borderBottomColor: '#000',
                    borderBottomWidth: 1,
                },
                columns: [
                    {
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1,
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Substance abuse',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '30%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Describe plans and efforts made (e.g. attending AA or NA meetings, substance abuse counseling) to address the child’s substance abuse issues, as applicable. ',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        itemsText: [
                            {
                                text: 'Discussed',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Educated',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Referred',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'Monitored',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'Provided directly',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Other',
                                typeInput: 'checkbox',
                                checked: false
                            }
                        ],
                        text: 'Discussed',
                        typeInput: 'list',
                    },
                    {
                        style: {
                            width: '15%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1,
                            paddingTop: 1 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: '',
                        typeInput: 'list',
                        itemsText: [
                            {
                                text: '14-day report',
                                typeInput: 'label',
                                sxExtra: {
                                    fontFamily: 'Times New Roman Bold'
                                }
                            },
                            {
                                text: '90-day report',
                                typeInput: 'label',
                                sxExtra: {
                                    fontFamily: 'Times New Roman Bold'
                                }
                            },
                        ],
                    },
                    {
                        style: {
                            width: '15%',
                            textAlign: 'left',
                            paddingLeft: 1,
                            paddingTop: 1 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Yes',
                        typeInput: 'list',
                        checked: true,
                        itemsText: [
                            {
                                text: 'Yes',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'No',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'In Progress',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'N/A',
                                typeInput: 'checkbox',
                                checked: false
                            }
                        ],
                    }
                ]
            },
            {
                style: {
                    height: 70,
                    borderBottomColor: '#000',
                    borderBottomWidth: 1,
                },
                columns: [
                    {
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1,
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Parenting/Supervision',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '30%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Describe any areas of parenting (e.g. supervision, adhering to appropriate child discipline methods) with which the sponsor needs assistance to meet the child’s needs.',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        itemsText: [
                            {
                                text: 'Discussed',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Educated',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Referred',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'Monitored',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'Provided directly',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Other',
                                typeInput: 'checkbox',
                                checked: false
                            }
                        ],
                        text: 'Discussed',
                        typeInput: 'list',
                    },
                    {
                        style: {
                            width: '15%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1,
                            paddingTop: 1 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: '',
                        typeInput: 'list',
                        itemsText: [
                            {
                                text: '14-day report',
                                typeInput: 'label',
                                sxExtra: {
                                    fontFamily: 'Times New Roman Bold'
                                }
                            },
                            {
                                text: '90-day report',
                                typeInput: 'label',
                                sxExtra: {
                                    fontFamily: 'Times New Roman Bold'
                                }
                            },
                        ],
                    },
                    {
                        style: {
                            width: '15%',
                            textAlign: 'left',
                            paddingLeft: 1,
                            paddingTop: 1 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Yes',
                        typeInput: 'list',
                        checked: true,
                        itemsText: [
                            {
                                text: 'Yes',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'No',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'In Progress',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'N/A',
                                typeInput: 'checkbox',
                                checked: false
                            }
                        ],
                    }
                ]
            },
            {
                style: {
                    height: 100,
                    borderBottomColor: '#000',
                    borderBottomWidth: 1,
                },
                columns: [
                    {
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1,
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Independent Living',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '30%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Note any independent living skills the minor seeks to obtain/improve (e.g. finances, work authorization, vocational training, transportation) and plans to help the minor achieve the desired independent living skills',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        itemsText: [
                            {
                                text: 'Discussed',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Educated',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Referred',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'Monitored',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'Provided directly',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Other',
                                typeInput: 'checkbox',
                                checked: false
                            }
                        ],
                        text: 'Discussed',
                        typeInput: 'list',
                    },
                    {
                        style: {
                            width: '15%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1,
                            paddingTop: 1 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: '',
                        typeInput: 'list',
                        itemsText: [
                            {
                                text: '14-day report',
                                typeInput: 'label',
                                sxExtra: {
                                    fontFamily: 'Times New Roman Bold'
                                }
                            },
                            {
                                text: '90-day report',
                                typeInput: 'label',
                                sxExtra: {
                                    fontFamily: 'Times New Roman Bold'
                                }
                            },
                        ],
                    },
                    {
                        style: {
                            width: '15%',
                            textAlign: 'left',
                            paddingLeft: 1,
                            paddingTop: 1 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Yes',
                        typeInput: 'list',
                        checked: true,
                        itemsText: [
                            {
                                text: 'Yes',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'No',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'In Progress',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'N/A',
                                typeInput: 'checkbox',
                                checked: false
                            }
                        ],
                    }
                ]
            },
            {
                style: {
                    height: 80,
                    borderBottomColor: '#000',
                    borderBottomWidth: 1,
                },
                columns: [
                    {
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1,
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Juvenile/Criminal Justice',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '30%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Describe any juvenile justice issues, including but not limited to proceedings pertaining to the minor’s criminal case, and the child’s probation services and requirements',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        itemsText: [
                            {
                                text: 'Discussed',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Educated',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Referred',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'Monitored',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'Provided directly',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Other',
                                typeInput: 'checkbox',
                                checked: false
                            }
                        ],
                        text: 'Discussed',
                        typeInput: 'list',
                    },
                    {
                        style: {
                            width: '15%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1,
                            paddingTop: 1 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: '',
                        typeInput: 'list',
                        itemsText: [
                            {
                                text: '14-day report',
                                typeInput: 'label',
                                sxExtra: {
                                    fontFamily: 'Times New Roman Bold'
                                }
                            },
                            {
                                text: '90-day report',
                                typeInput: 'label',
                                sxExtra: {
                                    fontFamily: 'Times New Roman Bold'
                                }
                            },
                        ],
                    },
                    {
                        style: {
                            width: '15%',
                            textAlign: 'left',
                            paddingLeft: 1,
                            paddingTop: 1 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Yes',
                        typeInput: 'list',
                        checked: true,
                        itemsText: [
                            {
                                text: 'Yes',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'No',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'In Progress',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'N/A',
                                typeInput: 'checkbox',
                                checked: false
                            }
                        ],
                    }
                ]
            },
            {
                style: {
                    height: 70,
                    borderBottomColor: '#000',
                    borderBottomWidth: 1,
                },
                columns: [
                    {
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1,
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Gang Prevention',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '30%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Provide the sponsor information about gang prevention programs in or around the sponsor’s   community',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        itemsText: [
                            {
                                text: 'Discussed',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Educated',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Referred',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'Monitored',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'Provided directly',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Other',
                                typeInput: 'checkbox',
                                checked: false
                            }
                        ],
                        text: 'Discussed',
                        typeInput: 'list',
                    },
                    {
                        style: {
                            width: '15%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1,
                            paddingTop: 1 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: '',
                        typeInput: 'list',
                        itemsText: [
                            {
                                text: '14-day report',
                                typeInput: 'label',
                                sxExtra: {
                                    fontFamily: 'Times New Roman Bold'
                                }
                            },
                            {
                                text: '90-day report',
                                typeInput: 'label',
                                sxExtra: {
                                    fontFamily: 'Times New Roman Bold'
                                }
                            },
                        ],
                    },
                    {
                        style: {
                            width: '15%',
                            textAlign: 'left',
                            paddingLeft: 1,
                            paddingTop: 1 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Yes',
                        typeInput: 'list',
                        checked: true,
                        itemsText: [
                            {
                                text: 'Yes',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'No',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'In Progress',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'N/A',
                                typeInput: 'checkbox',
                                checked: false
                            }
                        ],
                    }
                ]
            },
            {
                style: {
                    height: 90,
                    borderBottomColor: '#000',
                    borderBottomWidth: 1,
                },
                columns: [
                    {
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1,
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Other',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '30%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Document any other service areas for which the child needs assistance, the assistance provided, and the outcome or projected outcome after the child and sponsor receive assistance.',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                            paddingTop: 1
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        itemsText: [
                            {
                                text: 'Discussed',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Educated',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Referred',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'Monitored',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'Provided directly',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'Other',
                                typeInput: 'checkbox',
                                checked: false
                            }
                        ],
                        text: 'Discussed',
                        typeInput: 'list',
                    },
                    {
                        style: {
                            width: '15%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1,
                            paddingTop: 1 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: '',
                        typeInput: 'list',
                        itemsText: [
                            {
                                text: '14-day report',
                                typeInput: 'label',
                                sxExtra: {
                                    fontFamily: 'Times New Roman Bold'
                                }
                            },
                            {
                                text: '90-day report',
                                typeInput: 'label',
                                sxExtra: {
                                    fontFamily: 'Times New Roman Bold'
                                }
                            },
                        ],
                    },
                    {
                        style: {
                            width: '15%',
                            textAlign: 'left',
                            paddingLeft: 1,
                            paddingTop: 1 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Yes',
                        typeInput: 'list',
                        checked: true,
                        itemsText: [
                            {
                                text: 'Yes',
                                typeInput: 'checkbox',
                                checked: true
                            },
                            {
                                text: 'No',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'In Progress',
                                typeInput: 'checkbox',
                                checked: false
                            },
                            {
                                text: 'N/A',
                                typeInput: 'checkbox',
                                checked: false
                            }
                        ],
                    }
                ]
            }
        ]

    })

    return info;
}