import moment from "moment"

export const getAge = (fecha) => {
    let age = ''
    if(fecha){
        if(fecha.includes("/")){
            age = moment().diff(moment(fecha, "MM/DD/YYYY"),  'years')
        }else{
            age = moment().diff(moment(fecha, "MM/DD/YYYY"),  'years')
        }
    }
    
    return age;
}