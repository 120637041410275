import { useEffect, useState } from "react";

export default function useGetReferralResourceReport(data) {
    const [itemsText, setItemsText] = useState([])
    const [h,setH] = useState(60)

    useEffect(() => {
            const arrItems = []
            let exactH = data.length * 12;
            data.forEach((caseRef) => {
                exactH += caseRef.ReferralLists.length * 34
                let itemHead = {
                    text: caseRef.name,
                    typeInput: 'label',
                    sxExtra: {
                        marginTop: 5,
                        fontFamily: 'Times New Roman Bold',
                        paddingLeft: 5 
                    }
                }
                arrItems.push(itemHead)
                caseRef.ReferralLists.forEach(cRefListElem => {
                    let itName = {
                        text: cRefListElem.name,
                        typeInput: 'label',
                        sxExtra: {
                            marginTop: 2,
                            paddingLeft: 5                 
                        }
                    }
                    arrItems.push(itName)
                    let itAddress = {
                        text: cRefListElem.address,
                        typeInput: 'label',
                        sxExtra: {
                            paddingLeft: 5                 
                        }
                    }
                    arrItems.push(itAddress)
                    let itPhone = {
                        text: cRefListElem.phone,
                        typeInput: 'label',
                        sxExtra: {
                            paddingLeft: 5                 
                        }
                    }
                    arrItems.push(itPhone)
                    
                })
            })
            setH(exactH)
            setItemsText(arrItems) 
    }, [])

    const info = {
        header: '',
        headerType: 'list',
        itemHeader: [
            {
                text: 'Referrals & Resources'
            },
            {
                text: 'Please list referrals provided by the  PRS worker, or obtained by the family throughout the post-release service period',
                sxExtra: {
                    fontFamily: 'Times New Roman',
                }
            }
        ],
        rows: [
            {
                style: {
                    height: h,
                },
                columns: [
                    {
                        style: {
                            width: '100%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: '',
                        typeInput: 'list',
                        itemsText: itemsText
                    }
                ]
            }
        ]

    }
    return info;
}