import { useState } from "react";
import { Col, Row } from "reactstrap";
import QuestionInstructionsSection from "./QuestionInstructionsSection";

export default function Section6({form}){
    const { initialValues } = form;   
    const [serviceIndicatorQuestion, setServiceIndicatorQuestion] = useState(initialValues.ServiceInstructions)

    return(
        <>
            {serviceIndicatorQuestion && 
            <Row>
                <Col xs="12" md="12">
                    <h3 className="m-0 text-center bg-dark bg-soft py-4 mb-4">{serviceIndicatorQuestion?.data?.name}</h3>
                </Col>

                <Col xs="12"md="12">
                    <div className="ms-2">
                        <QuestionInstructionsSection questions={serviceIndicatorQuestion.data?.QuestionInstructionSections}/>
                    </div>                    
                </Col>
            </Row>}
        </>
    )
}