import { StyleSheet, Text, View } from "@react-pdf/renderer";
import BulletTitle from "./BulletTitle";
import { CheckboxTitle } from "./CheckboxTitle";

export default function RenderContentTable({typeInput, text, checked, items, sxExtra={}}){
    const sx = StyleSheet.create({
        column: {
            fontSize: 9,
            ...sxExtra
        },
        columnList: {
            flexDirection: 'column'
        }
    });
    switch(typeInput){
        case 'label':
            return <Text style={sxExtra}>{text}</Text>;
        case 'checkbox':
            return <CheckboxTitle text={text} sx={sx.column} checked={checked}/>;
        case 'bullet':
            return <BulletTitle text={text} sx={sx.column}/>;
        case 'list':
                return (
                    items.map((item, index) => (
                        <View style={sx.columnList} key={index.toString()} wrap>
                            <RenderContentTable 
                                typeInput={item.typeInput} 
                                text={item.text} 
                                checked={item?.checked ?? false}
                                sxExtra={item?.sxExtra}
                            />
                        </View>
                    ))
                    
                );
        default:
            return ''
    }


}