import { useEffect, useState } from "react";

export default function useGetCaseCloseProgram(data){
    const [info, setInfo] = useState(null);

    useEffect(() => {
            let itemsText = [];
            if(data.sectionsQ){
                Object.values(data.sectionsQ).forEach(sect => {
                    let itemHead = {
                        text: sect.title,
                        typeInput: 'label',
                        sxExtra: {
                            marginTop: 3,
                            fontFamily: 'Times New Roman Bold',
                            paddingLeft: 5
                        }
                    }
                    itemsText.push(itemHead);
                    sect.questions.forEach(qt => {
                        let itemTitle = {
                            text: qt.title,
                            typeInput: 'label',
                            sxExtra: {
                                paddingLeft: 5
                            }
                        }
                        itemsText.push(itemTitle);
                        if(qt.labels.length){
                            const columnLabels = qt.labels.map(lb => ({                                    
                                text: lb.value,
                                typeInput: 'checkbox',
                                checked: lb?.checked ?? false,
                                sxExtra: {
                                    viewCheckbox: {
                                        marginLeft: 12
                                    } 
                                }                            
                            }))

                            itemsText = [...itemsText, ...columnLabels]
                        }
                    })
                })
            }
            const obj = {
            header: '',
            headerType: 'list',
            itemHeader: [
                {
                    text: 'Case Closure Program Outcomes & Indicators'
                },
                {
                    text: '*Please complete this section at the time of case closure*',
                    sxExtra: {
                        fontFamily: 'Times New Roman',
                    }
                },
                {
                    text: 'Definitions of Indicator Questions are located at the end of this document'
                }
            
            ],
            rows: [
                    {
                        style: {
                            height: 1200,
                        },
                        columns: [
                            {
                                style: {
                                    width: '100%',
                                    textAlign: 'left',
                                    paddingLeft: 1, 
                                },
                                title: '',
                                titleStyle: {
                                    fontFamily: 'Times New Roman Bold',
                                },
                                text: '',
                                typeInput: 'list',
                                itemsText: itemsText
                            }
                        ]
                    }
                ]
            }
            setInfo(obj)
    }, [])

    return info;
}