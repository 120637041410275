import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
    Input,
    Button,
    Card,
    CardBody,
    CardTitle,
  } from "reactstrap";
import { ERROR_SERVER, IMPORT_SUCCESS } from "../../constants/messages";
import { getCaseByNumero, postUploadFile } from "../../helpers/case";
import ModalInformativo from "../Common/ModalInformativo";
import FullScreenLoader from "../Loader/FullScreenLoader";
import Select from "react-select";

export default function UploadDocument() {
    const [file, setFile] = useState([]);
    const [progress, setProgress] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const inputFile = useRef(null);
    const history = useHistory();
    const [openModal, setOpenModal] = useState(false)
    const [idCase, setIdCase] = useState(null)
    const [is_sponsor_assessment, setIs_Sponsor_Assessment] = useState({value: 0, label: 'PDF Version 1'})
  
    const handleChange = async (e) => {
        e.preventDefault();
      setIsSubmitting(true)
      let formData = new FormData();
      formData["file"] = e.target.files[0];
      try {
                                              //is_sponsor_assessment.value
        const response = await postUploadFile(is_sponsor_assessment.value, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
        });
        console.log(response)
        if(response.success){
            const aNumber = response.content.done.aNumber
            try {
                const caseToV = await getCaseByNumero(aNumber);
                setIdCase(caseToV?.content?.id)
                setOpenModal(true)
            } catch (error) {
                toast.success(IMPORT_SUCCESS);
                history.push({ pathname: '/case-add', state: { document: response.content?.done ?? {} } });
            }
            

        }
        setIsSubmitting(false);
      } catch (error) {
        console.log(error)
        setIsSubmitting(false);
        const { code } = error.response.data;
        switch (code) {
            case "FILE_MISSING":
              toast.error("Please select a file before uploading!");
              break;
            case "INVALID_TYPE":
              toast.error(
                "This file type is not support! Only .pdf files are allowed!"
              );
              break;
            case "LIMIT_FILE_SIZE":
              toast.error(
                "File size is too large! Please upload file bellow 1MB!"
              );
              break;
            default:
              toast.error(ERROR_SERVER);
              break;
        }
      }
       
      
    //   .then((data) => {
    //     setIsSubmitting(false);
    //     setFile([]);
    //     setProgress(0);
    //     console.log(data);
    //     if(data.success){
    //         const caseToV = await getCaseByNumero(numero.value);
    //     }
    //     toast.success(IMPORT_SUCCESS);
    //     //history.push({ pathname: '/case-add', state: { document: data.content?.done ?? {} } });
    //   })
    //   .catch((reason) => {
    //     const { code } = reason.response.data;
    //     switch (code) {
    //       case "FILE_MISSING":
    //         toast.error("Please select a file before uploading!");
    //         break;
    //       case "INVALID_TYPE":
    //         toast.error(
    //           "This file type is not support! Only .pdf files are allowed!"
    //         );
    //         break;
    //       case "LIMIT_FILE_SIZE":
    //         toast.error(
    //           "File size is too large! Please upload file bellow 1MB!"
    //         );
    //         break;
    //       default:
    //         toast.error(ERROR_SERVER);
    //         break;
    //     }
    //     setIsSubmitting(false);
    //     setProgress(0);
    //     setFile([]);
    //   });
    };
  
    const triggerHandleFile = () => {
      if (
        inputFile.current !== undefined &&
        inputFile.current.click !== undefined
      )
        inputFile.current.click();
    };

    const onClickViewCase = () => {
        setOpenModal(false)
        history.push(`/case-edit/${idCase}`);
    }
  
    return (
      <>
      { isSubmitting && <FullScreenLoader /> }
        <Card>
            <CardBody>
                <CardTitle className="h4 mb-4">Upload case</CardTitle>
                <div className="mb-2">
                  <Select
                        value={is_sponsor_assessment}
                        onChange={(value) => {
                            setIs_Sponsor_Assessment(value)
                        }}
                        options={[
                          {value: 0, label: 'PDF Version 1'},
                          {value: 1, label: 'PDF Version 2'}
                        ]}
                        closeMenuOnSelect={true}
                    />
                </div>
                
                <Input
                    type="file"
                    name="file"
                    onChange={handleChange}
                    innerRef={inputFile}
                    hidden
                />
                <Button
                    color="light"
                    block
                    onClick={() => triggerHandleFile()}
                >Select pdf file</Button>
            </CardBody>
        </Card>
        <ModalInformativo
            open={openModal}
            setOpen={setOpenModal}
            title={"Case number already exists!"}
            text={"Would you like to see the information"}
            onClickOk={() => {}}
            existCase={true}
            onClickViewCase={onClickViewCase}
        />
      </>
    );
  }
  