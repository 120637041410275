import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Card, CardBody, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ERROR_SERVER, requiredField, SAVE_SUCCESS, SELECT_OPTION, UPDATE_SUCCESS } from "../../constants/messages";
import FullScreenLoader from "../Loader/FullScreenLoader";
import { postReportTopConfiguration, putReportTopConfiguration } from "../../helpers/headerreport";
import useGetHeaderReport from "../../hooks/useGetHeaderReport";
import Select from "react-select";

export default function HeaderReportForm({id, item}){
    const { headerReport } = useGetHeaderReport()
    const [isSubmiting, setIsSubmiting] = useState(false)
    const history = useHistory();
    const initialValues =  item ? item :{
        title: '',
        show: true,
        type: '',
        report_top_configuration_id: null
    };
    const form = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: Yup.object({
            title: Yup.string().required(requiredField),
            type: Yup.string().required(requiredField),
        }),
        onSubmit: async (values) => { 
            setIsSubmiting(true)
            try {
                if(values.id){
                    const response = await putReportTopConfiguration(id, values);  
                    if(response.success){
                        toast.success(UPDATE_SUCCESS)
                    }else{
                        toast.error(ERROR_SERVER)
                    }         
                    setIsSubmiting(false)            
                }else{
                    const response = await postReportTopConfiguration(values)
                    if(response.success){
                        toast.success(SAVE_SUCCESS)
                    }else{
                        toast.error(ERROR_SERVER)
                    }  
                    setIsSubmiting(false)                   
                }
                setTimeout(() => {
                    history.goBack();
                }, 2000)
                
                
            } catch (error) {
                toast.error(ERROR_SERVER)
                setIsSubmiting(false) 
            }         
        }
    })
    return (
        <Card>
            {isSubmiting && <FullScreenLoader />}
            <CardBody>
                <Form
                    className="needs-validation"
                    id="tooltipForm"
                    onSubmit={(e) => {
                    e.preventDefault();
                    form.handleSubmit();
                    return false;
                }}
                >
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="title"
                        className="col-form-label col-lg-2"
                      >
                        Title
                      </Label>
                      <Col lg="10">
                        <Input
                            type="text"
                            id="title"
                            name="title"
                            className={`form-control ${form.errors.title ? 'is-invalid' : ''}`}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={form.values.title || ""}  
                        />
                        {
                            form.errors.title && 
                            <FormFeedback type="invalid">{form.errors.title}</FormFeedback>
                        }
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="type"
                        className="col-form-label col-lg-2"
                      >
                        Type
                      </Label>
                      <Col lg="10">
                        <select 
                            className={`form-select ${form.errors.type ? 'is-invalid' : ''}`}
                            id="type"
                            name="type"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={form.values.type || ""}
                        >
                            <option value="">{SELECT_OPTION}</option>
                            <option value="prs_case_type">PRS CASE TYPE</option>
                            <option value="prs_visit_type">PRS VISIT TYPE</option>
                            <option value="prs_level">PRS LEVEL</option>
                            <option value="case_closing_summary">CASE CLOSING SUMMARY</option>
                        </select>
                        {
                            form.errors.type && 
                            <FormFeedback type="invalid">{form.errors.type}</FormFeedback>
                        }
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="title"
                        className="col-form-label col-lg-2"
                      >
                        Assign to
                      </Label>
                      <Col lg="10">
                        <Select
                            value={form.values.report_top_configuration_id ? {
                                value: form.values.report_top_configuration_id, 
                                label: headerReport.find(item => item.value === form.values.report_top_configuration_id)?.label
                            }: null}
                            onChange={(value) => {
                                if(value){
                                    form.setFieldValue(`report_top_configuration_id`, value.value)
                                }else{
                                    form.setFieldValue(`report_top_configuration_id`, null)
                                }
                                
                            }}
                            options={headerReport}
                            classNamePrefix="select2-selection"
                            closeMenuOnSelect={true}
                            isClearable
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="show"
                        className="col-form-label col-lg-2"
                      >
                        
                      </Label>
                      <Col lg="10">
                        <div className="form-check form-check-end">
                            <Input
                                type="checkbox"
                                id="show"
                                name="show"
                                className={`form-check-input ${form.errors.show ? 'is-invalid' : ''}`}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                                checked={form.values.show || ""}  
                            />
                            <label
                                className="form-check-label"
                                htmlFor="show"
                            >
                            Show
                          </label>
                        </div>
                      </Col>
                    </FormGroup>
                    <Row className="justify-content-end">
                        <Col lg="10">
                        <Button type="submit" color="success" className="me-2">
                            Save
                        </Button>
                        <Link to="/topconfiguration-list" className="btn btn-light waves-effect">Cancel</Link>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
        </Card>
    )
}