import { useEffect } from "react";
import { useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumbs";
import HeaderReportForm from "../../components/HeaderReport/HeaderReportForm";
import SimpleLoad from "../../components/Loader/SimpleLoad";
import { ERROR_SERVER } from "../../constants/messages";
import { getReportTopConfiguration } from "../../helpers/headerreport";

function HeaderReportEdit(){
    const {id} = useParams()
    const [loading, setLoading] = useState(true)
    const [item, setItem] = useState(null)

    useEffect(() => {
        let isSubscribe = true;
        setLoading(true)
        async function getItemAPI(){
            if(isSubscribe){
                try {
                    const response = await getReportTopConfiguration(id);
                    if(response.success){
                        const itemToEdit = {
                            id: response.content.id,
                            title: response.content.title,
                            show: response.content.show,
                            type: response.content.type,
                            report_top_configuration_id: response.content.report_top_configuration_id ?? null
                        }
                        setItem(itemToEdit)
                        setLoading(false)
                    }else{
                        toast.error(ERROR_SERVER)
                    }
                    
                } catch (error) {
                    toast.error(ERROR_SERVER)
                }                
            }
        }
        getItemAPI();
        return () => isSubscribe = false;
    }, [id])

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Header Report" breadcrumbItem="Edit header report" />
                <Row>
                    <Col xs="12" lg="12">
                        { loading ? <SimpleLoad /> : <HeaderReportForm item={item} id={id}/>}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default withRouter(HeaderReportEdit)