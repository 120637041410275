import { useEffect, useState } from "react";
import { Button, Col, Label, Modal, Row, Table } from "reactstrap";
import { SELECT_OPTION } from "../../constants/messages";

export default function Section3({form}){
    const { initialValues } = form;
    const [referralResources, setReferralResources] = useState(initialValues?.ReferralResource)
    const [serviceAreas, setServicesAreas] = useState(initialValues?.ServiceAreas)
    const [reSet, setReSet] = useState(false)
    const [openDialog, setOpenDialog] = useState(false);
    const [indexSelected, setIndexSelected] = useState(null)
    const [type, setType] = useState('')
    const [text, setText] = useState('')

    const deletReferrals = (index, rIdx) => {
        const copyReff = [...referralResources.data]
        const reffList = [...copyReff[index].ReferralLists]
        reffList.splice(rIdx, 1)
        copyReff[index].ReferralLists = reffList
        setReferralResources(prev=>({
            ...prev, 
            data: copyReff
        }))
        setReSet(true)
    }

    useEffect(() => {
        if(reSet){
            form.setFieldValue('ReferralResource', referralResources)
            setReSet(false)
        }
    }, [reSet])

    //console.log(serviceAreas)

    const onHandleAssistenceChecked = (serviceIdx, checked, assitenceIdx) => {
        const copyServiceAreasP = [ ...serviceAreas.data ]
        const refAssitence = [...copyServiceAreasP[serviceIdx].assitenceProvide]
        refAssitence[assitenceIdx].checked = checked;
        copyServiceAreasP[serviceIdx].assitenceProvide = refAssitence
        setServicesAreas(prev=>({
            ...prev,
            data: copyServiceAreasP
        }))          
    }
    const onHandleGoalsAchievedChecked = (serviceIdx, checked, goalsAchievedIdx) => {
        const copyServiceAreasP = [ ...serviceAreas.data ]
        const refGoalsAchieved = [...copyServiceAreasP[serviceIdx].goalsAchieved]
        refGoalsAchieved[goalsAchievedIdx].checked = checked;
        copyServiceAreasP[serviceIdx].goalsAchieved = refGoalsAchieved
        setServicesAreas(prev=>({
            ...prev,
            data: copyServiceAreasP
        }))          
    }

    const showDialogCreateNarrative = (serviceIndex) => {
        setIndexSelected(serviceIndex)
        setOpenDialog(true)
    }

    const tog_center = () => {
        setOpenDialog(!openDialog);
        setIndexSelected(null)
        document.body.classList.add("no_padding");
    }

    const onClickOk = () => {
        const copyServiceAreasP = [ ...serviceAreas.data ]
        const refNarrative = [...copyServiceAreasP[indexSelected].narrative]
        const obj = {
            type: type,
            text: text
        }
        refNarrative.push(obj);
        copyServiceAreasP[indexSelected].narrative = refNarrative
        setServicesAreas(prev=>({
            ...prev,
            data: copyServiceAreasP
        }))
        setType('')
        setText('')
        setIndexSelected(null)
        setOpenDialog(false)
    }
    const deleteNarrative = (serviceIndex, narrativeIndex) => {
        const copyServiceAreasP = [ ...serviceAreas.data ]
        const refNarrative = [...copyServiceAreasP[serviceIndex].narrative]
        refNarrative.splice(narrativeIndex, 1)
        copyServiceAreasP[serviceIndex].narrative = refNarrative
        setServicesAreas(prev=>({
            ...prev,
            data: copyServiceAreasP
        }))
    }

    useEffect(() => {
        form.setFieldValue('ServiceAreas', serviceAreas)
    }, [serviceAreas])
    
    return(
        <>
            <Row>
                <Col xs="12" md="12">
                    <Table bordered size="sm" style={{width: '100%'}}>
                        <thead>
                            <tr className="bg-dark bg-soft">
                                <th colSpan={5}>
                                    <div>SERVICE AREAS</div>
                                    <div className="fw-light">
                                    Discuss service goals/objectives, assistance provided, and outcomes, addressing the child's 
                                    and sponsor's needs, strengths, coping skills and resiliency factors. See further instructions 
                                    below regarding the Service Areas grid.
                                    </div>
                                </th>
                            </tr>
                            <tr className="text-center align-middle">
                                <th width="10%">Domain</th>
                                <th width="20%">Goals/Objectives</th>
                                <th width="10%">Assistance provided</th>
                                <th width="50%">Narrative</th>
                                <th width="10%">Goals Achieved</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                serviceAreas?.data?.map((service, serviceIndex) => (
                                    <tr key={`servicearea_${serviceIndex}`} className="fs-7">
                                        <td>{service.domain}</td>
                                        <td>{service.goals}</td>
                                        <td>
                                            {
                                                service.assitenceProvide.map((assitence, assistanceIndex) => (
                                                    <div className="form-check mb-1" key={`assistence_${assistanceIndex}`}>
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id={`assistence_id_${serviceIndex}_${assistanceIndex}`}
                                                            onChange={(e)=>onHandleAssistenceChecked(serviceIndex, e.target.checked, assistanceIndex)} 
                                                            defaultChecked={assitence?.checked ?? false}
                                                        />
                                                        <label
                                                            className="form-check-label fw-400"
                                                            htmlFor={`assistence_id_${serviceIndex}_${assistanceIndex}`}
                                                        >{assitence.label}</label>
                                                    </div>
                                                ))
                                            }
                                        </td>
                                        <td>
                                            {
                                                service.narrative.map((item, idx) => (
                                                    <div key={`narrative_${idx}`}>
                                                        <div className="d-flex">
                                                            <div className="pe-2">
                                                            <Button color="link" className="text-danger p-0" onClick={() => deleteNarrative(serviceIndex, idx)}>
                                                                <i className="mdi mdi-trash-can-outline" />
                                                            </Button>
                                                            </div>
                                                            <div>
                                                                <strong className="d-block">{item.type}</strong>
                                                                <p className="m-0">{item.text}</p>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                ))
                                            }
                                            {service.narrative.length < 2 && 
                                            <Button color="link" onClick={() => showDialogCreateNarrative(serviceIndex)}>
                                            <i className="bx bx-plus" /> Add new narrative
                                            </Button>}
                                        </td>
                                        <td>
                                            {
                                                service.goalsAchieved.map((assitence, goalsIndex) => (
                                                    <div className="form-check mb-1" key={`goalsachieved_${goalsIndex}`}>
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id={`goalsachieved_id_${serviceIndex}_${goalsIndex}`}
                                                            onChange={(e)=>onHandleGoalsAchievedChecked(serviceIndex, e.target.checked, goalsIndex)} 
                                                            defaultChecked={assitence?.checked ?? false}
                                                        />
                                                        <label
                                                            className="form-check-label fw-400"
                                                            htmlFor={`goalsachieved_id_${serviceIndex}_${goalsIndex}`}
                                                        >{assitence.label}</label>
                                                    </div>
                                                ))
                                            }
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <Row>
                <Col xs="12" md="12">
                    <Table bordered size="sm" style={{width: '100%'}}>
                        <thead>
                            <tr className="bg-dark bg-soft">
                                <th>
                                    <div>Referrals & Resources</div>
                                    <div className="fw-light">
                                        Please list referrals provided by the  PRS worker, or obtained by the family throughout the post-release service period
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {
                                        referralResources?.data?.map((referr, index) => (
                                            <Row key={referr.id} className="mt-2">
                                                <Col xs="12" md="12">
                                                    <h6 className="m-0">{referr.name}</h6>
                                                </Col>

                                                <Col xs="12" md="12">
                                                {
                                                    referr.ReferralLists.map((reflist, rIdx) => (
                                                        <div className="d-flex mb-2" key={reflist.id}>
                                                            <div className="pe-3">
                                                                <i 
                                                                    className="mdi mdi-trash-can-outline text-danger" 
                                                                    onClick={() => deletReferrals(index, rIdx)}
                                                                />
                                                            </div>
                                                            <div>
                                                                <span className="d-block fw-bold fs-7">{reflist.name}</span>
                                                                <span className="d-block fs-7">{reflist.address}</span>
                                                                <span className="d-block fs-7">{reflist.phone}</span>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                                </Col>
                                            </Row>
                                        ))
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <Modal
                isOpen={openDialog}
                toggle={() => {
                tog_center();
                }}
                centered
                backdrop={'static'}
                id="staticBackdrop"
            >
                <div className="modal-header">
                <h5 className="modal-title mt-0">New narrative</h5>
                <button
                    type="button"
                    onClick={() => {setOpenDialog(false);}}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div className="modal-body">
                    <div className="mb-3">
                        <Label className="form-label">Type</Label>
                        <select 
                            className={`form-select`}
                            onChange={e=> setType(e.target.value)}
                            value={type}
                        >
                            <option value="">{SELECT_OPTION}</option>
                            <option value="14-day report">14-day report</option>
                            <option value="90-day report">90-day report</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <Label className="form-label">Option</Label>
                        <textarea
                            className={`form-control`} 
                            rows="3"
                            value={text}
                            onChange={e=>setText(e.target.value)}
                        />
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={() => tog_center()}>Cancel</button>
                    <button type="button" className="btn btn-primary" onClick={onClickOk}>Ok</button>
                </div>
            </Modal>
        </>
    )
}