import { withRouter } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumbs"
import SurveyForm from "../../components/Survey/SurveyForm"

function UserAdd(){
    
    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Survey" breadcrumbItem="New survey" />
                <Row>
                    <Col xs="12" lg="12">
                        <SurveyForm document={null}/>
                    </Col>
                </Row>
            </Container>
        </div>
    )

}

export default withRouter(UserAdd)