import { useState } from "react";

export default function useGetSignatureInfo(document) {
    const [info, setInfo] = useState({
        header: 'Signatures',
        rows: [
            {
                style: {
                    height: 18,
                    borderBottomColor: '#000',
                    borderBottomWidth: 1,
                },
                columns: [
                    {
                        style: {
                            width: '80%',
                            textAlign: 'left',
                            justifyContent: 'center',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Case Manager Signature (electronic): Dalie Ortet, CM',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                            justifyContent: 'center', 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Date:',
                        typeInput: 'label'
                    },
                ]
            },
            {
                style: {
                    height: 18,
                    borderBottomColor: '#000',
                    borderBottomWidth: 1,
                },
                columns: [
                    {
                        style: {
                            width: '80%',
                            textAlign: 'left',
                            justifyContent: 'center',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Supervisor Signature (electronic): Daylin Rodriguez, LPC',
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                            justifyContent: 'center', 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: 'Date:',
                        typeInput: 'label'
                    },
                ]
            },
        ]

    })

    return info;
}