import { useEffect, useState } from "react"
import { Link, withRouter } from "react-router-dom"
import { Badge, Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumbs"
import SimpleTable from "../../components/Tables/SimpleTable";
import { statuses } from "../../constants/statuses";
import Select from "react-select";
import moment from 'moment';
import { getCaseList } from "../../helpers/case";
import Paginate from "../../components/Tables/Paginate";
import { monthsOptSelect, rangeYearOptSelect } from "../../constants/dates";
import { toast } from "react-toastify";
import { ERROR_SERVER } from "../../constants/messages";
import FullScreenLoader from "../../components/Loader/FullScreenLoader";

function DocumentList(){
    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(true)
    const [status, setStatus] = useState(null)
    const [month, setMonth] = useState(null)
    const [year, setYear] = useState(null)
    const [page, setPage] = useState(0)
    const [totalPaginas, setTotalPaginas] = useState(0) 

    const [query, setQuery] = useState({
        page: 0,
        size: 10
    })

    const onHandleSearch = () => {
        let st = status ? status.value : null
        let startDate = null;
        let endDate = null;
        if(year && month){
            startDate = moment({year: year.value, month: month.value}).startOf('month').format("YYYY-MM-DD")
            endDate = moment({year: year.value, month: month.value}).endOf('month').format("YYYY-MM-DD")
        }
        setQuery(prev=>({
            ...prev,
            page: 0,
            status: st,
            startDate: startDate,
            endDate: endDate
        }))
    }

    useEffect(() => {
        setLoading(true)
        let isSubscribed = true;
        async function getCaseListApi(){
            let q = Object.keys(query).map(key=>`${key}=${query[key]}`).join("&")
            try {
                const response = await getCaseList(`?${q}`);
                setItems(response.content)
                setTotalPaginas(response.total_pages)
                setLoading(false)
            } catch (error) {
                setItems([])
                setTotalPaginas(0)
                toast.error(ERROR_SERVER)
                setLoading(false)
            }            
        }
        if(isSubscribed) getCaseListApi()
        return () => isSubscribed = false;
    }, [query])

    const columns = [
        {
          text: "id",
          dataField: "id",
          sort: true,
          hidden: true,
        },
        {
            text: "A#",
            dataField: "aNumber",
            style: {
                width: '8%'
            }         
        },
        {
            text: "Name",
            dataField: "name", 
            style: {
                width: '15%'
            },
            formatter: (cell, row) => `${row.name} ${row.lastName}`      
        },        
        {
            text: "Date of acceptance",
            dataField: "CaseInfo.dateAcceptance", 
            style: {
                width: '14%'
            }, 
            formatter: (cell) => cell && moment(cell, "YYYY-MM-DD").format("MM/DD/YYYY")         
        },
        {
            text: "Sponsor Name",
            dataField: "SponsorInfo.name", 
            style: {
                width: '17%'
            },
            formatter: (cell, row) => `${row.SponsorInfo?.name} ${row.SponsorInfo?.lastName}`      
        },
        {
            text: "Sponsor Address",
            dataField: "SponsorInfo.homeAddress", 
            style: {
                width: '25%'
            }          
        },
        {
            text: "Sponsor Contact",
            dataField: "name", 
            style: {
                width: '11%'
            },
            formatter: (cell, row) => (
                row.SponsorInfo.ContactNumbers.map(contact => (
                    <span className="d-block">{contact.number}</span>
                ))
            )
        },
        {
            dataField: "menu",
            isDummyField: true,
            editable: false,
            text: "Actions",
            style: {
                width: '10%'
            },   
            formatter: (cellContent, row) => (
                <>
                    <Link to={`/case-edit/${row.id}`} className="me-2"><i className="fs-5 mdi mdi-square-edit-outline"/></Link>
                    <Link to={`/case-reminders/${row.id}`} className="text-secondary"><i className="fs-5 mdi mdi-calendar-clock"/></Link>
                </>
                
            ),
        },
    ];

    const rowStyle = (row) => {
        const style = {};
        switch(row.CaseInfo.status){
            case 'active':
                style.backgroundColor = '#8eed5940';
                break;
            case 'maintenance':
                style.backgroundColor = '#ed59ce40';
                break;
            case 'close':
                style.backgroundColor = '#ede75970';
                break;
            default:
                break;
        }
        return style;
    }

    const handlePageClick = page => {
        setPage(page)
        setQuery(prev=>({
            ...prev,
            page: page
        }))
    }
    //console.log(status)

    return (
        <div className="page-content">
            {loading && <FullScreenLoader />}
            <Container fluid>
                <Breadcrumbs title="Case" breadcrumbItem="Case List" />
                <div className="d-flex flex-column flex-md-row justify-content-end align-items-center">
                    <div className="p-2">
                        <Select
                            value={month}
                            onChange={(value) => setMonth(value)}
                            options={monthsOptSelect}
                            classNamePrefix="select2-selection"
                            placeholder="Choose month"
                            isClearable
                        />
                    </div>
                    <div className="p-2 pe-4">
                        <Select
                            value={year}
                            onChange={(value) => setYear(value)}
                            options={rangeYearOptSelect}
                            classNamePrefix="select2-selection"
                            placeholder="Choose year"
                            isClearable
                        />
                    </div>
                    <div className="p-2">
                        <Select
                            value={status}
                            onChange={(value) => setStatus(value)}
                            options={statuses}
                            classNamePrefix="select2-selection"
                            placeholder="Choose status"
                            isClearable
                        />
                    </div>
                    <div className="p-2">
                        <Button
                            color="primary"
                            className="btn-block btn btn-primary"
                            type="button"
                            onClick={onHandleSearch}
                        >
                            <i className="mdi mdi-magnify" />
                        </Button>
                    </div>
                </div>
                <Card>
                    <CardBody>
                        <Row>
                            <Col xs="12" lg="12">
                                <SimpleTable 
                                    columns={columns}
                                    items={items} 
                                    rowStyle={rowStyle}
                                />
                            </Col>

                            {
                                items.length > 0 &&
                                <Paginate
                                    page={page}
                                    totalPaginas={totalPaginas}
                                    totalRegistros={0}
                                    handlePageClick={handlePageClick}
                                    limit={query.limite}
                                    handleChangeLimit={() => {}}
                                />
                            }
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </div>
    )
}

export default withRouter(DocumentList)