import { get, post, put } from "./api";
import * as url from "./url";

const getDataReport = id => get(`${url.DATA_REPORT}/${id}`)

const postDataReport = data => post(`${url.DATA_REPORT}`, data)

const putDataReport = (id, data) => put(`${url.DATA_REPORT}/${id}`, data)

const getSections = () => get(`${url.DATA_REPORT}/sections`)

const getDataReportBySection = section => get(`${url.DATA_REPORT}/section/${section}`)

export {
    getDataReport,
    postDataReport,
    putDataReport,
    getSections,
    getDataReportBySection
}