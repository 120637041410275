import { withRouter } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumbs"
import RelationshipForm from "../../components/Relationship/RelationshipForm"

function RelationshipAdd(){
    
    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Relationships" breadcrumbItem="New relationships" />
                <Row>
                    <Col xs="12" lg="12">
                        <RelationshipForm document={null}/>
                    </Col>
                </Row>
            </Container>
        </div>
    )

}

export default withRouter(RelationshipAdd)