import { get, post, put } from "./api";
import * as url from "./url";

//get user logued
const getRoleList = query => get(`${url.ROLE}${query}`)

//roles actives
const getRoleActive = query => get(`${url.ROLE}/active`)

//get case
const getRole = id => get(`${url.ROLE}/${id}`)

//save stage
const postRole = data => post(url.ROLE, data)

//update stage
const putRole = (id, data) => put(`${url.ROLE}/${id}`, data)



export {
    getRoleList,
    getRole,
    postRole,
    putRole,
    getRoleActive
}