import { Col, Row } from "reactstrap";

export default function QuestionInstructionsSection({questions}){

    return (
        questions.map((question) => (
            <Row className="mb-2" key={question.id}>
                <Col xs="12" md="12">
                    <h6 className="m-1">{question.name}</h6>
                    {
                        question.QuestionInstructionSubsections.length > 0 &&
                        <QuestionInstructionsSubSection subsections={question.QuestionInstructionSubsections} />
                    }
                    
                </Col>
            </Row>
        ))
    )
}

function QuestionInstructionsSubSection({subsections}){

    return (
        subsections.map((subsection, subIndex) => (
            <Row key={subsection.id} className="fs-7 ms-1">
                <Col xs="12" md="12">
                    {
                        subsection.adorment === 'number' ?
                        <label>{subIndex+1}. {subsection.description}</label>:
                        <label ><span className="fs-9">•</span> {subsection.description}</label>
                    }
                    
                    {subsection.QuestionInstructionSubsectionLists.length > 0 &&
                    <div className="ms-3">
                        <ul>
                            {
                                subsection.QuestionInstructionSubsectionLists.map((subList, subIndex) => (
                                    <li key={subList.id}>{subList.description}</li>
                                ))
                            }
                        </ul>
                    </div>}
                </Col>
            </Row>
        ))
    )
}