import { Redirect } from "react-router-dom"
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Dashboard from "../pages/Dashboard"
import DocumentAdd from "../pages/Document/DocumentAdd"
import DocumentEdit from "../pages/Document/DocumentEdit"
import DocumentList from "../pages/Document/DocumentList"
import HeaderReportAdd from "../pages/HeaderReport/HeaderReportAdd"
import HeaderReportEdit from "../pages/HeaderReport/HeaderReportEdit"
import HeaderReportList from "../pages/HeaderReport/HeaderReportList"
import QuestionInstructionsAdd from "../pages/QuestionInstructions/QuestionInstructionsAdd"
import QuestionInstructionsEdit from "../pages/QuestionInstructions/QuestionInstructionsEdit"
import QuestionInstructionsList from "../pages/QuestionInstructions/QuestionInstructionsList"
import ReferralAdd from "../pages/Referral/ReferralAdd"
import ReferralEdit from "../pages/Referral/ReferralEdit"
import RefertalList from "../pages/Referral/RefertalList"
import RelationshipAdd from "../pages/Relationship/RelationshipAdd"
import RelationshipEdit from "../pages/Relationship/RelationshipEdit"
import RelationshipList from "../pages/Relationship/RelationshipList"
import ServiceAreasList from "../pages/ServiceAreas/ServiceAreasList"
import StagesAdd from "../pages/Stages/StagesAdd"
import StagesEdit from "../pages/Stages/StagesEdit"
import StagesList from "../pages/Stages/StagesList"
import SurveyAdd from "../pages/Survey/SurveyAdd"
import SurveyEdit from "../pages/Survey/SurveyEdit"
import SurveyList from "../pages/Survey/SurveyList"
import UserAdd from "../pages/User/UserAdd"
import UserEdit from "../pages/User/UserEdit"
import UserList from "../pages/User/UserList"
import CaseReminder from "../pages/Document/CaseReminder"

const authProtectedRoutes = [
    { path: "/logout", component: Logout },
    { path: "/dashboard", component: Dashboard },
    { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]


const publicRoutes = [
    { path: "/login", component: Login },
]


const adminRoutes = [
    //user
    { path: "/user-list", exact: true, component: UserList },
    { path: "/user-add", exact: true, component: UserAdd },
    { path: "/user-edit/:id", exact: true, component: UserEdit },
]
const managerRoutes = [
     //satges
     { path: "/stage-list", exact: true, component: StagesList },
     { path: "/stage-add", exact: true, component: StagesAdd },
     { path: "/stage-edit/:id", exact: true, component: StagesEdit },
 
     //satges
     { path: "/relationship-list", exact: true, component: RelationshipList },
     { path: "/relationship-add", exact: true, component: RelationshipAdd },
     { path: "/relationship-edit/:id", exact: true, component: RelationshipEdit },
 
     //header report
     { path: "/topconfiguration-list", exact: true, component: HeaderReportList },
     { path: "/topconfiguration-add", exact: true, component: HeaderReportAdd },
     { path: "/topconfiguration-edit/:id", exact: true, component: HeaderReportEdit },

     //header report
     { path: "/survey-list", exact: true, component: SurveyList },
     { path: "/survey-add", exact: true, component: SurveyAdd },
     { path: "/survey-edit/:id", exact: true, component: SurveyEdit },

      //referral report
      { path: "/referral-list", exact: true, component: RefertalList },
      { path: "/referral-add", exact: true, component: ReferralAdd },
      { path: "/referral-edit/:id", exact: true, component: ReferralEdit },

      //question instructions report
      { path: "/questioninstructions-list", exact: true, component: QuestionInstructionsList },
      { path: "/questioninstructions-add", exact: true, component: QuestionInstructionsAdd },
      { path: "/questioninstructions-edit/:id", exact: true, component: QuestionInstructionsEdit },

      //services area report
      { path: "/serviceareas-list", exact: true, component: ServiceAreasList },
    //   { path: "/questioninstructions-add", exact: true, component: QuestionInstructionsAdd },
    //   { path: "/questioninstructions-edit/:id", exact: true, component: QuestionInstructionsEdit },
]
const agentRoutes = [
    //documents
    { path: "/case-list", exact: true, component: DocumentList },
    { path: "/case-add", exact: true, component: DocumentAdd },
    { path: "/case-edit/:id", exact: true, component: DocumentEdit },
    { path: "/case-reminders/:id", exact: true, component: CaseReminder },
]

export { 
    authProtectedRoutes, 
    publicRoutes,
    adminRoutes,
    managerRoutes,
    agentRoutes
}