import { useEffect, useState } from "react";

export default function useGetSafetlyStatus(data) {
    const [info, setInfo] = useState(null);

    useEffect(() => {
            const question = []
            data.questions.forEach(qt => {
                let row = {
                    style: {
                        height: 13,
                    },
                    columns: [
                        {
                            style: {
                                width: '100%',
                                textAlign: 'left',
                                paddingLeft: 1, 
                            },
                            title: qt.title,
                            titleStyle: {
                                fontFamily: 'Times New Roman Bold',
                            },
                            text: '',
                            typeInput: 'label'
                        },
                    ]
                }
                question.push(row)
                if(qt.labels.length){
                    const columnLabels = qt.labels.map(lb => ({
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: lb.value,
                        typeInput: 'checkbox',
                        checked: lb?.checked ?? false
                    }))
                    const newRowLabel = {
                        style: {
                            height: 13,
                        },
                        columns: columnLabels
                    }
                    question.push(newRowLabel)
                }
            })

            const obj = {
                header: 'SAFETY STATUS',
                rows: question
            }
            setInfo(obj)
    }, [])

    return info;
}