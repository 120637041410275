import { useEffect, useState } from "react";
import { useLocation, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumbs";
import DocumentForm from "../../components/Document/DocumentForm";
import DocumentFormNext from "../../components/Document/DocumentFormNext";
import SimpleLoad from "../../components/Loader/SimpleLoad";
import { ERROR_SERVER } from "../../constants/messages";
import { fillCleanCase } from "../../helpers/case";

function DocumentAdd(){
    const location = useLocation();
    const [loading, setLoading] = useState(true)
    const [document, setDocument] = useState(null)

    useEffect(() => {
        let isSubscribe = true;
        setLoading(true)
        async function getCaseAPI(){
            if(isSubscribe){
                console.log(location.state)                
                try {
                    const response = await fillCleanCase();
                    console.log(response)
                    if(response.success){
                        let dataFromPdf = {
                            name: '',
                            lastName: '',
                            aka: '',
                            aNumber: '',
                            birthday: null,
                            placeBirth: '',
                            gender: 'Male',
                            sponsorInfo: {
                                name: '',
                                lastName: '',
                                age: '',
                                relationship: '',
                                nationality: '',
                                contactNumbers: [],
                                homeAddress: '',
                                gender: 'Male',
                            },
                            caseInfo: {
                                dateAcceptance:  null,
                                dateRelease: null,
                                dateComplete: null,
                                dateClosure: null,
                                postReleaseAgency: '',
                                caseManager: '',
                                contactInformation: '',
                                status: 'active',
                            },
                            houseHoldMembers: [],
                            reasonReferral: {
                                prsOnly: {
                                    criminalHistory: false,
                                    gangInvolvement: false,
                                    behavioralProblems: false,
                                    abuseHistory:  false,
                                    traumaHistory: false,
                                    mentalHealth: false,
                                    suicidalBehaviors: false,
                                    substanceAbuse: false,
                                    cognitiveDevelopmentalDelays: false,
                                    lackFormalEducation:  false,
                                    medicalCondition: false,
                                    disability: false,
                                    pregnantParentingTeen: false,
                                    lackPriorRelationshipSponsor:  false,
                                    sponsorLackKnowledge: false,
                                    traffickingConcern:  false,
                                    sponsorConcerns: false,
                                    prsDiscretionary: false,
                                    categorySponsorship: false,
                                    other: false,
                                },
                                prsAfter: {
                                    tvpraTraffickingConcerns: false,
                                    tvpraSponsorConcerns: false,
                                    tvpraDisability:  false,
                                    tvpraAbuseHistory: false,
                                }
                            }
                        }
                        if(location.state!==undefined && location.state.document){
                            dataFromPdf = location.state.document
                        }
                        const caseToEdit = {
                            ...dataFromPdf,
                            Header: response.content.Header,
                            SafetyStatus:response.content.SafetyStatus,
                            ReferralResource: response.content.ReferralResource,
                            CaseCloseProgram: response.content.CaseCloseProgram,
                            DestinationIndicator: response.content.DestinationIndicator,
                            ServiceInstructions: response.content.ServiceInstructions,
                            ServiceAreas: response.content.ServiceAreas
                        }
                        setDocument(caseToEdit)
                    }else{
                        toast.error(ERROR_SERVER)
                    }
                    setLoading(false)
                } catch (error) {
                    toast.error(ERROR_SERVER)
                }
            }            
        }
        getCaseAPI();
        return () => isSubscribe = false;
    }, [])

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Case" breadcrumbItem="New case" />
                <Row>
                    <Col xs="12" lg="12">
                        { loading ? <SimpleLoad /> : <DocumentFormNext document={document} />}
                    
                        {/* <DocumentForm document={location.state?.document ? location.state?.document : null}/> */}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default withRouter(DocumentAdd)