import { useFormik } from "formik";
import { useState } from "react";
import { Card, CardBody, Col, Form,Row, Button, Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";
import * as Yup from "yup";
import { postCase, putCase } from "../../helpers/case";
import { toast } from 'react-toastify'
import { ERROR_SERVER, requiredField, SAVE_SUCCESS, UPDATE_SUCCESS } from "../../constants/messages";
import FullScreenLoader from "../Loader/FullScreenLoader";
import ReportPreview from "./Report/ReportPreview";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "./Header";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";

export default function DocumentFormNext({id, document}){
    //console.log(document)
    const history = useHistory();
    const [isSubmiting, setIsSubmiting] = useState(false)
    const [open, setOpen] = useState(false);
    
    const form = useFormik({
        enableReinitialize: true,
        initialValues: document,
        validationSchema: Yup.object({
            name: Yup.string().required(requiredField),
            caseInfo: Yup.object().shape({
                dateAcceptance: Yup.string().nullable().required(requiredField)
            }),
            User: Yup.object().shape({
                id: Yup.string().required(requiredField)
            }),
            houseHoldMembers: Yup.array().of(
                Yup.object().shape({
                    name: Yup.string().required(requiredField),
                    nacionality: Yup.string().required(requiredField),
                    age: Yup.string().required(requiredField),
                    gender: Yup.string().required(requiredField),
                    relationshipSponsor: Yup.string().required(requiredField),
                    relationship_id: Yup.number().required(requiredField)
                })
            ),          
        }),
        onSubmit: async (values) => {
            console.log(values)
            setIsSubmiting(true)
            try {
                if(values.id){
                    const response = await putCase(id, values);
                    if(response.success){
                        toast.success(UPDATE_SUCCESS)
                    }else{
                        toast.error(ERROR_SERVER)
                    }                   
                    setIsSubmiting(false) 
                }else{
                    const response = await postCase(values)
                    if(response.success){
                        toast.success(SAVE_SUCCESS)
                        setTimeout(() => {
                            history.push(`/case-edit/${response.content.id}`);
                        }, 1000)
                    }else{
                        toast.error(ERROR_SERVER)
                    }             
                    setIsSubmiting(false)      
                }                
            } catch (error) {
                toast.error(ERROR_SERVER)
                setIsSubmiting(false) 
            }
        }
    })
    const togglePreviewPDF = () => {
        setOpen(!open);
    };
    

    useEffect(() => {
        if(Object.keys(form.errors).length > 0 && form.isSubmitting){
            toast.error('Case invalid!. Some data is missing')
        }
    }, [form.errors, form.isSubmitting])

    return (
        <Form
            className="needs-validation"
            id="tooltipForm"
            onSubmit={(e) => {
            e.preventDefault();
            form.handleSubmit();
            return false;
        }}
        >
            {isSubmiting && <FullScreenLoader />}
            <Row>
                <Col xs="12" md={{size: 10, offset: 1}}>
                <Row className="container-case">
                    <Col xs="12" md="12">
                        <Row>
                            <Col xs="12" md="12">
                                <Card>                                
                                    <CardBody>
                                        <Header form={form}/>
                                    </CardBody>
                                </Card>                                
                            </Col>
                            <Col xs="12" md="12">
                                <Card>                                
                                    <CardBody>
                                        <Section2 form={form}/>
                                    </CardBody>
                                </Card>                                
                            </Col>
                            <Col xs="12" md="12">
                                <Card>                                
                                    <CardBody>
                                        <Section3 form={form}/>
                                    </CardBody>
                                </Card>                                
                            </Col>
                            <Col xs="12" md="12">
                                <Card>                                
                                    <CardBody>
                                        <Section4 form={form}/>
                                    </CardBody>
                                </Card>                                
                            </Col>
                            <Col xs="12" md="12">
                                <Card>                                
                                    <CardBody>
                                        <Section5 form={form}/>
                                    </CardBody>
                                </Card>                                
                            </Col>
                            <Col xs="12" md="12">
                                <Card>                                
                                    <CardBody>
                                        <Section6 form={form}/>
                                    </CardBody>
                                </Card>                                
                            </Col>
                        </Row>
                        
                    </Col>
                </Row>
                    <Row className="mt-3">
                        <Col xs="12" md="12">
                            <Card>
                                <CardBody className="p-1">
                                    <div className="d-flex">
                                        <Button
                                            color="success"
                                            className="btn btn-success m-2"
                                            type="submit"
                                            >Save
                                        </Button>
                                        {/* <Button
                                            color="light"
                                            className="btn btn-light m-2"
                                            onClick={togglePreviewPDF}
                                            >Preview
                                        </Button> */}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>                    
                    </Row>
                </Col>
            </Row>
            
            <Offcanvas 
                isOpen={open} 
                toggle={togglePreviewPDF} 
                direction="end"
                scrollable
                className="w-100"
            >
                {/* <OffcanvasHeader toggle={togglePreviewPDF}>
                    Preview
                </OffcanvasHeader> */}
                <OffcanvasBody>
                    <ReportPreview document={form}/>                   
                </OffcanvasBody>
            </Offcanvas>
        </Form>
    )
}