import { get, post, put } from "./api";
import * as url from "./url";


//get user logued
const getQuestionInstructionsList = query => get(`${url.QUESTION_INSTRUCTIONS}${query}`)

//get question & instructions
const getQuestionInstructions = id => get(`${url.QUESTION_INSTRUCTIONS}/${id}`)

//get question % instrcution by section
const getQuestionInstructionsBySection = id => get(`${url.QUESTION_INSTRUCTIONS}/section/${id}`)

const getQuestionInstructionsBySectionName = id => get(`${url.QUESTION_INSTRUCTIONS}/section-name/${id}`)

const postQuestionInstructions = data => post(`${url.QUESTION_INSTRUCTIONS}`, data)

const putQuestionInstructions = (id, data) => put(`${url.QUESTION_INSTRUCTIONS}/${id}`, data)


export {
    getQuestionInstructionsList,
    getQuestionInstructions,
    postQuestionInstructions,
    putQuestionInstructions,
    getQuestionInstructionsBySection,
    getQuestionInstructionsBySectionName
}