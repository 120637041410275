import { useState } from "react";

export default function useGetSponsorInfo(document) {
    const [info, setInfo] = useState({
        header: 'SPONSOR IDENTIFYING INFORMATION',
        rows: [
            {
                style: {
                    height: 24,
                    borderBottomColor: '#000',
                    borderBottomWidth: 1,
                },
                columns: [
                    {
                        style: {
                            width: '50%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                        },
                        title: 'Name:',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: `${document?.sponsorInfo?.name} ${document?.sponsorInfo?.lastName}`,
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '30%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                        },
                        title: 'Relationship to UC:',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: document?.sponsorInfo?.relationship,
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: 'Nationality:',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: document?.sponsorInfo?.nationality,
                        typeInput: 'label'
                    }
                ]
            },
            {
                style: {
                    height: 44,
                    borderBottomColor: '#000',
                    borderBottomWidth: 1,
                },
                columns: [
                    {
                        style: {
                            width: '25%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                        },
                        title: 'Age:',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: document?.sponsorInfo?.age,
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '25%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                        },
                        title: 'Sex:',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: document?.sponsorInfo?.gender,
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '25%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                        },
                        title: 'Home Address:',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: document?.sponsorInfo?.homeAddress,
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '25%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: 'Contact Number(s):',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: document?.sponsorInfo?.contactNumbers.map(item => item.number).join(', '),
                        typeInput: 'label'
                    }
                ]
            }
        ]

    })

    return info;
}