export const questionTypeOpt = [
    {
        value: 'simple_choice',
        label: 'Simple Choice'
    },
    {
        value: 'multiple_choice',
        label: 'Multiple Choice'
    },
    {
        value: 'text_box',
        label: 'Text Box'
    },
    {
        value: 'char_box',
        label: 'Char Box'
    },
    {
        value: 'numerical_box',
        label: 'Numerical Box'
    },
    {
        value: 'date',
        label: 'Date'
    },
    {
        value: 'datetime',
        label: 'Datetime'
    }
]