import { Button, Card, CardBody, CardTitle } from "reactstrap"
import SimpleTable from "../Tables/SimpleTable"
import { useState } from "react"
import { useEffect } from "react";
import { getRemindersAll, markReaded } from "../../helpers/reminders";
import moment from "moment";
import ModalInformativo from "../Common/ModalInformativo";
import { toast } from "react-toastify";
import { ERROR_SERVER, UPDATE_SUCCESS } from "../../constants/messages";
import Paginate from "../Tables/Paginate";

function CasesReminders(){
    const [items, setItems] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [id, setId] = useState(null)
    const [page, setPage] = useState(0)
    const [totalPaginas, setTotalPaginas] = useState(0) 

    const [query, setQuery] = useState({
        page: 0,
        size: 10
    })

    const rowStyle = (row) => {
        const style = {};
        const dateDiff = moment(row.date).diff(moment(), 'days')
        if(dateDiff <= 1){
            style.backgroundColor = '#f900006b';
        }else if(dateDiff > 1 && dateDiff <= 5){
            style.backgroundColor = '#f1b44c78';
        }else{
            style.backgroundColor = 'transparent';
        }
        
        return style;
    }

    const columns = [
        {
          text: "id",
          dataField: "id",
          sort: true,
          hidden: true,
        },
        {
          text: "Case Number",
          dataField: "Case.aNumber",  
          style: {
            width: '15%'
          }                 
        },
        {
            text: "Name",
            dataField: 'Case.name',  
            style: {
                width: '20%'
            },
            formatter: (cell, row) => <span>{`${row.Case.name} ${row.Case.lastName}`}</span>,        
        },
        {
            text: "Date Reminder",
            dataField: "date",
            style: {
                width: '10%'
            }, 
            formatter: (cell) => cell && moment(cell, "YYYY-MM-DD").format("MM/DD/YYYY")                  
        },
        {
            text: "Note",
            dataField: "note",
            style: {
                width: '33%'
            },
        },
        {
            text: "Assign to",
            dataField: "User.name",
            style: {
                width: '12%'
            },
        },
        {
            dataField: "menu",
            isDummyField: true,
            editable: false,
            text: "Action",
            style: {
                width: '10%'
            },
            formatter: (cellContent, row) => (
                    <Button
                        type="button" color="light"
                        size="sm"
                        onClick={() => {
                            setId(row.id)
                            setOpenModal(true)
                        }}
                    >
                        Mark as readed
                    </Button>                
            ),
        },
    ];

    const searchRemindersAllApi = async () => {
        let q = Object.keys(query).map(key=>`${key}=${query[key]}`).join("&")
        const response = await getRemindersAll(`?${q}`);
        if(response.success){
            setItems(response.content)
            setTotalPaginas(response.total_pages)
            setItems(response.content)
        }
    }

    useEffect(() => {
        searchRemindersAllApi()
    }, [query])

    const onClickReaded = async () => {
        try {
            const response = await markReaded(id)
            if(response.success){
                toast.success(UPDATE_SUCCESS)
                setId(null)
                setOpenModal(false)
                searchRemindersAllApi()
            }else{
                toast.error(ERROR_SERVER)
            }
        } catch (error) {
            toast.error(ERROR_SERVER)
        }
    }
    const handlePageClick = page => {
        setPage(page)
        setQuery(prev=>({
            ...prev,
            page: page
        }))
    }
    return (
        <>
            <Card className="mini-stats-wid">
                <CardBody>
                    <CardTitle className="h4 mb-4">Reminders</CardTitle>
                    <SimpleTable 
                        columns={columns}
                        items={items} 
                        rowStyle={rowStyle}
                    />
                    {
                        items.length > 0 &&
                        <Paginate
                            page={page}
                            totalPaginas={totalPaginas}
                            totalRegistros={0}
                            handlePageClick={handlePageClick}
                            limit={query.limite}
                            handleChangeLimit={() => {}}
                        />
                    }
                </CardBody>
            </Card>
            <ModalInformativo
                open={openModal}
                setOpen={setOpenModal}
                title={"Mark as readed"}
                text={"Once you marked as read, the reminder will removed from the list."}
                onClickOk={onClickReaded}
                existCase={false}
                onClickViewCase={() => {}}
            />
        </>
        
        
    )
}

export default CasesReminders