import moment from "moment"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Col, Row, Button, Table, Input, FormFeedback, Label, InputGroup, Badge } from "reactstrap"
import logoReporte from '../../assets/images/logo-reporte.png'
import { ERROR_SERVER, DELETE_SUCCESS } from "../../constants/messages"
import Select from "react-select";
import { countries } from "../../constants/countries"
import { getAge } from "../../utils/getAge"
import { FieldArray, FormikProvider } from "formik"
import { deleteContactNumbers } from "../../helpers/contactnumbers"
import { statuses } from "../../constants/statuses"
import DateInputMask from "../Controllers/DateInputMask"
import { getUserListAll } from "../../helpers/user"
import useLoguedUser from "../../hooks/useLoguedUser"

export default function Header({form}){
    const { initialValues } = form;
    const userLogued = useLoguedUser();
    const [result, setResult] = useState(initialValues.Header)
    const [dateBirthday, setDateBirthday] = useState(
        initialValues?.birthday ? 
        moment(initialValues?.birthday, "YYYY-MM-DD").format("MM/DD/YYYY") : 
        null
    )
    const [country, setCountry] = useState(
        initialValues?.placeBirth ? 
        {label: initialValues?.placeBirth, value: initialValues?.placeBirth} : 
        null
    );
    const [genderM, setGenderM] = useState(initialValues.gender);
    const [genderSponsorM, setGenderSponsorM] = useState(initialValues?.sponsorInfo?.gender);
    const [showHomeAddressControl, setShowHomeAddressControl] = useState(false)
    const [number, setNumber] = useState('');
    const [dateAcceptance, setDateAcceptance] = useState(
        initialValues?.caseInfo?.dateAcceptance ? 
        moment(initialValues?.caseInfo?.dateAcceptance, "YYYY-MM-DD").format("MM/DD/YYYY") : 
        null
    )
    const [dateRelease, setDateRelease] = useState(
        initialValues?.caseInfo?.dateRelease ? 
        moment(initialValues?.caseInfo?.dateRelease, "YYYY-MM-DD").format("MM/DD/YYYY") : 
        null
    )
    const [dateComplete, setDateComplete] = useState(
        initialValues?.caseInfo?.dateComplete ? 
        moment(initialValues?.caseInfo?.dateComplete, "YYYY-MM-DD").format("MM/DD/YYYY") : 
        null
    )
    const [dateClousure, setDateClousure] = useState(
        initialValues?.caseInfo?.dateClosure ? 
        moment(initialValues?.caseInfo?.dateClosure, "YYYY-MM-DD").format("MM/DD/YYYY") : 
        null
    )
    const [status, setStatus] = useState({label: initialValues.caseInfo.status, value: initialValues.caseInfo.status});
    const [reSet, setReSet] = useState(false)

    const onHandleChecked = (id, checked, type) => {
        const copyDataP = { ...result.data }
        const copyData = {...copyDataP};
        const idx = copyData[type].findIndex(it=>it.id === id)
        const copyObj = {...copyData[type][idx]}
        copyObj.checked = checked
        copyData[type][idx] = copyObj;
        copyDataP.data = copyData 
        setResult(prev=>({
            ...prev,
            data: copyData
        }))  
        setReSet(true) 
    }

    useEffect(() => {
        if(reSet){
            form.setFieldValue('Header', result)
            setReSet(false)
        }
    }, [reSet])

    //user
    const [user, setUser] = useState(initialValues.User ?
         {label: initialValues.User.name, value: initialValues.User.id} :
         {label: userLogued?.name, value: userLogued?.id})
    const [userOpt, setUserOpt] = useState([])

    const fecthUserApi = async () => {
        try {
            const response = await getUserListAll();
            setUserOpt(response.content.map((u) => ({label: u.name, value: u.id})))        
        } catch (error) {
            //toast.error(ERROR_SERVER)
        }
    }

    useEffect(() => {
        fecthUserApi();
    }, [])

    return (
        <>
            <Row>
                <Col xs="6" md="8">
                    <img src={logoReporte} alt="logotipo" className="responsive w-25 mb-4"/>
                </Col>
                <Col xs="6" md="4">
                    <div className="d-flex align-items-center mb-2">
                        <div className="pe-2">Asignado </div>
                        <div className="flex-grow-1">
                            <Select
                                value={user}
                                onChange={(value) => {
                                    setUser(value)
                                    form.setFieldValue('User.id', value.value)
                                }}
                                options={userOpt}
                                classNamePrefix="select2-selection"
                                closeMenuOnSelect={true}
                            />
                            {
                                form.errors.User?.id && 
                                <FormFeedback type="invalid">{form.errors.User.id}</FormFeedback>
                            }
                        </div>                        
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="pe-2">Status</div>
                        <div className="flex-grow-1">
                            <Select
                                value={status}
                                onChange={(value) => {
                                    setStatus(value)
                                    form.setFieldValue('caseInfo.status', value.label)
                                }}
                                options={statuses}
                                classNamePrefix="select2-selection"
                                closeMenuOnSelect={true}
                            />
                        </div>                        
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h4 className="text-center pb-3">ORR/UAC PROGRAM POST-RELEASE SERVICES REPORT</h4>
                </Col>
            </Row>
                <div className="fs-7 fw-light d-flex justify-content-center">
                    <div className="pe-5">
                        <div className="mb-3">
                            <h6>PRS Case Type:</h6>
                            {
                                result?.data?.prs_case_type.map((it) => (
                                    <div key={it.id}>
                                        {
                                            !it.report_top_configuration_id && 
                                            <div className="form-check mb-1">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id={`prsCaseType_${it.id}`}
                                                    onChange={(e)=>onHandleChecked(it.id, e.target.checked, 'prs_case_type')} 
                                                    defaultChecked={it?.checked ?? false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor={`prsCaseType_${it.id}`}
                                                >{it.title}</label>
                                            </div>
                                        }
                                        {
                                            result?.data?.prs_case_type.filter(itChild => itChild.report_top_configuration_id === it.id).map(itChildMap => (
                                                <div className="form-check mb-1 ms-3" key={itChildMap.id}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id={`prsCaseType_${itChildMap.id}`}
                                                        onChange={(e)=>onHandleChecked(itChildMap.id, e.target.checked, 'prs_case_type')}
                                                        defaultChecked={it?.checked ?? false}                                                        
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor={`prsCaseType_${itChildMap.id}`}
                                                    >{itChildMap.title}</label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    
                                ))
                            }
                        </div>
                        <div className="mb-3">
                            <h6>PRS Visit Type:</h6>
                            {
                                result?.data?.prs_visit_type.map((it) => (
                                    <div key={it.id}>
                                        {
                                            !it.report_top_configuration_id &&
                                            <div className="form-check mb-1">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id={`prsVisitType_${it.id}`}
                                                    name="reasonReferral.prsOnly.criminalHistory"
                                                    onChange={(e)=>onHandleChecked(it.id, e.target.checked, 'prs_visit_type')}
                                                    defaultChecked={it?.checked ?? false}   
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor={`prsVisitType_${it.id}`}
                                                >{it.title}</label>
                                            </div>
                                        }
                                        {
                                            result?.data?.prs_visit_type.filter(itChild => itChild.report_top_configuration_id === it.id).map(itChildMap => (
                                                <div className="form-check mb-1 ms-3" key={itChildMap.id}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id={`prsVisitType_${itChildMap.id}`}
                                                        name="reasonReferral.prsOnly.criminalHistory"
                                                        onChange={(e)=>onHandleChecked(itChildMap.id, e.target.checked, 'prs_visit_type')}
                                                        defaultChecked={it?.checked ?? false}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor={`prsVisitType_${itChildMap.id}`}
                                                    >{itChildMap.title}</label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="ps-5">
                        <div className="mb-3">
                            <h6>PRS Level:</h6>
                            {
                                result?.data?.prs_level.map((it) => (
                                    <div key={it.id}>
                                        {
                                            !it.report_top_configuration_id &&
                                            <div className="form-check mb-1" >
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id={`prsLevel_${it.id}`}
                                                    onChange={(e)=>onHandleChecked(it.id, e.target.checked, 'prs_level')}
                                                    defaultChecked={it?.checked ?? false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor={`prsLevel_${it.id}`}
                                                >{it.title}</label>
                                            </div>
                                        }
                                        {
                                            result?.data?.prs_level.filter(itChild => itChild.report_top_configuration_id === it.id).map(itChildMap => (
                                                <div className="form-check mb-1 ms-3" key={itChildMap.id}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id={`prsLevel_${itChildMap.id}`}
                                                        name="reasonReferral.prsOnly.criminalHistory"
                                                        onChange={(e)=>onHandleChecked(it.id, e.target.checked, 'prs_level')}
                                                        defaultChecked={it?.checked ?? false}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor={`prsLevel_${itChildMap.id}`}
                                                    >{itChildMap.title}</label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    
                                ))
                            }
                        </div>
                        <div className="mb-3">
                            <h6>Case Closing Summary:</h6>
                            {
                                result?.data?.case_closing_summary.map((it) => (
                                    <div key={it.id}>
                                        {
                                            !it.report_top_configuration_id &&
                                            <div className="form-check mb-1">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id={`case_closing_summary_${it.id}`}
                                                    onChange={(e)=>onHandleChecked(it.id, e.target.checked, 'case_closing_summary')}
                                                    defaultChecked={it?.checked ?? false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor={`case_closing_summary_${it.id}`}
                                                >{it.title}</label>
                                            </div>
                                        }
                                        {
                                            result?.data?.case_closing_summary.filter(itChild => itChild.report_top_configuration_id === it.id).map(itChildMap => (
                                                <div className="form-check mb-1 ms-3" key={itChildMap.id}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id={`case_closing_summary_${itChildMap.id}`}
                                                        onChange={(e)=>onHandleChecked(it.id, e.target.checked, 'case_closing_summary')}
                                                        defaultChecked={it?.checked ?? false}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor={`case_closing_summary_${itChildMap.id}`}
                                                    >{itChildMap.title}</label>
                                                </div>
                                            ))
                                        }
                                    </div>                                    
                                ))
                            }
                        </div>
                    </div>
                </div>
            <Row>
                <Col xs="12" md="12">
                    <Table bordered size="sm">
                        <thead>
                            <tr className="bg-dark bg-soft">
                                <th colSpan={4}>CHILD IDENTIFYING INFORMATION</th>
                            </tr>
                        </thead>
                        <tbody className="fs-7">
                            <tr>
                                <td colSpan={2}>
                                    <div className="d-flex align-items-center">
                                        <div className="pe-1">Name: </div>
                                        <div className="flex-grow-1">
                                            <Input
                                                type="text"
                                                id="name"
                                                name="name"
                                                className={`fs-7 input-1 p-0 form-control ${form.errors.name ? 'is-invalid' : ''}`}
                                                onChange={form.handleChange}
                                                onBlur={form.handleBlur}
                                                value={form.values.name || ""}  
                                            />
                                            {
                                                form.errors.name && 
                                                <FormFeedback type="invalid">{form.errors.name}</FormFeedback>
                                            }
                                        </div>
                                        <div className="ps-2 pe-1">Last Name: </div>
                                        <div className="flex-grow-1">
                                            <Input
                                                type="text"
                                                id="lastName"
                                                name="lastName"
                                                className={`fs-7 input-1 p-0 form-control ${form.errors.lastName ? 'is-invalid' : ''}`}
                                                onChange={form.handleChange}
                                                onBlur={form.handleBlur}
                                                value={form.values.lastName || ""}  
                                            />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <div className="pe-2">AKA: </div>
                                        <div className="flex-grow-1">
                                            <Input
                                                type="text"
                                                id="aka"
                                                name="aka"
                                                className={`fs-7 input-1 p-0 form-control ${form.errors.aka ? 'is-invalid' : ''}`}
                                                onChange={form.handleChange}
                                                onBlur={form.handleBlur}
                                                value={form.values.aka || ""}  
                                            />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <div className="pe-2">A#: </div>
                                        <div className="flex-grow-1">
                                            <Input
                                                type="text"
                                                id="aNumber"
                                                name="aNumber"
                                                className={`fs-7 input-1 p-0 form-control ${form.errors.aNumber ? 'is-invalid' : ''}`}
                                                onChange={form.handleChange}
                                                onBlur={form.handleBlur}
                                                value={form.values.aNumber || ""}  
                                            />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <div className="pe-2">Birthday: </div>
                                        <div className="flex-grow-1">
                                            <DateInputMask
                                                date={dateBirthday}
                                                onChange={event=>{
                                                    console.log(event.target.value)
                                                    setDateBirthday(event.target.value)
                                                    form.setFieldValue('birthday', event.target.value)
                                                }}
                                            />
                                            {/* <SimpleDate 
                                                date={dateBirthday}
                                                className={'fs-7 input-1 p-0'}
                                                setDate={value=>{
                                                    setDateBirthday(value)
                                                    if(value.length > 0){
                                                        form.setFieldValue('birthday', moment(value[0]).format("YYYY-MM-DD"))
                                                    }else{
                                                        form.setFieldValue('birthday', null)
                                                    }                                
                                                }}
                                            /> */}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <div className="pe-2">Age: </div>
                                        <div className="flex-grow-1">
                                            {getAge(dateBirthday)}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <div className="pe-2">Place of birth: </div>
                                        <div className="flex-grow-1">
                                            <Select
                                                value={country}
                                                onChange={(value) => {
                                                    setCountry(value)
                                                    form.setFieldValue('placeBirth', value.label)
                                                }}
                                                options={countries}
                                                classNamePrefix="select2-selection"
                                                closeMenuOnSelect={true}
                                                className="select2-1"
                                            />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <div className="pe-2">Sex: </div>
                                        <div className="flex-grow-1">
                                            <div className="form-check form-check-inline">
                                                <Input
                                                    type="radio"
                                                    id="genderRadio1"
                                                    name="genderRadio"
                                                    className="form-check-input"
                                                    checked={genderM==="Male"}
                                                    onChange={e=>{
                                                        setGenderM(e.target.checked ? 'Male' : 'Female')
                                                        form.setFieldValue('gender', e.target.checked ? 'Male' : 'Female')
                                                    }}
                                                />
                                                <Label
                                                    className="form-check-label fw-light"
                                                    htmlFor="genderRadio1"
                                                >
                                                    Male
                                                </Label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <Input
                                                    type="radio"
                                                    id="genderRadio2"
                                                    name="genderRadio"
                                                    className="form-check-input"
                                                    checked={genderM==='Female'}
                                                    onChange={e=>{
                                                        setGenderM(e.target.checked ? 'Female' : 'Male')
                                                        form.setFieldValue('gender', e.target.checked ? 'Female' : 'Male')
                                                    }}
                                                />
                                                <Label
                                                    className="form-check-label fw-light"
                                                    htmlFor="genderRadio2"
                                                >
                                                    Female
                                                </Label>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <Row>
                <Col xs="12" md="12">
                    <Table bordered size="sm" style={{width: '100%'}}>
                        <thead>
                            <tr className="bg-dark bg-soft">
                                <th colSpan={4}>SPONSOR IDENTIFYING INFORMATION</th>
                            </tr>
                        </thead>
                        <tbody className="fs-7">
                            <tr>
                                <td colSpan={2} style={{width: '50%'}}>
                                    <div className="d-flex align-items-center">
                                        <div className="pe-1">Name: </div>
                                        <div className="flex-grow-1">
                                            <Input
                                                type="text"
                                                id="sponsorInfo.name"
                                                name="sponsorInfo.name"
                                                className={`fs-7 input-1 p-0 form-control ${form.errors.sponsorInfo?.name ? 'is-invalid' : ''}`}
                                                onChange={form.handleChange}
                                                onBlur={form.handleBlur}
                                                value={form.values.sponsorInfo?.name || ""}  
                                            />
                                        </div>
                                        <div className="ps-2">Last Name</div>
                                        <div className="flex-grow-1">
                                            <Input
                                                type="text"
                                                id="sponsorInfo.lastName"
                                                name="sponsorInfo.lastName"
                                                className={`fs-7 input-1 p-0 form-control ${form.errors.sponsorInfo?.lastName ? 'is-invalid' : ''}`}
                                                onChange={form.handleChange}
                                                onBlur={form.handleBlur}
                                                value={form.values.sponsorInfo?.lastName || ""}  
                                            />
                                        </div>
                                    </div>
                                </td>
                                <td style={{width: '25%'}}>
                                    <div className="d-flex align-items-center">
                                        <div className="pe-2">Relationship to UC: </div>
                                        <div className="flex-grow-1">
                                            <Input
                                                type="text"
                                                id="sponsorInfo.relationship"
                                                name="sponsorInfo.relationship"
                                                className={`fs-7 input-1 p-0 form-control ${form.errors.sponsorInfo?.relationship ? 'is-invalid' : ''}`}
                                                onChange={form.handleChange}
                                                onBlur={form.handleBlur}
                                                value={form.values.sponsorInfo?.relationship || ""}  
                                            />
                                        </div>
                                    </div>
                                </td>
                                <td style={{width: '25%'}}>
                                    <div className="d-flex align-items-center">
                                        <div className="pe-2">Nationality: </div>
                                        <div className="flex-grow-1">
                                            <Input
                                                type="text"
                                                id="sponsorInfo.nacionality"
                                                name="sponsorInfo.nationality"
                                                className={`fs-7 input-1 p-0 form-control ${form.errors.sponsorInfo?.nationality ? 'is-invalid' : ''}`}
                                                onChange={form.handleChange}
                                                onBlur={form.handleBlur}
                                                value={form.values.sponsorInfo?.nationality || ""}  
                                            />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: '15%'}}>
                                    <div className="d-flex align-items-center">
                                        <div className="pe-2">Age: </div>
                                        <div className="flex-grow-1">
                                            <Input
                                                type="number"
                                                id="sponsorInfo.age"
                                                name="sponsorInfo.age"
                                                className={`fs-7 input-1 p-0 form-control ${form.errors.sponsorInfo?.age ? 'is-invalid' : ''}`}
                                                onChange={form.handleChange}
                                                onBlur={form.handleBlur}
                                                value={form.values.sponsorInfo?.age || ""}  
                                            />
                                        </div>
                                    </div>
                                </td>
                                <td style={{width: '20%'}}>
                                    <div className="d-flex align-items-center">
                                    <div className="pe-2">Sex: </div>
                                        <div className="flex-grow-1">
                                            <div className="form-check form-check-inline">
                                                <Input
                                                    type="radio"
                                                    value="Male"
                                                    id="genderSponsorRadio1"
                                                    name="genderSponsorRadio"
                                                    className="form-check-input"
                                                    checked={genderSponsorM==='Male'}
                                                    onChange={e=>{
                                                        setGenderSponsorM(e.target.checked ? 'Male' : 'Female')
                                                        form.setFieldValue('sponsorInfo.gender', e.target.checked ? 'Male' : 'Female')
                                                    }}
                                                />
                                                <Label
                                                    className="form-check-label fw-light"
                                                    htmlFor="genderSponsorRadio1"
                                                >
                                                    Male
                                                </Label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <Input
                                                    type="radio"
                                                    value="Female"
                                                    id="genderSponsorRadio2"
                                                    name="genderSponsorRadio"
                                                    className="form-check-input"
                                                    checked={genderSponsorM==='Female'}
                                                    onChange={e=>{
                                                        setGenderSponsorM(e.target.checked ? 'Female' : 'Male')                                                        
                                                        form.setFieldValue('sponsorInfo.gender', e.target.checked ? 'Female' : 'Male')
                                                    }}
                                                />
                                                <Label
                                                    className="form-check-label fw-light"
                                                    htmlFor="genderSponsorRadio2"
                                                >
                                                    Female
                                                </Label>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td style={{width: '30%'}}>
                                    <div className="d-flex align-items-center">
                                        <div className="pe-2">Home address: </div>
                                        <div className="flex-grow-1">
                                            {
                                                !showHomeAddressControl ? 
                                                <div onClick={e=>setShowHomeAddressControl(true)} className={`${form.values.sponsorInfo?.homeAddress ? '' : 'py-2'} border`}>{form.values.sponsorInfo?.homeAddress}</div> :
                                                <Input
                                                    type="text"
                                                    id="sponsorInfo.homeAddress"
                                                    name="sponsorInfo.homeAddress"
                                                    className={`fs-7 input-1 p-0 form-control ${form.errors.sponsorInfo?.homeAddress ? 'is-invalid' : ''}`}
                                                    onChange={form.handleChange}
                                                    onBlur={e=>{
                                                        setShowHomeAddressControl(false)
                                                    }}
                                                    value={form.values.sponsorInfo?.homeAddress || ""} 

                                                />
                                            }                                            
                                        </div>
                                    </div>
                                </td>
                                <td style={{width: '35%'}}>
                                    <div className="d-flex align-items-center">
                                        <div className="pe-2">Contact numbers </div>
                                        <div className="flex-grow-1">
                                            <InputGroup 
                                                className="input-group bootstrap-touchspin bootstrap-touchspin-injected"
                                            >                        
                                                <input
                                                type="text"
                                                id="sponsorInfo.contactNumbers"
                                                className="form-control p-1"
                                                value={number}
                                                onChange={e=>setNumber(e.target.value)}
                                                />
                                                <div
                                                className="input-group-append"
                                                >
                                                <Button 
                                                    type="button" 
                                                    className="p-1"
                                                    color="primary"
                                                    onClick={() => {
                                                        if(number.trim().length > 0){
                                                            const newN = [...form.values.sponsorInfo.contactNumbers]
                                                            newN.push({number: number});
                                                            form.setFieldValue('sponsorInfo.contactNumbers', newN)
                                                            setNumber('')
                                                        }                                
                                                    }}
                                                >
                                                    <i className="mdi mdi-plus" />
                                                </Button>
                                                </div>
                                            </InputGroup>                                            
                                        </div>
                                    </div>
                                    <div className="pt-1">
                                        <FormikProvider value={form}>
                                            <FieldArray 
                                                name="sponsorInfo.contactNumbers"
                                                render={arrayHelper=>(
                                                    <>
                                                        {
                                                            (form.values.sponsorInfo?.contactNumbers &&
                                                            form.values.sponsorInfo?.contactNumbers.length > 0) &&
                                                            (
                                                                form.values.sponsorInfo?.contactNumbers.map((item, index) => (
                                                                    <Badge className="me-2 badge-soft-primary p-1  fs-6" key={index}>
                                                                        {item.number}
                                                                        <i 
                                                                            className="mdi mdi-close-circle-outline ms-2 text-danger cursor-pointer"
                                                                            onClick={async () => {
                                                                                //primero eliminaos BD despues quitamos de aqui
                                                                                try {
                                                                                    if(item.id){
                                                                                        const response = await deleteContactNumbers(item.id)
                                                                                        if(response.success){
                                                                                            arrayHelper.remove(index)
                                                                                            toast.success(DELETE_SUCCESS)
                                                                                        }else{
                                                                                            toast.error(ERROR_SERVER)
                                                                                        }              
                                                                                    }else{
                                                                                        arrayHelper.remove(index)
                                                                                    }                                                                                                                                         
                                                                                } catch (error) {
                                                                                    toast.error(ERROR_SERVER)
                                                                                }                                                                
                                                                            }}
                                                                        />
                                                                    </Badge>
                                                                    
                                                                ))
                                                            )                                            
                                                        }
                                                    </>
                                                )}
                                            />
                                        </FormikProvider>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <Row>
                <Col xs="12" md="12">
                    <Table bordered size="sm" style={{width: '100%'}}>
                        <thead>
                            <tr className="bg-dark bg-soft">
                                <th colSpan={3}>CASE INFORMATION</th>
                            </tr>
                        </thead>
                        <tbody className="fs-7">
                            <tr>
                                <td style={{width: '40%'}}>
                                    <div className="pe-2">Date of Case Acceptance: </div>
                                    <div className="flex-grow-1">
                                        <DateInputMask
                                            date={dateAcceptance}
                                            onChange={event=>{
                                                setDateAcceptance(event.target.value)
                                                form.setFieldValue('caseInfo.dateAcceptance', event.target.value)
                                            }}
                                        />
                                        {/* <SimpleDate 
                                            date={dateAcceptance}
                                            className={'fs-7 input-1 p-0'}
                                            setDate={value=>{
                                                setDateAcceptance(value)
                                                if(value.length > 0){
                                                    form.setFieldValue('caseInfo.dateAcceptance', moment(value[0]).format("YYYY-MM-DD"))
                                                }else{
                                                    form.setFieldValue('caseInfo.dateAcceptance', null)
                                                    form.setFieldError('caseInfo.dateAcceptance', requiredField)
                                                }                                
                                            }}
                                            error={form.errors.caseInfo?.dateAcceptance ? true: false}
                                        /> */}
                                        {
                                            form.errors.caseInfo?.dateAcceptance && 
                                            <FormFeedback type="invalid">{form.errors.caseInfo?.dateAcceptance}</FormFeedback>
                                        }
                                    </div>
                                </td>
                                <td style={{width: '30%'}}>
                                    <div className="pe-2">Date of Release: </div>
                                    <div className="flex-grow-1">
                                        <DateInputMask
                                            date={dateRelease}
                                            onChange={event=>{
                                                setDateRelease(event.target.value)
                                                form.setFieldValue('caseInfo.dateRelease', event.target.value)
                                            }}
                                        />
                                        {/* <SimpleDate 
                                            date={dateRelease}
                                            className={'fs-7 input-1 p-0'}
                                            setDate={value=>{
                                                setDateRelease(value)
                                                if(value.length > 0){
                                                    form.setFieldValue('caseInfo.dateRelease', moment(value[0]).format("YYYY-MM-DD"))
                                                }else{
                                                    form.setFieldValue('caseInfo.dateRelease', null)
                                                }                                
                                            }}
                                        /> */}
                                    </div>
                                </td>
                                <td style={{width: '30%'}}>
                                    <div className="d-flex align-items-center">
                                        <div className="pe-2">Post-Release Agency: </div>
                                        <div className="flex-grow-1">
                                            <Input
                                                type="text"
                                                id="postReleaseAgency"
                                                name="caseInfo.postReleaseAgency"
                                                className={`fs-7 input-1 p-0 form-control ${form.errors.caseInfo?.postReleaseAgency ? 'is-invalid' : ''}`}
                                                onChange={form.handleChange}
                                                onBlur={form.handleBlur}
                                                value={form.values.caseInfo?.postReleaseAgency || ""}  
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className="pe-2">Case Manager: </div>
                                        <div className="flex-grow-1">
                                            <Input
                                                type="text"
                                                id="caseManager"
                                                name="caseInfo.caseManager"
                                                className={`fs-7 input-1 p-0 form-control ${form.errors.caseInfo?.caseManager ? 'is-invalid' : ''}`}
                                                onChange={form.handleChange}
                                                onBlur={form.handleBlur}
                                                value={form.values.caseInfo?.caseManager || ""}  
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className="pe-2">Contact Information: </div>
                                        <div className="flex-grow-1">
                                            <Input
                                                type="text"
                                                id="contactInformation"
                                                name="caseInfo.contactInformation"
                                                className={`fs-7 input-1 p-0 form-control ${form.errors.caseInfo?.contactInformation ? 'is-invalid' : ''}`}
                                                onChange={form.handleChange}
                                                onBlur={form.handleBlur}
                                                value={form.values.caseInfo?.contactInformation || ""}  
                                            />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: '40%'}}>
                                    <div className="d-flex align-items-center">
                                        <div className="pe-2">Date(s) and Type(s) of Contacts: </div>
                                        <div className="flex-grow-1">
                                            
                                        </div>
                                    </div>
                                </td>
                                <td style={{width: '30%'}}>
                                    <div className="pe-2">Date Report Completed: </div>
                                    <div className="flex-grow-1">
                                        <DateInputMask
                                            date={dateComplete}
                                            onChange={event=>{
                                                setDateComplete(event.target.value)
                                                form.setFieldValue('caseInfo.dateComplete', event.target.value)
                                            }}
                                        />
                                        {/* <SimpleDate 
                                            date={dateComplete}
                                            className={'fs-7 input-1 p-0'}
                                            setDate={value=>{
                                                setDateComplete(value)
                                                if(value.length > 0){
                                                    form.setFieldValue('caseInfo.dateComplete', moment(value[0]).format("YYYY-MM-DD"))
                                                }else{
                                                    form.setFieldValue('caseInfo.dateComplete', null)
                                                }                                
                                            }}
                                        /> */}
                                    </div>
                                </td>
                                <td style={{width: '30%'}}>
                                    <div className="pe-2">Date of Case Closure: </div>
                                    <div className="flex-grow-1">
                                        <DateInputMask
                                            date={dateClousure}
                                            onChange={event=>{
                                                setDateClousure(event.target.value)
                                                form.setFieldValue('caseInfo.dateClosure', event.target.value)
                                            }}
                                        />
                                        {/* <SimpleDate 
                                            date={dateClousure}
                                            className={'fs-7 input-1 p-0'}
                                            setDate={value=>{
                                                setDateClousure(value)
                                                if(value.length > 0){
                                                    form.setFieldValue('caseInfo.dateClosure', moment(value[0]).format("YYYY-MM-DD"))
                                                }else{
                                                    form.setFieldValue('caseInfo.dateClosure', null)
                                                }                                
                                            }}
                                        /> */}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
}