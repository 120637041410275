import { StyleSheet, Text, View } from "@react-pdf/renderer";
import RenderContentTable from "./RenderContentTable";

export default function ReportListContent({rows}){
    const styles = StyleSheet.create({
        row: {
            flexDirection: 'row',
            fontSize: 9,            
        },
        title: {
            display: 'block'
        },
    });

    const element = rows.map( (item, index) => 
        <View key={index.toString()}>
            <View style={[styles.row, item?.style]}>
                <Text styles={styles.title}>
                    {item.adorment === 'number' ? index+1+'. ' : ''}
                    {item.title}
                </Text>            
            </View>
            {
                item.children.map((child, columnIndex) => (
                    <View key={`${index.toString()}${columnIndex.toString()}`} style={[styles.row, item?.style]}>
                        {
                            <RenderContentTable 
                                typeInput={child.type} 
                                text={child.title} 
                                sxExtra={child?.sxExtra}
                            />
                        }                        
                    </View>
                    
                ))
            }
        </View>
        
    )

    return <>{element}</>

}