import { del, get, post, put } from "./api";
import * as url from "./url";

//get user logued
const getCaseList = query => get(`${url.CASE}${query}`)

//get case
const getCase = id => get(`${url.CASE}/${id}`)

const fillCleanCase = () => get(`${url.CASE}/newcase`)

//save case
const postCase = data => post(url.CASE, data)

//update case
const putCase = (id, data) => put(`${url.CASE}/${id}`, data)

//get user logued
const getSearchCaseByStatus = query => get(`${url.CASE_SEARCH}${query}`)

//get cases by status
const getAllCasesAndStatus = () => get(url.CASE_AND_STATUS);

//post file and get json
const postUploadFile = (is_sponsor_assessment, data, config) => post(`${url.CASE_UPLOAD_FILE}/${is_sponsor_assessment}`, data, config)

//check case by numero
const getCaseByNumero = (numero) => get(`${url.CASE_BY_NUMERO}/${numero}`)

//delete case referral resoruce
const deleteCaseReferralResource = (id) => del(`${url.CASE_REFERRAL_RESOURCE}/${id}`);
const deleteCaseReferralResourceList = (id) => del(`${url.CASE_REFERRAL_RESOURCE}/referrallist/${id}`);

export {
    getCaseList,
    getCase,
    postCase,
    putCase,
    getSearchCaseByStatus,
    getAllCasesAndStatus,
    postUploadFile,
    getCaseByNumero,
    deleteCaseReferralResource,
    deleteCaseReferralResourceList,
    fillCleanCase
};