import { Modal } from "reactstrap";

export default function ModalInformativo({
    open, 
    setOpen,
    title,
    text,
    onClickOk,
    existCase,
    onClickViewCase
}) {

    const tog_center = () => {
        setOpen(!open);
        document.body.classList.add("no_padding");
    }

    return(
        <Modal
            isOpen={open}
            toggle={() => {
            tog_center();
            }}
            centered
            backdrop={'static'}
            id="staticBackdrop"
        >
            <div className="modal-header">
            <h5 className="modal-title mt-0">{title}</h5>
            <button
                type="button"
                onClick={() => {setOpen(false);}}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div className="modal-body">
                <h6 className="m-0">{text}</h6>
            </div>
            <div className="modal-footer">
                {
                    existCase ? 
                    <button type="button" className="btn btn-primary" onClick={onClickViewCase}>View case</button> :
                    <button type="button" className="btn btn-primary" onClick={onClickOk}>Ok</button>
                }
            
            </div>
        </Modal>
    )


}