import moment from "moment";
import { useState } from "react";

export default function useGetPersonalInfo(document) {
    let age = ''
    if(document.birthday.includes("/")){
        age = moment().diff(moment(document.birthday, "MM/DD/YYYY"),  'years')
    }else{
        age = moment().diff(moment(document.birthday, 'YYYY-MM-DD'),  'years')
    }
    
    const [info, setInfo] = useState({
        header: 'CHILD IDENTIFYING INFORMATION',
        rows: [
            {
                style: {
                    height: 24,
                    borderBottomColor: '#000',
                    borderBottomWidth: 1,
                },
                columns: [
                    {
                        style: {
                            width: '50%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                        },
                        title: 'Name:',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: `${document?.name} ${document?.lastName}`,
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '30%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                        },
                        title: 'AKA:',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: document?.aka,
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '20%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: 'A#:',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: document?.aNumber,
                        typeInput: 'label'
                    }
                ]
            },
            {
                style: {
                    height: 44,
                    borderBottomColor: '#000',
                    borderBottomWidth: 1,
                },
                columns: [
                    {
                        style: {
                            width: '25%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                        },
                        title: 'Date of Birth:',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: document?.birthday,
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '25%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                        },
                        title: 'Age:',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: age,
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '25%',
                            textAlign: 'left',
                            borderRightColor: '#000',
                            borderRightWidth: 1,
                            paddingLeft: 1, 
                        },
                        title: 'Place of Birth:',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: document?.placeBirth,
                        typeInput: 'label'
                    },
                    {
                        style: {
                            width: '25%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: 'Sex:',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: document?.gender,
                        typeInput: 'label'
                    }
                ]
            }
        ]

    })

    return info;
}