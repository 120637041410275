import { useEffect } from "react";
import { useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumbs";
import SimpleLoad from "../../components/Loader/SimpleLoad";
import StagesForm from "../../components/Stages/StagesForm";
import { ERROR_SERVER } from "../../constants/messages";
import { getStage } from "../../helpers/stages";

function StagesEdit(){
    const {id} = useParams()
    const [loading, setLoading] = useState(true)
    const [stages, setStages] = useState(null)

    useEffect(() => {
        let isSubscribe = true;
        setLoading(true)
        async function getStageAPI(){
            if(isSubscribe){
                try {
                    const response = await getStage(id);
                    if(response.success){
                        const stageToEdit = {
                            id: response.content.id,
                            name: response.content.name,
                            active: response.content.active
                        }
                        setStages(stageToEdit)
                        setLoading(false)
                    }else{
                        toast.error(ERROR_SERVER)
                    }
                    
                } catch (error) {
                    toast.error(ERROR_SERVER)
                }                
            }
        }
        getStageAPI();
        return () => isSubscribe = false;
    }, [])

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Stage" breadcrumbItem="Edit stage" />
                <Row>
                    <Col xs="12" lg="12">
                        { loading ? <SimpleLoad /> : <StagesForm stage={stages} id={id}/>}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default withRouter(StagesEdit)