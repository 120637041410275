import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { CheckboxTitle } from "./CheckboxTitle";

export default function ReportHeader({dataHeader}){
    const styles = StyleSheet.create({
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 16
        },
        detailColumn: {
            display: 'block'
        },  
        title: {
            fontSize: 11,
            fontFamily: 'Times New Roman Bold',
        }     
      });
    return (
        <>
            <View style={styles.container}>
                <View style={[styles.detailColumn, {paddingLeft: 50}]}>
                    <Text style={styles.title}>PRS Case Type:</Text>
                    {
                        dataHeader?.prs_case_type?.map((it, index) => (
                            <div key={index}>
                                {
                                    !it.report_top_configuration_id && 
                                    <CheckboxTitle text={it.title} checked={it.checked}/>
                                }
                                {
                                    dataHeader?.prs_case_type?.filter(itChild => itChild.report_top_configuration_id === it.value).map(itChildMap => (
                                        <div key={itChildMap.value}>
                                            <CheckboxTitle 
                                                text={itChildMap.title} 
                                                checked={itChildMap.checked}
                                                sx={{
                                                    viewCheckbox: {
                                                        marginLeft: 12
                                                    }
                                                }}
                                            />
                                        </div>
                                    )) 
                                    
                                }
                            </div>
                            
                        ))
                    }
                    <View style={{marginBottom: 5}}/>
                    <Text style={styles.title}>PRS Visit Type:</Text>
                    {
                        dataHeader?.prs_visit_type?.map((it, index) => (
                            <div key={index}>
                                {
                                    !it.report_top_configuration_id && 
                                    <CheckboxTitle text={it.title} checked={it.checked}/>
                                }
                                {
                                    dataHeader?.prs_visit_type?.filter(itChild => itChild.report_top_configuration_id === it.value).map(itChildMap => (
                                        <div key={itChildMap.value}>
                                            <CheckboxTitle 
                                                text={itChildMap.title} 
                                                checked={itChildMap.checked}
                                                sx={{
                                                    viewCheckbox: {
                                                        marginLeft: 12
                                                    }
                                                }}
                                            />
                                        </div>
                                    )) 
                                    
                                }                                
                            </div>
                            
                        ))
                    }
                </View>
                <View style={styles.detailColumn}>
                    <Text style={styles.title}>PRS Level:</Text>
                    {
                        dataHeader?.prs_level?.map((it, index) => (
                            <div key={index}>
                                {
                                    !it.report_top_configuration_id && 
                                    <CheckboxTitle text={it.title} checked={it.checked}/>
                                }
                                {
                                    dataHeader?.prs_level?.filter(itChild => itChild.report_top_configuration_id === it.value).map(itChildMap => (
                                        <div key={itChildMap.value}>
                                            <CheckboxTitle 
                                                text={itChildMap.title} 
                                                checked={itChildMap.checked}
                                                sx={{
                                                    viewCheckbox: {
                                                        marginLeft: 12
                                                    }
                                                }}
                                            />
                                        </div>
                                    )) 
                                    
                                }                                
                            </div>
                            
                        ))
                    }
                    <View style={{marginBottom: 5}}/>
                    <Text style={styles.title}>Case Closing Summary:</Text>
                    {
                        dataHeader?.case_closing_summary?.map((it, index) => (
                            <div key={index}>
                                {
                                    !it.report_top_configuration_id && 
                                    <CheckboxTitle text={it.title} checked={it.checked}/>
                                }
                                {
                                    dataHeader?.case_closing_summary?.filter(itChild => itChild.report_top_configuration_id === it.value).map(itChildMap => (
                                        <div key={itChildMap.value}>
                                            <CheckboxTitle 
                                                text={itChildMap.title} 
                                                checked={itChildMap.checked}
                                                sx={{
                                                    viewCheckbox: {
                                                        marginLeft: 12
                                                    }
                                                }}
                                            />
                                        </div>
                                    )) 
                                    
                                }                                
                            </div>
                            
                        ))
                    }                    
                </View>
            </View>
        </>
        
    );
}