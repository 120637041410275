import { useEffect, useState } from "react";
import { Col, Row, Table } from "reactstrap";
import ControlledFieldSurvey from "../Controllers/ControlledFieldSurvey";

export default function Section4({form}){
    const { initialValues } = form;
    const [caseCloseProgram, setCaseCloseProgram] = useState(initialValues.CaseCloseProgram)
    const [reSet, setReSet] = useState(false)

    const handleOnChange = (value, idQuestion, label) => {
        const copyData = {...caseCloseProgram.data}
        const copyQuestions = [...copyData.questions];
        const idx = copyQuestions.findIndex(it=>it?.id === idQuestion)
        if(idx>=0){
            const copyQuestionObj = copyQuestions[idx]
            const copyLabels = copyQuestionObj.labels.map((it=>({...it, checked: false})))
            const idxL = copyLabels.findIndex(it=>it.value===label.value)
            if(idxL>=0){
                copyLabels[idxL].checked = true;
                copyQuestionObj.labels = copyLabels
                copyQuestions[idx] = copyQuestionObj;
            }
        }
        copyData.questions = copyQuestions
        setCaseCloseProgram(prev=>({
            ...prev,
            data: copyData
        }))
        setReSet(true)
    }

    const handleOnChangeSectionsQ = (value, idQuestion, label, sectionQId) => {
        const copyData = {...caseCloseProgram.data}
        const copySQ = {...copyData.sectionsQ}
        const copySectionsQ = {...caseCloseProgram.data.sectionsQ[sectionQId]}
        const copyQuestions = [...copySectionsQ.questions];
        const idx = copyQuestions.findIndex(it=>it?.id === idQuestion)
        if(idx>=0){
            const copyQuestionObj = copyQuestions[idx]
            const copyLabels = copyQuestionObj.labels.map((it=>({...it, checked: false})))
            const idxL = copyLabels.findIndex(it=>it.value===label.value)
            if(idxL>=0){
                copyLabels[idxL].checked = true;
                copyQuestionObj.labels = copyLabels
                copyQuestions[idx] = copyQuestionObj;
                copySectionsQ.questions = copyQuestions
            }
        }
        copySQ[sectionQId] = copySectionsQ

        copyData.sectionsQ = copySQ
        setCaseCloseProgram(prev => ({
            ...prev,
            data: copyData
        }))  
        setReSet(true)      
    }

    useEffect(() => {
        if(reSet){
            form.setFieldValue('CaseCloseProgram', caseCloseProgram)
            setReSet(false)
        }
    }, [reSet])
    
    return(
        <>
            <Row>
                <Col xs="12" md="12">
                    <Table bordered size="sm" style={{width: '100%'}} className="mb-0">
                        <thead>
                            <tr className="bg-dark bg-soft">
                                <th>
                                    <div>14- day Case Summary Visit</div>
                                    <div>Date: 9/5/22</div>
                                    <div className="fw-light">
                                        Provide a narrative summary of significant changes, child’s functioning and adjustment, 
                                        impact of services, and projected needs and services that have not been addressed in above service areas
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>IN DEVELOP</td>
                            </tr>
                        </tbody>
                    </Table>
                    <Table bordered size="sm" style={{width: '100%'}}>
                        <thead>
                            <tr className="bg-dark bg-soft">
                                <th>
                                    <div>90-day Case Summary Visit</div>
                                    <div>Date: </div>
                                    <div className="fw-light">
                                        Provide a narrative summary of significant changes, child’s functioning and adjustment, 
                                        impact of services, and projected needs and services that have not been addressed in above service areas
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>IN DEVELOP</td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <Row>
                <Col xs="12" md="12">
                    <Table bordered size="sm" style={{width: '100%'}}>
                        <thead>
                            <tr className="bg-dark bg-soft">
                                <th>
                                    <div>Case Closure Program Outcomes & Indicators</div>
                                    <div>*Please complete this section at the time of case closure*</div>
                                    <div className="fw-light">
                                        Definitions of Indicator Questions are located at the end of this document
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {  
                                        caseCloseProgram?.data?.sectionsQ &&
                                        Object.values(caseCloseProgram.data.sectionsQ)?.map(section=>(
                                            <div key={section.id}>
                                                <h6>{section?.title}</h6>
                                                {
                                                    section?.questions?.map(item=>(
                                                        <div className="mb-2" key={item.id}>
                                                            <label className="fw-400 fs-7">{item.title}</label>
                                                            <ControlledFieldSurvey 
                                                                question={item}
                                                                onChange={handleOnChangeSectionsQ}
                                                                respuesta={[]}
                                                                sectionQId={section.id}
                                                            />
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        ))
                                    }
                                    {
                                        caseCloseProgram?.data?.questions.map(item=>(
                                            <div className="mb-2" key={item.id}>
                                                <h6>{item.title}</h6>
                                                <ControlledFieldSurvey 
                                                    question={item}
                                                    onChange={handleOnChange}
                                                    respuesta={[]}
                                                />
                                            </div>
                                        ))
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <Row>
                <Col xs="12" md="12">
                    <Table bordered size="sm" style={{width: '100%'}}>
                        <thead>
                            <tr className="bg-dark bg-soft">
                                <th>
                                    Signatures
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>IN DEVELOP</td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
}