import { StyleSheet, Text, View } from "@react-pdf/renderer";

export default  function ReportCard({title, sx={}}){

    const styles = StyleSheet.create({
        header: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            borderWidth: 1,
            borderColor: '#000',
            borderBottomColor: '#000',
            backgroundColor: '#d7d7d7',
            ...sx?.header
        },
        headerText: {
            textAlign: 'center',
            fontFamily: 'Times New Roman Bold',
            fontSize: 14,
            width: '100%',
            height: 40,
            marginTop: 20,
            ...sx?.headerText
        }
    });


    return (
        <View style={styles.header}>
            <Text style={styles.headerText}>{title}</Text>
        </View>
    )
}