import { StyleSheet, Text, View } from "@react-pdf/renderer";

export default  function ReportListHeader({title, titleType='label'}){
    const styles = StyleSheet.create({
        container: {
            flexDirection: 'row',
            flexGrow: 1,
        },
        title: {
            width: '100%',
            fontSize: 11,
            fontFamily: 'Times New Roman Bold',
        },
    });

    switch(titleType){
        case 'label':
            return  (
                <View style={styles.container}>
                    <Text style={styles.title}>{title}</Text>
                </View>
            );
        default:
            return  (
                <View style={styles.container}>
                    <Text style={styles.title}>{title}</Text>
                </View>
            )

    }

}