import { Col, Row } from "reactstrap";

export default function ReasonReferral({form}){
    //console.log(form)
    return (
        <>
            <Row className="fs-7">
                <Col xs="12" md="12">
                    <h6>PRS Only</h6>
                    <div className="form-check mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="criminalHistory"
                            name="reasonReferral.prsOnly.criminalHistory"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            checked={form.values.reasonReferral.prsOnly.criminalHistory}  
                        />
                        <label
                            className="form-check-label fw-light"
                            htmlFor="criminalHistory"
                        >Criminal history</label>
                    </div>
                    <div className="form-check mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="gangInvolvement"
                            name="reasonReferral.prsOnly.gangInvolvement"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            checked={form.values.reasonReferral.prsOnly.gangInvolvement}  
                        />
                        <label
                            className="form-check-label fw-light"
                            htmlFor="gangInvolvement"
                        >Gang involement</label>
                    </div>
                    <div className="form-check mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="behavioralProblems"
                            name="reasonReferral.prsOnly.behavioralProblems"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            checked={form.values.reasonReferral.prsOnly.behavioralProblems}  
                        />
                        <label
                            className="form-check-label fw-light"
                            htmlFor="behavioralProblems"
                        >Behavioral problems</label>
                    </div>
                    <div className="form-check mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="abuseHistory"
                            name="reasonReferral.prsOnly.abuseHistory"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            checked={form.values.reasonReferral.prsOnly.abuseHistory}  
                        />
                        <label
                            className="form-check-label fw-light"
                            htmlFor="abuseHistory"
                        >Abuse history</label>
                    </div>
                    <div className="form-check mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="traumaHistory"
                            name="reasonReferral.prsOnly.traumaHistory"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            checked={form.values.reasonReferral.prsOnly.traumaHistory}  
                        />
                        <label
                            className="form-check-label fw-light"
                            htmlFor="traumaHistory"
                        >Trauma history</label>
                    </div>
                    <div className="form-check mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="mentalHealth"
                            name="reasonReferral.prsOnly.mentalHealth"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            checked={form.values.reasonReferral.prsOnly.mentalHealth}  
                        />
                        <label
                            className="form-check-label fw-light"
                            htmlFor="mentalHealth"
                        >Mental health</label>
                    </div>
                    <div className="form-check mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="suicidalBehaviors"
                            name="reasonReferral.prsOnly.suicidalBehaviors"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            checked={form.values.reasonReferral.prsOnly.suicidalBehaviors}  
                        />
                        <label
                            className="form-check-label fw-light"
                            htmlFor="suicidalBehaviors"
                        >Suicidal/self-harming behaviors</label>
                    </div>
                    <div className="form-check mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="substanceAbuse"
                            name="reasonReferral.prsOnly.substanceAbuse"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            checked={form.values.reasonReferral.prsOnly.substanceAbuse}  
                        />
                        <label
                            className="form-check-label fw-light"
                            htmlFor="substanceAbuse"
                        >Substance abuse</label>
                    </div>
                    <div className="form-check mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="cognitiveDevelopmentalDelays"
                            name="reasonReferral.prsOnly.cognitiveDevelopmentalDelays"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            checked={form.values.reasonReferral.prsOnly.cognitiveDevelopmentalDelays}  
                        />
                        <label
                            className="form-check-label fw-light"
                            htmlFor="cognitiveDevelopmentalDelays"
                        >Cognitive developmental delays</label>
                    </div>
                    <div className="form-check mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="lackFormalEducation"
                            name="reasonReferral.prsOnly.lackFormalEducation"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            checked={form.values.reasonReferral.prsOnly.lackFormalEducation}  
                        />
                        <label
                            className="form-check-label fw-light"
                            htmlFor="lackFormalEducation"
                        >Lack of formal education</label>
                    </div>
                    <div className="form-check mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="medicalCondition"
                            name="reasonReferral.prsOnly.medicalCondition"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            checked={form.values.reasonReferral.prsOnly.medicalCondition}  
                        />
                        <label
                            className="form-check-label fw-light"
                            htmlFor="medicalCondition"
                        >Medical condition</label>
                    </div>
                    <div className="form-check mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="disability"
                            name="reasonReferral.prsOnly.disability"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            checked={form.values.reasonReferral.prsOnly.disability}  
                        />
                        <label
                            className="form-check-label fw-light"
                            htmlFor="disability"
                        >Disability</label>
                    </div>
                    <div className="form-check mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="pregnantParentingTeen"
                            name="reasonReferral.prsOnly.pregnantParentingTeen"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            checked={form.values.reasonReferral.prsOnly.pregnantParentingTeen}  
                        />
                        <label
                            className="form-check-label fw-light"
                            htmlFor="pregnantParentingTeen"
                        >Pregnant or parenting teen</label>
                    </div>
                    <div className="form-check mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="lackPriorRelationshipSponsor"
                            name="reasonReferral.prsOnly.lackPriorRelationshipSponsor"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            checked={form.values.reasonReferral.prsOnly.lackPriorRelationshipSponsor}  
                        />
                        <label
                            className="form-check-label fw-light"
                            htmlFor="lackPriorRelationshipSponsor"
                        >Lack of prior relationship with sponsor</label>
                    </div>
                    <div className="form-check mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="sponsorLackKnowledge"
                            name="reasonReferral.prsOnly.sponsorLackKnowledge"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            checked={form.values.reasonReferral.prsOnly.sponsorLackKnowledge}  
                        />
                        <label
                            className="form-check-label fw-light"
                            htmlFor="sponsorLackKnowledge"
                        >Sponsor's lack of knowledge/accessibility to community resources</label>
                    </div>
                    <div className="form-check mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="traffickingConcern"
                            name="reasonReferral.prsOnly.traffickingConcern"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            checked={form.values.reasonReferral.prsOnly.traffickingConcern}  
                        />
                        <label
                            className="form-check-label fw-light"
                            htmlFor="traffickingConcern"
                        >Trafficking concern</label>
                    </div>
                    <div className="form-check mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="sponsorConcerns"
                            name="reasonReferral.prsOnly.sponsorConcerns"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            checked={form.values.reasonReferral.prsOnly.sponsorConcerns}  
                        />
                        <label
                            className="form-check-label fw-light"
                            htmlFor="sponsorConcerns"
                        >Sponsor Concerns</label>
                    </div>
                    <div className="form-check mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="prsDiscretionary"
                            name="reasonReferral.prsOnly.prsDiscretionary"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            checked={form.values.reasonReferral.prsOnly.prsDiscretionary}  
                        />
                        <label
                            className="form-check-label fw-light"
                            htmlFor="prsDiscretionary"
                        >PRS after a Discretionary Home Study</label>
                    </div>
                    <div className="form-check mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="categorySponsorship"
                            name="reasonReferral.prsOnly.categorySponsorship"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            checked={form.values.reasonReferral.prsOnly.categorySponsorship}  
                        />
                        <label
                            className="form-check-label fw-light"
                            htmlFor="categorySponsorship"
                        >Category 3 Sponsorship</label>
                    </div>
                    <div className="form-check mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="other"
                            name="reasonReferral.prsOnly.other"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            checked={form.values.reasonReferral.prsOnly.other}  
                        />
                        <label
                            className="form-check-label fw-light"
                            htmlFor="other"
                        >Other (List Reason)</label>
                    </div>
                </Col>
                <Col xs="12" md="12">
                    <h6>PRS After TVPRA Home Study</h6>
                    <div className="form-check mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="tvpraTraffickingConcerns"
                            name="reasonReferral.prsAfter.tvpraTraffickingConcerns"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            checked={form.values.reasonReferral.prsAfter.tvpraTraffickingConcerns}  
                        />
                        <label
                            className="form-check-label fw-light"
                            htmlFor="tvpraTraffickingConcerns"
                        >TVPRA category required post release service after home study trafficking concerns</label>
                    </div>
                    <div className="form-check mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="tvpraSponsorConcerns"
                            name="reasonReferral.prsAfter.tvpraSponsorConcerns"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            checked={form.values.reasonReferral.prsAfter.tvpraSponsorConcerns}  
                        />
                        <label
                            className="form-check-label fw-light"
                            htmlFor="tvpraSponsorConcerns"
                        >TVPRA category required post release service after home study sponsor concerns</label>
                    </div>
                    <div className="form-check mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="tvpraDisability"
                            name="reasonReferral.prsAfter.tvpraDisability"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            checked={form.values.reasonReferral.prsAfter.tvpraDisability}  
                        />
                        <label
                            className="form-check-label fw-light"
                            htmlFor="tvpraDisability"
                        >TVPRA category required post release service after home study disability</label>
                    </div>
                    <div className="form-check mb-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="tvpraAbuseHistory"
                            name="reasonReferral.prsAfter.tvpraAbuseHistory"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            checked={form.values.reasonReferral.prsAfter.tvpraAbuseHistory}  
                        />
                        <label
                            className="form-check-label fw-light"
                            htmlFor="tvpraAbuseHistory"
                        >TVPRA category required post release service after home study abuse history</label>
                    </div>
                </Col>
            </Row>
        </>
    )
}