import { del, get, post, put } from "./api";
import * as url from "./url";

//get user logued
const getReferralList = query => get(`${url.REFERRAL}${query}`)

//get case
const getReferral = id => get(`${url.REFERRAL}/${id}`)

//save stage
const postReferral = data => post(url.REFERRAL, data)

//update stage
const putReferral = (id, data) => put(`${url.REFERRAL}/${id}`, data)

//delete referralList
const deleteReferralList = (id) => del(`${url.REFERRAL}/referrallist/${id}`);

//get referral entity
const getReferralEntity = () => get(`${url.REFERRAL}/entity`)

export {
    getReferralList,
    getReferral,
    postReferral,
    putReferral,
    deleteReferralList,
    getReferralEntity
}