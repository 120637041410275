import { get, post, put } from "./api";
import * as url from "./url";

//get user logued
const getRelationshipList = query => get(`${url.RELATIONSHIP}${query}`)

//get case
const getRelationship = id => get(`${url.RELATIONSHIP}/${id}`)

//save stage
const postRelationship = data => post(url.RELATIONSHIP, data)

//update stage
const putRelationship = (id, data) => put(`${url.RELATIONSHIP}/${id}`, data)

const getRelationshipActives = () => get(`${url.RELATIONSHIP}/active`)

export {
    getRelationshipList,
    getRelationship,
    postRelationship,
    putRelationship,
    getRelationshipActives
}