import { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumbs";
import SimpleLoad from "../../components/Loader/SimpleLoad";
import { ERROR_SERVER } from "../../constants/messages";
import ReminderForm from "../../components/Document/ReminderForm";
import { getRemindersByCase } from "../../helpers/reminders";
import moment from "moment";

function CaseReminder(){
    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const [reminders, setReminders] = useState([])
    const [reload, setReload] = useState(true)

    const fetchApi = async () => {
        try {
            const response = await getRemindersByCase(id);
            console.log(response)
            if(response.success){
                setReminders(response.content.map((rem=>({
                    ...rem,
                    date: moment(rem.date, "YYYY-MM-DD").format("MM-DD-YYYY")
                }))))
                setReload(false)   
            }else{
                toast.error(ERROR_SERVER)
                setReload(false)   
            }
            setLoading(false)
        } catch (error) {
            toast.error(ERROR_SERVER)
            setReload(false)   
        } 
    }

    useEffect(() => {
        if(reload){
            setLoading(true)
            fetchApi()
        }
    }, [reload])

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Case" breadcrumbItem="Case reminders" />
                <Row>
                    <Col xs="12" lg="12">
                        { loading ? <SimpleLoad /> : 
                        <ReminderForm reminders={reminders} id={id} setReload={setReload}/>}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default withRouter(CaseReminder)