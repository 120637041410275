import { StyleSheet, Text, View } from "@react-pdf/renderer";

export default function ReportTableHeader({title, headerType='label', itemHeader=[]}){
    const borderColor = '#000';

    const styles = StyleSheet.create({
        container: {
            flexDirection: 'row',
            borderBottomColor: '#000',
            backgroundColor: '#d7d7d7',
            borderBottomWidth: 1,
            flexGrow: 1,
        },
        title: {
            width: '100%',
            borderRightColor: borderColor,
            fontSize: 11,
            fontFamily: 'Times New Roman Bold',
            paddingLeft: 2
        },
        container2: {
            flexDirection: 'column',
            borderBottomColor: '#000',
            backgroundColor: '#d7d7d7',
            borderBottomWidth: 1,
            flexGrow: 1,
        },
        column: {
            flexDirection: 'column'
        }
    });

    switch(headerType){
        case 'label':
            return  (
                <View style={styles.container}>
                    <Text style={styles.title}>{title}</Text>
                </View>
            );
        case 'list':
            return (
                <View style={styles.container2}>
                    {
                        itemHeader.map((item, index) => (                    
                            <Text style={[styles.title, styles.column, item?.sxExtra]} key={index.toString()}>{item.text}</Text>                        
                        ))
                    }
                </View>                
            );
        default:
            return  (
                <View style={styles.container}>
                    <Text style={styles.title}>{title}</Text>
                </View>
            )

    }
}

