import { useState } from "react";

export default function useGetStagesInfo2() {
    const [info, setInfo] = useState({
        header: '',
        headerType: 'list',
        itemHeader: [
            {
                text: '14- day Case Summary Visit'
            },
            {
                text: 'Date:'
            },
            {
                text: 'Provide a narrative summary of significant changes, child’s functioning and adjustment, impact of services, and projected needs and services that have not been addressed in above service areas',
                sxExtra: {
                    fontFamily: 'Times New Roman',
                    marginTop: 4
                }
            }
        ],
        rows: [
            {
                style: {
                    height: 44,
                },
                columns: [
                    {
                        style: {
                            width: '100%',
                            textAlign: 'left',
                            paddingLeft: 1, 
                        },
                        title: '',
                        titleStyle: {
                            fontFamily: 'Times New Roman Bold',
                        },
                        text: '',
                        typeInput: 'label',
                    }
                ]
            }
        ]

    })

    return info;
}