import { del, get, post, put } from "./api";
import * as url from "./url";


const getSurveyList = query => get(`${url.SURVEY}${query}`)


const getSurvey = id => get(`${url.SURVEY}/${id}`)


const postSurvey = data => post(url.SURVEY, data)


const putSurvey = (id, data) => put(`${url.SURVEY}/${id}`, data)

const deleteSurveyQuestion = (id) => del(`${url.SURVEY}/surveyquestion/${id}`)

const getSurveyBySection = section => get(`${url.SURVEY}/section/${section}`)

const getSurveyQuestionsActive = () => get(`${url.SURVEY}/sections`)



export {
    getSurveyList,
    getSurvey,
    postSurvey,
    putSurvey,
    deleteSurveyQuestion,
    getSurveyBySection,
    getSurveyQuestionsActive
}