import { useEffect } from "react";
import { useState } from "react";
import { getReportTopConfigurationActives } from "../helpers/headerreport";

export default function useGetHeaderReport(){
    const [items, setItems] = useState([]);
    const [loading, setLoading]= useState(true);

    useEffect(() => {
        setLoading(true)
        let isSubscribed = true;
        async function getItemsApi(){
            const response = await getReportTopConfigurationActives();
            if(response.success){
                setItems(response.content.map(it=>({
                        value: it.id, 
                        label: it.title,
                        type: it.type,
                        report_top_configuration_id: it.report_top_configuration_id
                    })))
            }
            setLoading(false)
        }
        if(isSubscribed) getItemsApi()

        return () => isSubscribed = false;
    }, [])

    return {
        headerReport: items, 
        loading
    };
}