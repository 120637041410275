import { Input } from "reactstrap"

export default function ControlledFieldSurvey({question, onChange, respuesta, sectionQId}){

    switch(question?.question_type){
        case "simple_choice":
            return (
                <div className="d-flex">
                    {
                        question.labels.map((label, idx)=>(
                            <div className="form-check form-check-end pe-3" key={idx}>
                                <Input
                                    type="radio"
                                    id={`label_${idx}_${question.id}`}
                                    name={`active_${question.id}`}
                                    className={`form-check-input`}
                                    onChange={e=>onChange(e.target.checked, question.id, label, sectionQId)} 
                                    checked={label?.checked ?? false}
                                />
                                <label className="form-check-label fs-7 fw-light" htmlFor={`label_${idx}_${question.id}`}>{label.value}</label>
                            </div>
                        ))
                    }
                </div>
            )
        default:
            return 'Controller not available'
    }




}