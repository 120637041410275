import { del, get, post, put } from "./api";
import * as url from "./url";

//get user logued
const getRemindersByCase = id => get(`${url.REMINDERS}/case/${id}`)

//save reminders
const postReminder = data => post(url.REMINDERS, data)

const deleteReminder = (id) => del(`${url.REMINDERS}/${id}`)

const getRemindersAll = query => get(`${url.REMINDERS}/all${query}`)

const markReaded = (id) => put(`${url.REMINDERS}/readed/${id}`)

export {
    getRemindersByCase,
    postReminder,
    deleteReminder,
    getRemindersAll,
    markReaded
}