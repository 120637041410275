import { StyleSheet, View } from "@react-pdf/renderer";
import ReportListContent from "./ReportListContent";
import ReportListHeader from "./ReportListHeader";

export default function ReportList({list, sx={}}){


    const styles = StyleSheet.create({
        listContainer: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: 24,
        },
    });

    return (
        list.map((item, indexList) => (
            <View style={[styles.listContainer, sx]} key={indexList.toString()}>
                <ReportListHeader title={item.title}/>
                <ReportListContent rows={item.rows}/>
            </View>
        ))
    )

}