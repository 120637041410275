import { Field, FieldArray, FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";
import { ERROR_SERVER, requiredField, SAVE_SUCCESS, UPDATE_SUCCESS } from "../../constants/messages";
import { postQuestionInstructions, putQuestionInstructions } from "../../helpers/questionInstructions";
import FullScreenLoader from "../Loader/FullScreenLoader";
import Select from "react-select";
import useGetSections from "../../hooks/useGetSections";

export default function QuestionInstructionsForm({id, item}){
    const sectionOpt = useGetSections();
    const [isSubmiting, setIsSubmiting] = useState(false)
    const history = useHistory();
    const [activeIndex, setActiveIndex] = useState(-1)
    const [activeIndexSubsection, setActiveIndexSubsection] = useState(-1)
    const [activeIndexSubsectionList, setActiveIndexSubsectionList] = useState(-1)
    const initialValues = item ? item :{
        id: null,
        name: '',
        section_id: '',
        sectionName: '',
        QuestionInstructionSections: []
    };

    const form = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: Yup.object({
            name: Yup.string().required(requiredField),
            section_id: Yup.string().required(requiredField),
            QuestionInstructionSections: Yup.array().of(
                Yup.object().shape({
                    name: Yup.string().required(requiredField),
                    QuestionInstructionSubsections: Yup.array().of(
                        Yup.object().shape({
                            description: Yup.string().required(requiredField),
                            QuestionInstructionSubsectionLists: Yup.array().of(
                                Yup.object().shape({
                                    description: Yup.string().required(requiredField),
                                })
                            )
                        })
                    )  
                })
            )           
        }),
        onSubmit: async (values) => { 
            setIsSubmiting(true)
            try {
                if(values.id){
                    const response = await putQuestionInstructions(id, values);  
                    if(response.success){
                        toast.success(UPDATE_SUCCESS)
                    }else{
                        toast.error(ERROR_SERVER)
                    }         
                    setIsSubmiting(false)            
                }else{
                    const response = await postQuestionInstructions(values)
                    if(response.success){
                        toast.success(SAVE_SUCCESS)
                    }else{
                        toast.error(ERROR_SERVER)
                    }  
                    setIsSubmiting(false)                   
                }
                setTimeout(() => {
                    history.goBack();
                }, 2000)
                
                
            } catch (error) {
                toast.error(ERROR_SERVER)
                setIsSubmiting(false) 
            }         
        }
    });
    return (
        <Card>
            {isSubmiting && <FullScreenLoader />}
            <CardBody>
                <Form
                    className="needs-validation"
                    id="tooltipForm"
                    onSubmit={(e) => {
                    e.preventDefault();
                    form.handleSubmit();
                    return false;
                }}
                >
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="name"
                        className="col-form-label col-lg-2"
                      >
                        Section
                      </Label>
                      <Col lg="10">
                        <Select
                            value={{
                                value: form.values.section_id, 
                                label: sectionOpt.find(it=>it.value===parseInt(form.values.section_id))?.label
                            }}
                            onChange={(selected) => {
                                if(selected){
                                    form.setFieldValue(`section_id`, selected.value)
                                    form.setFieldValue(`sectionName`, selected.label)
                                }else{
                                    form.setFieldValue(`section_id`, '')
                                    form.setFieldValue(`sectionName`, '')
                                }
                            }}
                            options={sectionOpt}
                            classNamePrefix="select2-selection"
                            closeMenuOnSelect={true}
                            className={`${form.errors?.houseHoldMembers?.length > 0 && form.errors?.section_id ? 'is-invalid' : ''}`}
                        />                      
                        {
                            form.errors.section_id && 
                            <FormFeedback type="invalid">{form.errors?.section_id}</FormFeedback>
                        }
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="name"
                        className="col-form-label col-lg-2"
                      >
                        Name
                      </Label>
                      <Col lg="10">
                        <Input
                            type="text"
                            id="name"
                            name="name"
                            className={`form-control ${form.errors.name ? 'is-invalid' : ''}`}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={form.values.name || ""}  
                        />                      
                        {
                            form.errors.name && 
                            <FormFeedback type="invalid">{form.errors?.name}</FormFeedback>
                        }
                      </Col>
                    </FormGroup>

                    <hr className="my-3"/>

                    <FormikProvider value={form}>
                        <FieldArray
                            name="QuestionInstructionSections" 
                            render={arrayHelper=>(
                                <>
                                <Row className="mb-4">
                                    <Col xs="12" md="12">
                                        <div className="d-flex align-items-center">
                                            <div className="pe-5">
                                                <h6 className="m-0">Question & Instructions Section</h6>
                                            </div>
                                            <div>
                                                <Button type="button" color="light" size="sm" className="me-2" onClick={() =>{ 
                                                        arrayHelper.push({
                                                            name: '',
                                                            QuestionInstructionSubsections: [],
                                                        })
                                                        setActiveIndex(form.values.QuestionInstructionSections.length)
                                                    }}
                                                >
                                                    <i className="mdi mdi-plus me-1"></i>
                                                    New 
                                                </Button>
                                                <Button type="button" color="light" size="sm" onClick={() => setActiveIndex(-1)}
                                                >
                                                    <i className="mdi mdi-minus me-1"></i>
                                                    Close All 
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                {
                                    (form.values.QuestionInstructionSections && form.values.QuestionInstructionSections.length > 0) &&
                                    form.values.QuestionInstructionSections.map((item, index) => (
                                        index !== activeIndex ?
                                        <div className="d-flex justify-content-between border-bottom mb-2" key={`QuestionInstructionSections_${index}`}>
                                            <div>
                                                <span>{item.name}</span>
                                            </div>
                                            <div>
                                                <i className="far fa-eye" onClick={() => {
                                                    setActiveIndexSubsection(-1)
                                                    setActiveIndexSubsectionList(-1)
                                                    setActiveIndex(index)
                                                }}></i>
                                            </div>                                            
                                        </div>
                                        :
                                        <div key={`QuestionInstructionSections_${index}`} className="border p-3 mb-2 shadow-sm">
                                            <FormGroup className="mb-4" row>
                                                <Label htmlFor={`name_${index}`} className="col-form-label col-lg-2">Name</Label>
                                                <Col lg="10">
                                                    <Field
                                                        type="text"
                                                        id={`name_${index}`}
                                                        name={`QuestionInstructionSections.${index}.name`}
                                                        className={`form-control ${form.errors?.QuestionInstructionSections?.length > 0 && form.errors.QuestionInstructionSections[index]?.name ? 'is-invalid' : ''}`} 
                                                    />
                                                    {
                                                        (form.errors?.QuestionInstructionSections?.length > 0 && form.errors?.QuestionInstructionSections[index]?.name) &&
                                                        <FormFeedback type="invalid">{form.errors?.QuestionInstructionSections[index]?.name}</FormFeedback>
                                                    }
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="my-2" row>
                                                <FieldArray 
                                                    name={`QuestionInstructionSections.${index}.QuestionInstructionSubsections`}
                                                    render={arrayHelperSubsection=>(
                                                        <>
                                                            <div className="d-flex align-items-center border-bottom pb-2">
                                                                <div className="pe-5">
                                                                    <h6 className="m-0">Question & Instructions Subsection</h6>
                                                                </div>
                                                                <div>
                                                                    <Button type="button" color="light" size="sm" className="me-2" onClick={() =>{ 
                                                                            arrayHelperSubsection.push({
                                                                                description: '',
                                                                                adorment: 'number',
                                                                                QuestionInstructionSubsectionLists: [],
                                                                            })
                                                                            setActiveIndexSubsection(form.values.QuestionInstructionSections[index].QuestionInstructionSubsections.length)
                                                                        }}
                                                                    >
                                                                        <i className="mdi mdi-plus me-1"></i>
                                                                        New 
                                                                    </Button>
                                                                    <Button type="button" color="light" size="sm" onClick={() => setActiveIndexSubsection(-1)}
                                                                    >
                                                                        <i className="mdi mdi-minus me-1"></i>
                                                                        Close All 
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                            {
                                                                (form.values.QuestionInstructionSections[index].QuestionInstructionSubsections && 
                                                                    form.values.QuestionInstructionSections[index].QuestionInstructionSubsections.length > 0) &&
                                                                form.values.QuestionInstructionSections[index].QuestionInstructionSubsections.map((itemSub, indexSub) => (
                                                                    indexSub !== activeIndexSubsection ?
                                                                    <div className="d-flex justify-content-between border-bottom mb-2" key={`QuestionInstructionSubsections_${indexSub}`}>
                                                                        <div>
                                                                            <span>{itemSub.description.substring(0, 200)}...</span>
                                                                        </div>
                                                                        <div>
                                                                            <i className="far fa-eye" onClick={() => {
                                                                                setActiveIndexSubsectionList(-1)
                                                                                setActiveIndexSubsection(indexSub)
                                                                            }}></i>
                                                                        </div>                                            
                                                                    </div>
                                                                    :
                                                                    <div key={`QuestionInstructionSubsections_${indexSub}`} className="px-5 py-3 mb-2">
                                                                        <FormGroup className="mb-2" row>
                                                                            <Label htmlFor={`name_${index}_${indexSub}`} className="col-form-label col-lg-2">Description</Label>
                                                                            <Col lg="10">
                                                                                <textarea
                                                                                    className={`form-control ${form.errors?.QuestionInstructionSections?.[index]?.QuestionInstructionSubsections?.length > 0 && 
                                                                                        form.errors.QuestionInstructionSections?.[index]?.QuestionInstructionSubsections?.[indexSub]?.description ? 'is-invalid' : ''}`} 
                                                                                    id={`name_${index}_${indexSub}`}
                                                                                    rows="3"
                                                                                    name={`QuestionInstructionSections.${index}.QuestionInstructionSubsections.${indexSub}.description`}
                                                                                    value={form.values.QuestionInstructionSections[index]?.QuestionInstructionSubsections[indexSub]?.description || ""}
                                                                                    onChange={form.handleChange}
                                                                                    onBlur={form.handleBlur}
                                                                                />
                                                                                {
                                                                                    (form.errors?.QuestionInstructionSections?.[index]?.QuestionInstructionSubsections?.length > 0 && 
                                                                                        form.errors.QuestionInstructionSections?.[index]?.QuestionInstructionSubsections?.[indexSub]?.description) &&
                                                                                    <FormFeedback type="invalid">{form.errors?.QuestionInstructionSections[index]?.QuestionInstructionSubsections[indexSub]?.description}</FormFeedback>
                                                                                }
                                                                            </Col>
                                                                        </FormGroup>
                                                                        <FormGroup className="mb-2" row>
                                                                            <Label htmlFor={`adorment_${index}_${indexSub}`} className="col-form-label col-lg-2">Adorment</Label>
                                                                            <Col lg="10">
                                                                                <select 
                                                                                    className="form-select" 
                                                                                    name={`QuestionInstructionSections.${index}.QuestionInstructionSubsections.${indexSub}.adorment`}
                                                                                    value={form.values.QuestionInstructionSections[index]?.QuestionInstructionSubsections[indexSub]?.adorment} 
                                                                                    onChange={form.handleChange}
                                                                                    onBlur={form.handleBlur}
                                                                                >
                                                                                    <option value="number">Number</option>
                                                                                    <option value="bullet">Bullet</option>
                                                                                </select>
                                                                            </Col>
                                                                        </FormGroup>
                                                                        <FormGroup className="my-2" row>
                                                                            <FieldArray 
                                                                                name={`QuestionInstructionSections.${index}.QuestionInstructionSubsections.${indexSub}.QuestionInstructionSubsectionLists`}
                                                                                render={arrayHelperSubsectionlist => (
                                                                                    <>
                                                                                        <div className="d-flex align-items-center border-bottom pb-2">
                                                                                            <div className="pe-5">
                                                                                                <h6 className="m-0">Question & Instructions Subsection List</h6>
                                                                                            </div>
                                                                                            <div>
                                                                                                <Button type="button" color="light" size="sm" className="me-2" onClick={() =>{ 
                                                                                                        arrayHelperSubsectionlist.push({
                                                                                                            description: '',
                                                                                                            adorment: 'bullet'
                                                                                                        })
                                                                                                        setActiveIndexSubsectionList(form.values.QuestionInstructionSections[index].QuestionInstructionSubsections[indexSub].QuestionInstructionSubsectionLists.length)
                                                                                                    }}
                                                                                                >
                                                                                                    <i className="mdi mdi-plus me-1"></i>
                                                                                                    New 
                                                                                                </Button>
                                                                                                <Button type="button" color="light" size="sm" onClick={() => setActiveIndexSubsectionList(-1)}
                                                                                                >
                                                                                                    <i className="mdi mdi-minus me-1"></i>
                                                                                                    Close All 
                                                                                                </Button>
                                                                                            </div>
                                                                                        </div>
                                                                                        {
                                                                                            (form.values.QuestionInstructionSections[index].QuestionInstructionSubsections[indexSub].QuestionInstructionSubsectionLists && 
                                                                                                form.values.QuestionInstructionSections[index].QuestionInstructionSubsections[indexSub].QuestionInstructionSubsectionLists.length > 0) &&
                                                                                            form.values.QuestionInstructionSections[index].QuestionInstructionSubsections[indexSub].QuestionInstructionSubsectionLists.map((itemSubList, indexSubList) => (
                                                                                                indexSubList !== activeIndexSubsectionList ?
                                                                                                <div className="d-flex justify-content-between border-bottom mb-2" key={`QuestionInstructionSubsectionLists_${indexSubList}`}>
                                                                                                    <div>
                                                                                                        <span>{itemSubList.description.substring(0, 200)}...</span>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <i className="far fa-eye" onClick={() => {
                                                                                                            setActiveIndexSubsectionList(indexSubList)
                                                                                                        }}></i>
                                                                                                    </div>                                            
                                                                                                </div>
                                                                                                :
                                                                                                <div key={`QuestionInstructionSubsectionLists_${indexSubList}`} className="px-5 py-3 mb-2">
                                                                                                    <FormGroup className="mb-2" row>
                                                                                                        <Label htmlFor={`name_${index}_${indexSub}_${indexSubList}`} className="col-form-label col-lg-2">Description</Label>
                                                                                                        <Col lg="10">
                                                                                                            <textarea
                                                                                                                className={`form-control ${form.errors?.QuestionInstructionSections?.[index]?.QuestionInstructionSubsections?.[indexSub]?.QuestionInstructionSubsectionLists?.length > 0 && 
                                                                                                                    form.errors.QuestionInstructionSections?.[index]?.QuestionInstructionSubsections?.[indexSub]?.QuestionInstructionSubsectionLists?.[indexSubList]?.description ? 'is-invalid' : ''}`} 
                                                                                                                id={`name_${index}_${indexSub}_${indexSubList}`}
                                                                                                                rows="3"
                                                                                                                name={`QuestionInstructionSections.${index}.QuestionInstructionSubsections.${indexSub}.QuestionInstructionSubsectionLists.${indexSubList}.description`}
                                                                                                                value={form.values.QuestionInstructionSections[index]?.QuestionInstructionSubsections[indexSub]?.QuestionInstructionSubsectionLists[indexSubList]?.description || ""}
                                                                                                                onChange={form.handleChange}
                                                                                                                onBlur={form.handleBlur}
                                                                                                            />
                                                                                                            {
                                                                                                                (form.errors?.QuestionInstructionSections?.[index]?.QuestionInstructionSubsections?.[indexSub]?.QuestionInstructionSubsectionLists?.length > 0 && 
                                                                                                                    form.errors.QuestionInstructionSections?.[index]?.QuestionInstructionSubsections?.[indexSub]?.QuestionInstructionSubsectionLists?.[indexSubList]?.description) &&
                                                                                                                <FormFeedback type="invalid">{form.errors?.QuestionInstructionSections[index]?.QuestionInstructionSubsections[indexSub]?.QuestionInstructionSubsectionLists?.[indexSubList]?.description}</FormFeedback>
                                                                                                            }
                                                                                                        </Col>
                                                                                                    </FormGroup>
                                                                                                    <FormGroup className="mb-2" row>
                                                                                                        <Label htmlFor={`adorment_${index}_${indexSub}_${indexSubList}`} className="col-form-label col-lg-2">Adorment</Label>
                                                                                                        <Col lg="10">
                                                                                                            <select 
                                                                                                                className="form-select" 
                                                                                                                name={`QuestionInstructionSections.${index}.QuestionInstructionSubsections.${indexSub}.QuestionInstructionSubsectionLists.${indexSubList}.adorment`}
                                                                                                                value={form.values.QuestionInstructionSections[index]?.QuestionInstructionSubsections[indexSub]?.QuestionInstructionSubsectionLists[indexSubList]?.adorment} 
                                                                                                                onChange={form.handleChange}
                                                                                                                onBlur={form.handleBlur}
                                                                                                            >
                                                                                                                <option value="number">Number</option>
                                                                                                                <option value="bullet">Bullet</option>
                                                                                                            </select>
                                                                                                        </Col>
                                                                                                    </FormGroup>
                                                                                                    <div className="d-flex justify-content-end">
                                                                                                        <Button type="button" color="outline-danger" size="sm" 
                                                                                                            onClick={async () => {
                                                                                                                if(item.id){
                                                                                                                    // try {
                                                                                                                    //     const response = await deleteSurveyQuestion(item.id)
                                                                                                                    //     console.log(response)
                                                                                                                    //     if(response.success){
                                                                                                                    //         arrayHelper.remove(index)
                                                                                                                    //         toast.success(DELETE_SUCCESS)
                                                                                                                    //     }else{
                                                                                                                    //         toast.error(ERROR_SERVER)
                                                                                                                    //     }                                                                   
                                                                                                                    // } catch (error) {
                                                                                                                    //     toast.error(ERROR_SERVER)
                                                                                                                    // }
                                                                                                                }else{
                                                                                                                    arrayHelperSubsectionlist.remove(indexSubList)
                                                                                                                }    
                                                                                                                if(indexSubList === 0) setActiveIndexSubsectionList(-1)                                          
                                                                                                            }}
                                                                                                        >
                                                                                                            <i className="mdi mdi-trash me-1"></i>
                                                                                                            Delete 
                                                                                                        </Button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ))
                                                                                        }
                                                                                    </>
                                                                                )}
                                                                            />
                                                                        </FormGroup>
                                                                            {activeIndexSubsectionList === -1  &&
                                                                            <div className="d-flex justify-content-end">
                                                                                <Button type="button" color="outline-danger" size="sm" 
                                                                                    onClick={async () => {
                                                                                        if(item.id){
                                                                                            // try {
                                                                                            //     const response = await deleteSurveyQuestion(item.id)
                                                                                            //     console.log(response)
                                                                                            //     if(response.success){
                                                                                            //         arrayHelper.remove(index)
                                                                                            //         toast.success(DELETE_SUCCESS)
                                                                                            //     }else{
                                                                                            //         toast.error(ERROR_SERVER)
                                                                                            //     }                                                                   
                                                                                            // } catch (error) {
                                                                                            //     toast.error(ERROR_SERVER)
                                                                                            // }
                                                                                        }else{
                                                                                            arrayHelperSubsection.remove(indexSub)
                                                                                        }      
                                                                                        if(indexSub === 0) setActiveIndexSubsection(-1)        
                                                                                    }}
                                                                                >
                                                                                    <i className="mdi mdi-trash me-1"></i>
                                                                                    Delete 
                                                                                </Button>
                                                                            </div>}
                                                                    </div>
                                                                ))
                                                            }
                                                        </>
                                                    )} 
                                                />
                                            </FormGroup>



                                                {(activeIndexSubsection === -1 && activeIndexSubsectionList === -1)  &&
                                                <div className="d-flex justify-content-end">
                                                    <Button type="button" color="outline-danger" size="sm" 
                                                        onClick={async () => {
                                                            if(item.id){
                                                                // try {
                                                                //     const response = await deleteSurveyQuestion(item.id)
                                                                //     console.log(response)
                                                                //     if(response.success){
                                                                //         arrayHelper.remove(index)
                                                                //         toast.success(DELETE_SUCCESS)
                                                                //     }else{
                                                                //         toast.error(ERROR_SERVER)
                                                                //     }                                                                   
                                                                // } catch (error) {
                                                                //     toast.error(ERROR_SERVER)
                                                                // }
                                                            }else{
                                                                arrayHelper.remove(index)
                                                            }                                                       
                                                        }}
                                                    >
                                                        <i className="mdi mdi-trash me-1"></i>
                                                        Delete 
                                                    </Button>
                                                </div>}                                          
                                        </div>
                                    ))
                                }
                                </>
                            )}
                        />
                    </FormikProvider>   

                    <Row className="justify-content-end mt-3">
                        <Col lg="10">
                        <Button type="submit" color="success" className="me-2">
                            Save
                        </Button>
                        <Link to="/questioninstructions-list" className="btn btn-light waves-effect">Cancel</Link>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
        </Card>
    )

}