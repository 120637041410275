import { useEffect, useState } from "react";
import { Field, FieldArray, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { Button, Card, CardBody, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { DELETE_SUCCESS, ERROR_SERVER, requiredField, SAVE_SUCCESS, UPDATE_SUCCESS } from "../../constants/messages";
import FullScreenLoader from "../Loader/FullScreenLoader";
import { deleteSurveyQuestion, postSurvey, putSurvey } from "../../helpers/survey";
import { questionTypeOpt } from "../../constants/quesionType";
import Select from "react-select";
import { sectionTypeOpt } from "../../constants/sectionType";

export default function SurveyForm({id, item}){
    const [isSubmiting, setIsSubmiting] = useState(false)
    const [activeIndex, setActiveIndex] = useState(-1)
    const [itemsOpt, setItemsOpt] = useState([])
    const history = useHistory();
    const [inputText, setInputText] = useState([]);
    
    const initialValues = item ? item :{
        id: null,
        section: '',
        title: '',
        description: '',
        description_done: '',
        questions:[],
        active: true,
    };
    const form = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: Yup.object({
            section: Yup.string().required(requiredField),
            title: Yup.string().required(requiredField),
            questions: Yup.array().of(
                Yup.object().shape({
                    title: Yup.string().required(requiredField),
                })
            )           
        }),
        onSubmit: async (values) => { 
            setIsSubmiting(true)
            //console.log(values)
            try {
                if(values.id){
                    const response = await putSurvey(id, values);  
                    if(response.success){
                        toast.success(UPDATE_SUCCESS)
                    }else{
                        toast.error(ERROR_SERVER)
                    }         
                    setIsSubmiting(false)            
                }else{
                    const response = await postSurvey(values)
                    if(response.success){
                        toast.success(SAVE_SUCCESS)
                    }else{
                        toast.error(ERROR_SERVER)
                    }  
                    setIsSubmiting(false)                   
                }
                setTimeout(() => {
                    history.goBack();
                }, 2000)
                
                
            } catch (error) {
                toast.error(ERROR_SERVER)
                setIsSubmiting(false) 
            }         
        }
    })

    useEffect(() => {
        //console.log(form.values.questions)
        setItemsOpt(form.values.questions.filter(it=>it.is_page).map(it=>({value: it.id, label: it.title})))
    }, [form.values.questions])
    return (
        <Card>
            {isSubmiting && <FullScreenLoader />}
            <CardBody>
                <Form
                    className="needs-validation"
                    id="tooltipForm"
                    onSubmit={(e) => {
                    e.preventDefault();
                    form.handleSubmit();
                    return false;
                }}
                >
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="section"
                        className="col-form-label col-lg-2"
                      >
                        Section
                      </Label>
                      <Col lg="10">
                        <Select
                            value={{
                                value: form.values.section, 
                                label: sectionTypeOpt.find(item => item.value === form.values.section)?.label
                            }}
                            onChange={(value) => {
                                console.log(value)
                                form.setFieldValue(`section`, value.value)
                            }}
                            options={sectionTypeOpt}
                            classNamePrefix="select2-selection"
                            closeMenuOnSelect={true}
                        />                        
                        {
                            form.errors.section && 
                            <FormFeedback type="invalid">{form.errors?.section}</FormFeedback>
                        }
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="stagesname"
                        className="col-form-label col-lg-2"
                      >
                        Title
                      </Label>
                      <Col lg="10">
                        <Input
                            type="text"
                            id="title"
                            name="title"
                            className={`form-control ${form.errors.title ? 'is-invalid' : ''}`}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={form.values.title || ""}  
                        />
                        {
                            form.errors.title && 
                            <FormFeedback type="invalid">{form.errors?.title}</FormFeedback>
                        }
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="description_done"
                        className="col-form-label col-lg-2"
                      >
                        Subtitle
                      </Label>
                      <Col lg="10">
                        <Input
                            type="text"
                            id="description_done"
                            name="description_done"
                            className={`form-control ${form.errors.description_done ? 'is-invalid' : ''}`}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={form.values.description_done || ""}  
                        />
                        {
                            form.errors.description_done && 
                            <FormFeedback type="invalid">{form.errors?.description_done}</FormFeedback>
                        }
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="email"
                        className="col-form-label col-lg-2"
                      >
                        Description
                      </Label>
                      <Col lg="10">
                        <textarea
                            id="description"
                            name="description"
                            className={`form-control ${form.errors.description ? 'is-invalid' : ''}`}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={form.values.description || ""}  
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="stagesactive"
                        className="col-form-label col-lg-2"
                      >
                        
                      </Label>
                      <Col lg="10">
                        <div className="form-check form-check-end">
                            <Input
                                type="checkbox"
                                id="stagesactive"
                                name="active"
                                className={`form-check-input ${form.errors.active ? 'is-invalid' : ''}`}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                                checked={form.values.active || ""}  
                            />
                            <label
                                className="form-check-label"
                                htmlFor="stagesactive"
                            >
                            Active
                          </label>
                        </div>
                      </Col>
                    </FormGroup>

                    <hr className="my-3"/>

                    <FormikProvider value={form}>
                        <FieldArray
                            name="questions" 
                            render={arrayHelper=>(
                                <>
                                <Row className="mb-4">
                                    <Col xs="12" md="12">
                                        <div className="d-flex align-items-center">
                                            <div className="pe-5">
                                                <h6 className="m-0">Question List</h6>
                                            </div>
                                            <div>
                                                <Button type="button" color="light" size="sm" className="me-2" onClick={() =>{ 
                                                        arrayHelper.push({
                                                            title: '',
                                                            description: '',
                                                            question_placeholder: '',
                                                            question_type: 'simple_choice',
                                                            is_page: false,
                                                            labels: [],
                                                            page_id: null,
                                                        })
                                                        setActiveIndex(form.values.questions.length)
                                                    }}
                                                >
                                                    <i className="mdi mdi-plus me-1"></i>
                                                    New 
                                                </Button>
                                                <Button type="button" color="light" size="sm" onClick={() => setActiveIndex(-1)}
                                                >
                                                    <i className="mdi mdi-minus me-1"></i>
                                                    Close All 
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                {
                                    (form.values.questions && form.values.questions.length > 0) &&
                                    form.values.questions.map((item, index) => (
                                        index !== activeIndex ?
                                        <div className="d-flex justify-content-between border-bottom mb-2" key={index}>
                                            <div>
                                                <span className={`${item.is_page ? 'fw-bold': ''}`}>{item.title}</span>
                                            </div>
                                            <div>
                                                <i className="far fa-eye" onClick={() => setActiveIndex(index)}></i>
                                            </div>                                            
                                        </div>
                                        :
                                        <div key={index} className="border p-3 mb-2 shadow-sm">
                                            <FormGroup className="mb-4" row>
                                                <Label htmlFor={`title_${index}`} className="col-form-label col-lg-2">Title</Label>
                                                <Col lg="10">
                                                    <Field
                                                        type="text"
                                                        id={`title_${index}`}
                                                        name={`questions.${index}.title`}
                                                        className={`form-control ${form.errors?.questions?.length > 0 && form.errors.questions[index]?.title ? 'is-invalid' : ''}`} 
                                                    />
                                                    {
                                                        (form.errors?.questions?.length > 0 && form.errors?.questions[index]?.title) &&
                                                        <FormFeedback type="invalid">{form.errors?.questions[index]?.title}</FormFeedback>
                                                    }
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="mb-4 align-items-center" row>
                                                <Label
                                                    htmlFor={`is_page_${index}`}
                                                    className="col-form-label col-lg-2"
                                                >
                                                    Is Section
                                                </Label>
                                                <Col lg="10">
                                                    <div className="form-check form-check-end">
                                                        <Field
                                                            type="checkbox"
                                                            id={`is_page_${index}`}
                                                            name={`questions.${index}.is_page`}
                                                            className={`form-check-input`} 
                                                        />
                                                    </div>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="mb-4" row>
                                                <Label htmlFor={`description_${index}`} className="col-form-label col-lg-2">Description</Label>
                                                <Col lg="10">
                                                    <textarea
                                                        id={`description_${index}`}
                                                        name={`questions.${index}.description`}
                                                        className={`form-control`}
                                                        onChange={form.handleChange}
                                                        onBlur={form.handleBlur}
                                                        value={form.values.questions[index].description || ""}  
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="mb-4" row>
                                                <Label htmlFor={`question_placeholder_${index}`} className="col-form-label col-lg-2">Placeholder</Label>
                                                <Col lg="10">
                                                    <Field
                                                        type="text"
                                                        id={`question_placeholder_${index}`}
                                                        name={`questions.${index}.question_placeholder`}
                                                        className={`form-control ${form.errors?.questions?.length > 0 && form.errors.questions[index]?.question_placeholder ? 'is-invalid' : ''}`} 
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="mb-4" row>
                                                <Label htmlFor={`type_${index}`} className="col-form-label col-lg-2">Type</Label>
                                                <Col lg="10">
                                                    <Select
                                                        value={{
                                                            value: form.values.questions[index].question_type, 
                                                            label: questionTypeOpt.find(item => item.value === form.values.questions[index].question_type)?.label
                                                        }}
                                                        onChange={(value) => {
                                                            form.setFieldValue(`questions.${index}.question_type`, value.value)
                                                        }}
                                                        options={questionTypeOpt}
                                                        classNamePrefix="select2-selection"
                                                        closeMenuOnSelect={true}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            
                                            {!form.values.questions[index].is_page &&
                                                <>
                                                    <FormGroup className="mb-4" row>
                                                        <Label htmlFor={`labels_${index}`} className="col-form-label col-lg-2">Options</Label>
                                                        <Col lg="10">
                                                            <div className="d-flex flex-wrap gap-3 align-items-center">
                                                            {
                                                                form.values.questions[index].labels.map((label, idx)=>(
                                                                    <span className="position-relative border p-2" key={idx}>
                                                                        {label.value} 
                                                                        <span 
                                                                            className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                                                                            onClick={() => {
                                                                                const newLabels = [...form.values.questions[index].labels];
                                                                                newLabels.splice(idx, 1)
                                                                                form.setFieldValue(`questions.${index}.labels`, newLabels)
                                                                            }}
                                                                        >
                                                                            <i className="fas fa-times-circle" /> 
                                                                            <span className="visually-hidden">delete</span>
                                                                        </span>
                                                                    </span>
                                                                ))
                                                            }
                                                            <Input
                                                                className="form-control w-auto"
                                                                value={inputText[index] ?? ''}
                                                                onChange={e=>{
                                                                    const copyI = [...inputText]
                                                                    copyI[index] = e.target.value;
                                                                    setInputText(copyI)
                                                                }}
                                                            />
                                                            <Button 
                                                                color="secondary"
                                                                onClick={() => {
                                                                    form.values.questions[index].labels.push({
                                                                        value: inputText[index]
                                                                    })
                                                                    const copyI = [...inputText]
                                                                    copyI[index] = '';
                                                                    setInputText(copyI)
                                                                    form.setFieldValue(`questions.${index}.labels`, form.values.questions[index].labels)
                                                                }}
                                                            >Add</Button>
                                                            </div>
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup className="mb-4" row>
                                                        <Label htmlFor={`type_${index}`} className="col-form-label col-lg-2">Belong to</Label>
                                                        <Col lg="10">
                                                            <Select
                                                                value={{
                                                                    value: form.values.questions[index].page_id, 
                                                                    label: itemsOpt.find(item => item.value === form.values.questions[index].page_id)?.label
                                                                }}
                                                                onChange={(value) => {
                                                                    form.setFieldValue(`questions.${index}.page_id`, value.value)
                                                                }}
                                                                options={itemsOpt}
                                                                classNamePrefix="select2-selection"
                                                                closeMenuOnSelect={true}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                </>}
                                            <div className="d-flex justify-content-end">
                                                <Button type="button" color="outline-danger" size="sm" 
                                                    onClick={async () => {
                                                        if(item.id){
                                                            try {
                                                                const response = await deleteSurveyQuestion(item.id)
                                                                console.log(response)
                                                                if(response.success){
                                                                    arrayHelper.remove(index)
                                                                    toast.success(DELETE_SUCCESS)
                                                                }else{
                                                                    toast.error(ERROR_SERVER)
                                                                }                                                                   
                                                            } catch (error) {
                                                                toast.error(ERROR_SERVER)
                                                            }
                                                        }else{
                                                            arrayHelper.remove(index)
                                                        }                                                       
                                                    }}
                                                >
                                                    <i className="mdi mdi-trash me-1"></i>
                                                    Delete 
                                                </Button>
                                            </div>                                            
                                        </div>
                                    ))
                                }
                                </>
                            )}
                        />
                    </FormikProvider>   

                    <Row className="justify-content-end mt-3">
                        <Col lg="10">
                        <Button type="submit" color="success" className="me-2">
                            Save
                        </Button>
                        <Link to="/survey-list" className="btn btn-light waves-effect">Cancel</Link>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
        </Card>
    )
}