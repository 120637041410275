import { Document, Font, Image, Page, PDFViewer, StyleSheet, Text } from "@react-pdf/renderer"
import logoReporte from '../../../assets/images/logo-reporte.png'
import useGetCaseInfo from "../../../hooks/reports/useGetCaseInfo";
import useGetHouseHoldMembers from "../../../hooks/reports/useGetHouseHoldMembers";
import useGetIndicatorQuestionInfo from "../../../hooks/reports/useGetIndicatorQuestionInfo";
import useGetPersonalInfo from "../../../hooks/reports/useGetPersonalInfo";
import useGetReasonForReferral from "../../../hooks/reports/useGetReasonForReferral";
import useGetSafetlyStatus from "../../../hooks/reports/useGetSafetlyStatus";
import useGetServicesArea from "../../../hooks/reports/useGetServicesArea";
import useGetSignatureInfo from "../../../hooks/reports/useGetSignatureInfo";
import useGetSponsorInfo from "../../../hooks/reports/useGetSponsorInfo";
import useGetStagesInfo2 from "../../../hooks/reports/useGetStagesInfo2";
import useGetStagesInfo3 from "../../../hooks/reports/useGetStagesInfo3";
import useGetSuplementalInstructionsInfo from "../../../hooks/reports/useGetSuplementalInstructionsInfo";
import useGetCaseCloseProgram from "../../../hooks/reports/useGetCaseCloseProgram";
import ReportCard from "./ReportCard";
import ReportHeader from "./ReportHeader";
import ReportList from "./ReportList";
import ReportTable from "./ReportTable";
import ReportTitle from "./ReportTitle";
import fontTimes from "../../../assets/fonts/times.ttf"
import fontTimesBold from "../../../assets/fonts/timesbd.ttf"
import useGetReferralResourceReport from "../../../hooks/reports/useGetReferralResourceReport";

export default function ReportPreview({document}){
    const headerReport = document.values.Header.data;
    const personalInfo = useGetPersonalInfo(document.values)
    const sponsorInfo = useGetSponsorInfo(document.values)
    const caseInfo = useGetCaseInfo(document.values)
    const houseHoldMembersInfo = useGetHouseHoldMembers(document.values)
    const reasonForReferralInfo = useGetReasonForReferral(document.values)
    const safetlyStatusInfo = useGetSafetlyStatus(document.values.SafetyStatus.data)
    const caseReferralResource = useGetReferralResourceReport(document.values.ReferralResource.data)
    const caseCloseProgramInfo = useGetCaseCloseProgram(document.values.CaseCloseProgram.data)
    const indicatorQuestionInfo = useGetIndicatorQuestionInfo(document.values.DestinationIndicator.data);
    const suplementalInstructionsInfo = useGetSuplementalInstructionsInfo(document.values.ServiceInstructions.data);


    //In develop
    const servicesAreasInfo = useGetServicesArea();
    const summaryStagesInfo2 = useGetStagesInfo2();
    const summaryStagesInfo3 = useGetStagesInfo3();
    const signatureInfo = useGetSignatureInfo();

    Font.register({
        family: 'Times New Roman',
        format: "truetype",
        src: fontTimes,
    }); 
    Font.register({
        family: 'Times New Roman Bold',
        format: "truetype",
        src: fontTimesBold,
    });

    const styles = StyleSheet.create({
        body: {
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: 35,
            fontFamily: 'Times New Roman',
        },
        logo: {
            width: 140,
            height: 75,
            marginRight: 'auto'
        }
    });
    return (
        <div>
            <PDFViewer width="1000" height="600" className="reporte-viewer" >
                <Document>
                    <Page size="A4" style={styles.body}>
                        <Image style={styles.logo} src={logoReporte} />
                        <ReportTitle />
                        <ReportHeader dataHeader={headerReport}/>
                        <ReportTable table={personalInfo} />
                        <ReportTable table={sponsorInfo} sx={{marginTop: 20}} />
                        <ReportTable table={caseInfo} sx={{marginTop: 20}} />
                        
                        <Text break></Text>
                        <ReportTable table={houseHoldMembersInfo} sx={{marginTop: 20}} />
                        <ReportTable table={reasonForReferralInfo} sx={{marginTop: 20}} />
                        {safetlyStatusInfo && <ReportTable table={safetlyStatusInfo} sx={{marginTop: 20}} />}
                        <Text break></Text>
                        <ReportTable table={servicesAreasInfo} sx={{marginTop: 20}} />
                        <ReportTable table={caseReferralResource} sx={{marginTop: 20}} />
                       <ReportTable table={summaryStagesInfo2} sx={{marginTop: 0}} />
                        <ReportTable table={summaryStagesInfo3} sx={{marginTop: 0}} />
                        {caseCloseProgramInfo && <ReportTable table={caseCloseProgramInfo} sx={{marginTop: 20}} />}
                        <ReportTable table={signatureInfo} sx={{marginTop: 20}} />
                        <ReportCard 
                            title={document.values?.DestinationIndicator?.data?.name} 
                            sx={{
                                header: {marginTop: 20}
                            }}
                        />
                        <ReportList list={indicatorQuestionInfo} sx={{marginTop: 20}}/>
                        <ReportCard 
                            title={document.values?.ServiceInstructions?.data?.name} 
                            sx={{
                                header: {marginTop: 20}
                            }}
                        />
                        <ReportList list={suplementalInstructionsInfo} sx={{marginTop: 20}}/>
                    </Page>
                </Document>
            </PDFViewer>            
        </div>
    )

}