import { StyleSheet, Text, View } from "@react-pdf/renderer";
import RenderContentTable from "./RenderContentTable";

export default function ReportTableRow({rows}){
    const styles = StyleSheet.create({
        row: {
            flexDirection: 'row',
            fontSize: 9,                       
        },
    });

    const element = rows.map( (item, index) => 
        <View style={[styles.row, item.style]} key={index.toString()}>
            {
                item.columns.map((column, columnIndex) => (
                    <View style={column.style} key={`${index.toString()}${columnIndex.toString()}`}>
                        <Text style={column.titleStyle}>{column.title}</Text>
                        {
                            <RenderContentTable 
                                typeInput={column.typeInput} 
                                text={column.text} 
                                checked={column?.checked ?? false}
                                items={column?.itemsText}
                            />
                        }                        
                    </View>
                    
                ))
            }        
        </View>
    )

    return <>{element}</>
}