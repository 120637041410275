import { StyleSheet, Text, View } from "@react-pdf/renderer";

export const CheckboxTitle = ({text, sx={}, checked=false}) =>{
  const styles = StyleSheet.create({
    item: {
      flexDirection: 'row',
      paddingRight: 1,
      ...sx?.viewCheckbox
    },
    bulletPoint: {
      width: 10,
      height: 10,
      fontSize: 10,
      border: '1px solid #000',
      marginRight: 2,
      backgroundColor: checked ? '#05a621': ''
    },
    itemContent: {
      fontSize: 10,
      //textTransform: 'capitalize'
    },
  });

  return (
    <View style={styles.item}>
        <Text style={styles.bulletPoint}></Text>
        <Text style={[styles.itemContent, sx]}>{text}</Text>
    </View>
  )    
}