import { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumbs";
import DocumentFormNext from "../../components/Document/DocumentFormNext";
import SimpleLoad from "../../components/Loader/SimpleLoad";
import { ERROR_SERVER } from "../../constants/messages";
import { getCase } from "../../helpers/case";

function DocumentEdit(){
    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const [document, setDocument] = useState(null)

    useEffect(() => {
        let isSubscribe = true;
        setLoading(true)
        async function getCaseAPI(){
            if(isSubscribe){
                try {
                    const response = await getCase(id);
                    console.log(response)
                    if(response.success){
                        const caseToEdit = {
                            id: response.content.id,
                            name: response.content.name ?? '',
                            lastName: response.content.lastName ?? '',
                            aka: response.content.aka ?? '',
                            aNumber: response.content.aNumber ?? '',
                            birthday: response.content.birthday ?? null,
                            placeBirth: response.content.placeBirth ?? '',
                            gender: response.content.gender ?? 'Male',
                            User: {
                                id: response.content.User?.id ?? '',
                                name: response.content.User?.name ?? ''
                            },
                            sponsorInfo: {
                                id: response.content.SponsorInfo?.id ?? '',
                                name: response.content.SponsorInfo?.name ?? '',
                                lastName: response.content.SponsorInfo.lastName ?? '',
                                age: response.content.SponsorInfo?.age ?? '',
                                relationship: response.content.SponsorInfo?.relationship ?? '',
                                nationality: response.content.SponsorInfo?.nationality ?? '',
                                contactNumbers: response.content.SponsorInfo?.ContactNumbers ?? [],
                                homeAddress: response.content.SponsorInfo?.homeAddress ?? '',
                                gender: response.content.SponsorInfo?.gender ?? 'Male',
                            },
                            caseInfo: {
                                id: response.content.CaseInfo?.id ?? null,
                                dateAcceptance: response.content.CaseInfo?.dateAcceptance ?? null,
                                dateRelease: response.content.CaseInfo?.dateRelease ?? null,
                                dateComplete: response.content.CaseInfo?.dateComplete ?? null,
                                dateClosure: response.content.CaseInfo?.dateClosure ?? null,
                                postReleaseAgency: response.content.CaseInfo?.postReleaseAgency ?? '',
                                caseManager: response.content.CaseInfo?.caseManager ?? '',
                                contactInformation: response.content.CaseInfo?.contactInformation ?? '',
                                status: response.content.CaseInfo?.status ?? 'active',
                            },
                            houseHoldMembers: response.content.HouseHoldMembers,
                            reasonReferral: {
                                prsOnly: {
                                    id: response.content.PRSOnly?.id ?? null,
                                    criminalHistory: response.content.PRSOnly?.criminalHistory ?? false,
                                    gangInvolvement: response.content.PRSOnly?.gangInvolvement ?? false,
                                    behavioralProblems: response.content.PRSOnly?.behavioralProblems ?? false,
                                    abuseHistory: response.content.PRSOnly?.abuseHistory ?? false,
                                    traumaHistory: response.content.PRSOnly?.traumaHistory ?? false,
                                    mentalHealth: response.content.PRSOnly?.mentalHealth ?? false,
                                    suicidalBehaviors: response.content.PRSOnly?.suicidalBehaviors ?? false,
                                    substanceAbuse: response.content.PRSOnly?.substanceAbuse ?? false,
                                    cognitiveDevelopmentalDelays: response.content.PRSOnly?.cognitiveDevelopmentalDelays ?? false,
                                    lackFormalEducation: response.content.PRSOnly?.lackFormalEducation ?? false,
                                    medicalCondition: response.content.PRSOnly?.medicalCondition ?? false,
                                    disability: response.content.PRSOnly?.disability ?? false,
                                    pregnantParentingTeen: response.content.PRSOnly?.pregnantParentingTeen ?? false,
                                    lackPriorRelationshipSponsor: response.content.PRSOnly?.lackPriorRelationshipSponsor ?? false,
                                    sponsorLackKnowledge: response.content.PRSOnly?.sponsorLackKnowledge ?? false,
                                    traffickingConcern: response.content.PRSOnly?.traffickingConcern ?? false,
                                    sponsorConcerns: response.content.PRSOnly?.sponsorConcerns ?? false,
                                    prsDiscretionary: response.content.PRSOnly?.prsDiscretionary ?? false,
                                    categorySponsorship: response.content.PRSOnly?.categorySponsorship ?? false,
                                    other: response.content.PRSOnly?.other ?? false,
                                },
                                prsAfter: {
                                    id: response.content.PRSAfter?.id ?? null,
                                    tvpraTraffickingConcerns: response.content.PRSAfter?.tvpraTraffickingConcerns ?? false,
                                    tvpraSponsorConcerns: response.content.PRSAfter?.tvpraSponsorConcerns ?? false,
                                    tvpraDisability: response.content.PRSAfter?.tvpraDisability ?? false,
                                    tvpraAbuseHistory: response.content.PRSAfter?.tvpraAbuseHistory ?? false,
                                }
                            },
                            Header: response.content.Header,
                            SafetyStatus:response.content.SafetyStatus,
                            ReferralResource: response.content.ReferralResource,
                            CaseCloseProgram: response.content.CaseCloseProgram,
                            DestinationIndicator: response.content.DestinationIndicator,
                            ServiceInstructions: response.content.ServiceInstructions,
                            ServiceAreas: response.content.ServiceAreas
                        }
                        setDocument(caseToEdit)
                    }else{
                        toast.error(ERROR_SERVER)
                    }
                    setLoading(false)
                } catch (error) {
                    toast.error(ERROR_SERVER)
                }
            }            
        }
        getCaseAPI();
        return () => isSubscribe = false;
    }, [])

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Case" breadcrumbItem="Edit case" />
                <Row>
                    <Col xs="12" lg="12">
                        { loading ? <SimpleLoad /> : <DocumentFormNext document={document} id={id}/>}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default withRouter(DocumentEdit)