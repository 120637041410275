import { useEffect } from "react";
import { useState } from "react";
import { getRelationshipActives } from "../helpers/relationship";

export default function useGetRelationShip(){
    const [items, setItems] = useState([]);

    useEffect(() => {
        let isSubscribed = true;
        async function getItemsApi(){
            const response = await getRelationshipActives();
            if(response.success){
                setItems(response.content.map(it=>({value: it.id, label: it.name})))
            }
        }
        if(isSubscribed) getItemsApi()

        return () => isSubscribed = false;
    }, [])

    return items;
}