import { withRouter } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumbs"
import ReferralForm from "../../components/Referral/ReferralForm"

function StagesAdd(){
    
    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Referrals & Resources" breadcrumbItem="New Referrals & Resources" />
                <Row>
                    <Col xs="12" lg="12">
                        <ReferralForm document={null}/>
                    </Col>
                </Row>
            </Container>
        </div>
    )

}

export default withRouter(StagesAdd)