import InputMask from "react-input-mask";

export default function DateInputMask({date, onChange, className='fs-7 input-1 p-0 form-control'}){

    return <InputMask 
                mask="99/99/9999" 
                value={date} 
                onChange={onChange} 
                className={className}
            />;
}