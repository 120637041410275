import { useState } from "react";
import { Field, FieldArray, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { Button, Card, CardBody, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { DELETE_SUCCESS, ERROR_SERVER, requiredField, SAVE_SUCCESS, UPDATE_SUCCESS } from "../../constants/messages";
import FullScreenLoader from "../Loader/FullScreenLoader";
import { deleteReferralList, postReferral, putReferral } from "../../helpers/referral";

export default function ReferralForm({id, item}){
    const [isSubmiting, setIsSubmiting] = useState(false)
    const history = useHistory();
    const [activeIndex, setActiveIndex] = useState(-1)
    const initialValues = item ? item :{
        name: '',
        referral_list_ids: []
    };
    const form = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: Yup.object({  
            name: Yup.string().required(requiredField),
            referral_list_ids: Yup.array().of(
                Yup.object().shape({
                    name: Yup.string().required(requiredField),
                })
            ),            
        }),
        onSubmit: async (values) => { 
            setIsSubmiting(true)
            try {
                if(values.id){
                    const response = await putReferral(id, values);  
                    if(response.success){
                        toast.success(UPDATE_SUCCESS)
                    }else{
                        toast.error(ERROR_SERVER)
                    }         
                    setIsSubmiting(false)            
                }else{
                    const response = await postReferral(values)
                    if(response.success){
                        toast.success(SAVE_SUCCESS)
                    }else{
                        toast.error(ERROR_SERVER)
                    }  
                    setIsSubmiting(false)                   
                }
                setTimeout(() => {
                    history.goBack();
                }, 2000)
                
                
            } catch (error) {
                toast.error(ERROR_SERVER)
                setIsSubmiting(false) 
            }         
        }
    })
    return (
        <Card>
            {isSubmiting && <FullScreenLoader />}
            <CardBody>
                <Form
                    className="needs-validation"
                    id="tooltipForm"
                    onSubmit={(e) => {
                    e.preventDefault();
                    form.handleSubmit();
                    return false;
                }}
                >
                    <FormGroup className="mb-4" row>
                      <Label
                        htmlFor="stagesname"
                        className="col-form-label col-lg-2"
                      >
                        Name
                      </Label>
                      <Col lg="10">
                        <Input
                            type="text"
                            id="name"
                            name="name"
                            className={`form-control ${form.errors.name ? 'is-invalid' : ''}`}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={form.values.name || ""}  
                        />
                        {
                            form.errors.name && 
                            <FormFeedback type="invalid">{form.errors?.name}</FormFeedback>
                        }
                      </Col>
                    </FormGroup>
                    
                    <hr className="my-3"/>

                    <FormikProvider value={form}>
                    <FieldArray
                        name="referral_list_ids" 
                        render={arrayHelper=>(
                            <>
                            <Row className="mb-4">
                                <Col xs="12" md="12">
                                    <div className="d-flex align-items-center">
                                        <div className="pe-5">
                                            <h6 className="m-0">Referral List</h6>
                                        </div>
                                        <div>
                                            <Button type="button" color="light" size="sm" className="me-2" onClick={() =>{ 
                                                    arrayHelper.push({
                                                        name: '',
                                                        address: '',
                                                        phone: '',
                                                    })
                                                    setActiveIndex(form.values.referral_list_ids.length)
                                                }}
                                            >
                                                <i className="mdi mdi-plus me-1"></i>
                                                New 
                                            </Button>
                                            <Button type="button" color="light" size="sm" onClick={() => setActiveIndex(-1)}
                                            >
                                                <i className="mdi mdi-minus me-1"></i>
                                                Close All 
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            {
                                (form.values.referral_list_ids && form.values.referral_list_ids.length > 0) &&
                                form.values.referral_list_ids.map((item, index) => (
                                    index !== activeIndex ?
                                    <div className="d-flex justify-content-between border-bottom mb-2" key={index}>
                                        <div>
                                            <span>{item.name}</span>
                                        </div>
                                        <div>
                                            <i className="far fa-eye" onClick={() => setActiveIndex(index)}></i>
                                        </div>                                            
                                    </div>
                                    :
                                    <div key={index} className="border p-3 mb-2 shadow-sm">
                                        <FormGroup className="mb-4" row>
                                            <Label htmlFor={`title_${index}`} className="col-form-label col-lg-2">Name</Label>
                                            <Col lg="10">
                                                <Field
                                                    type="text"
                                                    id={`title_${index}`}
                                                    name={`referral_list_ids.${index}.name`}
                                                    className={`form-control ${form.errors?.referral_list_ids?.length > 0 && form.errors.referral_list_ids[index]?.name ? 'is-invalid' : ''}`} 
                                                />
                                                {
                                                    (form.errors?.referral_list_ids?.length > 0 && form.errors?.referral_list_ids[index]?.name) &&
                                                    <FormFeedback type="invalid">{form.errors?.referral_list_ids[index]?.name}</FormFeedback>
                                                }
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="mb-4" row>
                                            <Label htmlFor={`address_${index}`} className="col-form-label col-lg-2">Address</Label>
                                            <Col lg="10">
                                                <Field
                                                    type="text"
                                                    id={`address_${index}`}
                                                    name={`referral_list_ids.${index}.address`}
                                                    className="form-control" 
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="mb-4" row>
                                            <Label htmlFor={`phone_${index}`} className="col-form-label col-lg-2">Phone</Label>
                                            <Col lg="10">
                                                <Field
                                                    type="text"
                                                    id={`phone_${index}`}
                                                    name={`referral_list_ids.${index}.phone`}
                                                    className="form-control" 
                                                />
                                            </Col>
                                        </FormGroup>
                                        <div className="d-flex justify-content-end">
                                            <Button type="button" color="outline-danger" size="sm" 
                                                onClick={async () => {
                                                    if(item.id){
                                                        try {
                                                            const response = await deleteReferralList(item.id)
                                                            console.log(response)
                                                            if(response.success){
                                                                arrayHelper.remove(index)
                                                                toast.success(DELETE_SUCCESS)
                                                            }else{
                                                                toast.error(ERROR_SERVER)
                                                            }                                                                   
                                                        } catch (error) {
                                                            toast.error(ERROR_SERVER)
                                                        }
                                                    }else{
                                                        arrayHelper.remove(index)
                                                    }                                                       
                                                }}
                                            >
                                                <i className="mdi mdi-trash me-1"></i>
                                                Delete 
                                            </Button>
                                        </div>                                            
                                    </div>
                                ))
                            }
                            </>
                        )}
                    />
                    </FormikProvider>

                    <Row className="justify-content-end">
                        <Col lg="10">
                        <Button type="submit" color="success" className="me-2">
                            Save
                        </Button>
                        <Link to="/referral-list" className="btn btn-light waves-effect">Cancel</Link>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
        </Card>
    )
}