import { useEffect } from "react"
import { useState } from "react"
import { Link, withRouter } from "react-router-dom"
import { toast } from "react-toastify"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumbs"
import ButtonLink from "../../components/Common/ButtonLink"
import FullScreenLoader from "../../components/Loader/FullScreenLoader"
import Paginate from "../../components/Tables/Paginate"
import SimpleTable from "../../components/Tables/SimpleTable"
import { ERROR_SERVER } from "../../constants/messages"
import { getQuestionInstructionsList } from "../../helpers/questionInstructions"

function ServiceAreasList(){
    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(0)
    const [totalPaginas, setTotalPaginas] = useState(0) 
    const [query, setQuery] = useState({
        page: 0,
        size: 10
    })

    useEffect(() => {
        setLoading(true)
        let isSubscribed = true;
        async function getListApi(){
            let q = Object.keys(query).map(key=>`${key}=${query[key]}`).join("&")
            try {
                const response = await getQuestionInstructionsList(`?${q}`);
                setItems(response.content)
                setTotalPaginas(response.total_pages)
                setLoading(false)
            } catch (error) {
                toast.error(ERROR_SERVER)
                setItems([])
                setTotalPaginas(0)
                setLoading(false)
            }            
        }
        if(isSubscribed) getListApi()
        return () => isSubscribed = false;
    }, [query])

    const columns = [
        {
          text: "id",
          dataField: "id",
          sort: true,
          hidden: true,
        },
        {
          text: "Name",
          dataField: "name",          
        },
        // {
        //     text: "Active",
        //     dataField: "active", 
        //     formatter: cell => cell ? <i className="fs-5 mdi mdi-check-circle text-success"/> : <i className="fs-5 mdi mdi-close-circle text-danger"/>         
        // },
        {
            dataField: "menu",
            isDummyField: true,
            editable: false,
            text: "Actions",
            formatter: (cellContent, row) => (
                <Link to={`/questioninstructions-edit/${row.id}`}><i className="fs-5 mdi mdi-square-edit-outline"/></Link>
            ),
        },
    ];

    const handlePageClick = page => {
        setPage(page)
        setQuery(prev=>({
            ...prev,
            page: page
        }))
    }

    return (
        <div className="page-content">
            {loading && <FullScreenLoader />}
            <Container fluid>
                <Breadcrumbs title="Questions & Instructions" breadcrumbItem="Questions & Instructions List" />
                <ButtonLink label="Add Questions & Instructions" to="/questioninstructions-add"/>
                <Card>
                    <CardBody>
                        <Row>
                            <Col xs="12" lg="12">
                                <SimpleTable 
                                    columns={columns}
                                    items={items} 
                                />
                            </Col>

                            {
                                items.length > 0 &&
                                <Paginate
                                    page={page}
                                    totalPaginas={totalPaginas}
                                    totalRegistros={0}
                                    handlePageClick={handlePageClick}
                                    limit={query.limite}
                                    handleChangeLimit={() => {}}
                                />
                            }
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </div>
    )
}

export default withRouter(ServiceAreasList)